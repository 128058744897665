import React, { Component } from "react";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import axios from 'axios';
import Breadcrumbs from "./breadcrumbs";
import DrawerLink from './open-drawer-link.js';
import ContactsDropdown from "./contacts-dropdown";
import ContactGroups from "./contact-groups";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt,faCaretDown,faCaretLeft,faCaretRight, faCaretUp, faSearch,faTrash,faSpinner,faShare, faSort  } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import { Doughnut, Line } from "react-chartjs-2";
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer';
import "react-datepicker/dist/react-datepicker.css";
import Tooltip from "./tooltip";
import ReportPDF from "./report-pdf";
import ReportPDFFormat1 from "./report-pdf-format1";
import PendingInvoicePDF from "./pending-invoice-pdf";
import utils from '../utils.js';
import Notification from "./notification.js";
import check_access from './access-level.js';
import ExpenseTypes from "./expense-types";
import ReactWhatsapp from 'react-whatsapp';
import Link from './link.js';

import Autocomplete from "./autocomplete";
import { parse } from "@fortawesome/fontawesome-svg-core";
import ProductCategory from "./product-category.js";
import CanvasJSReact from '@canvasjs/react-charts';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


var CryptoJS = require("crypto-js");
let {enc_key,app_api,user_info,states} = require('../library.js');
let _this;
export default class Reports extends Component {
    constructor() {
        super();
        this.state = {
          selected_payment_mode : 'all',
          is_deleting_trans : false,
          is_show_trans_action : false,
          product_report_type : 'quantity',
          trans_action : 'delete',
          pay_rcv_type : 'all',
          trading_account_left_total : 0,
          trading_account_right_total : 0,
          selected_trial_balance : '',
          is_generating_trading_account : false,
          is_delete_trial_balance : false,
          is_save_trading_account : false,
          is_downloading_trans : false,
          is_trans_action_btn : false,
          is_item_commission : false,
          is_delete_trans : false,
          debit_sym : 'Dr',
          credit_sym : 'Cr',
          active_tab : 'ledger',
          FromDate:  new Date(),
          ToDate: new Date(),
          reports_list:[],
          payment_mode_total : {},
          generated_trial_balance:[],
          transaction_report:[],
          transaction_report_temp:[],
          payment_mode_transaction_report:[],
          expenses_report:[],
          profit_loss_report:[],
          audit_trial_report:[],
          profit_loss_from:'',
          profit_loss_to:'',
          tax_report:[],
          tcs_tax_report:[],
          total_b2b : 0,
          total_b2c : 0,
          total_cdnr : 0,
          total_hsn : 0,
          customer_pending_invoice_report:[],
          customer_pending_invoices:[],
          customer_pending_payments:[],
          selected_pending_invoice_contact_type : "1",
          contact_ids:[],
          contact_names:[],
          contact_types:[],
          total_pendings:[],
          pending_share_data:[],
          total_collected_tax:0,
          creditors_debitors_report:[],
          creditors_debitors_report_tmp:[],
         
          loading_process:'Please adjust the filter and click on VIEW button to see the report.',
          
          total_credit:0,
          total_debit:0,
          total_balance:0,
          total_pdf_credit:0,
          total_pdf_debit:0,
          total_pdf_balance:0,
          msg1:'',
          msg2:'',
          msg3:'',
          msg4:'',
          report_time:'',
          report_date:'',
          pdf_report:[],
          contact_info : [],
          filter_option : 'today',
          chart_data :[], 
          chart_table_format :[], 
          total_earning :0, 
          total_sale :0,
          total_sales_arr :[],
          total_amount_arr :[],
          sort_type : 'asc',
          total_transaction:0,
          assisted_by_option:[],
          payment_mode_option:[],
          selected_month : 'Apr',          
          number_of_row :2,
          prev_link :'',
          next_link: '',
          current_page : '',
          total_page : '',
          page_msg : '',
          current_url : '',
          prev_url : '',
          inventory_list : [],
          total_available : 0,
          total_sp : 0,
          total_pp : 0,
          total_amount : 0,
          ledger_format : 'format1',
          ledger_from_date : '',
          ledger_to_date : '',
          total_inv_available : 0,
          total_inv_sp : 0,
          total_inv_pp : 0,
          total_purchase_value : 0,
          total_purchase_tax : 0,
          inventory_xls_report : [],
          is_print:false,
          selected_category : 0,
          report_type : 'Chart',
          customers_ids : [],
          customer_total : 0,
          customer_adjusted : 0,
          customer_balance : 0,
          contact_name : '',
          total_grand_amount : 0,
          pending_contact_type : '1',
          is_showing_pending_invoice : false,
          title : 'Reports',
          sub_title : 'View Ledger',
          sales_man_report : [],
          sales_man_ids : [],
          sales_man_names : [],
          total_sm_comission : 0,
          sales_man_grand_total : 0,
          staff_options : [],
          is_staff_selected : false,
          gst_json_file : '',
          tax_section : 'b2b',
          is_all_contact : true,
          cpipage :2,
          is_show_more_pending_invoice:true,
          clicked_show_more_pi_btn:false,
          enable_tcs:false,
          enable_orders:false,
          enable_audit_trial:false,
          transaction_opt :'billing',
          total_stock_ledger_quantity : '',
          selected_stock_item : '',
          no_hsn_found_data : [],
          audit_trial_page : 1,
          is_loaded_audit_trial : false,
          is_load_contact_dd : true,
          is_generated_tcs : true,
          pending_invoice_load_type : '1',
          progress : 0,
          trail_balance_page : 1,
          trail_balance_target_count : 0,
          gst_page_no : 1,
          number_of_gst_record : 0,
          number_of_gst_pages : 0,
          total_contact : 0,
          total_contact_loaded : 0,
          total_record : 0,
          gst_sales : [],
          amount_data : [],
          count_data : [],
          labels : [],
          hide_transaction_list_items : (localStorage.getItem('hide_transaction_list_items'))?JSON.parse(localStorage.getItem('hide_transaction_list_items')):['payment_mode','customer','assisted_by','total'],
          show_pending_invoice_list_items : ['return','note','payments','opening_balance'],
          gst_sales_return : [],
          customer_tcs_report : [],
          top_selling_product_report : [],
          grand_quantity : 0,
          grand_revenue : 0,
          total_global_discount : 0,
          total_selling_price : 0,
          grand_profit : 0,
          average_margin : 0,
          retained_customer_report : [],
          gst_tax_report_download_msg :'Please wait while fetching tax report from server',
          customer_tcs_page : 1,
          is_show_customer_tcs_load_more : true,
          is_bank_account : false,
          is_reload_trial_balance : false,
          payable_receivable_customers : [],
          payable_receivable_customers_dropdown : [],
          payable_receivable_customers_data : [],
          transaction_book_report : [],
          trial_balance_report : [],
          trial_balance_total_credit : 0,
          trial_balance_total_debit : 0,

          trial_balance_total_credit_credit : 0,
          trial_balance_total_credit_debit : 0,

          trial_balance_total_debit_credit : 0,
          trial_balance_total_debit_debit : 0,

          trial_balance_filter_type : 'thisyear',
          pending_report_format_type : 'billing',
          pending_report_opening_balance : [],
          trial_balance_generate_type :'payment',
          is_generating_trial_balance : false,
          is_visible_trial_balance : false,
          is_visible_trading_account : false,
          selected_trading_account : '',
          selected_trial_balance : '',
          generated_trading_accounts :[],
          view_trading_accounts :[],
          new_customers :[],
          retained_customers :[],
          new_customer_percent : 0,
          retained_customer_percent : 0,
          retained_customer_graph : '',
          retained_customer_load_type :'all',
        };
        this.gst_progress_interval = null;
        _this = this;
        this.currency_format = utils.currency_format.bind(this);
        this.is_access = check_access.is_access.bind(this);
        this.handleReportFilter = this.handleReportFilter.bind(this);
        this.handleStaffChange = this.handleStaffChange.bind(this);
        this.handlePaymentModeChange = this.handlePaymentModeChange.bind(this);
        this.getContactOnId = this.getContactOnId.bind(this);
        this.handleChangeLedgerFormat = this.handleChangeLedgerFormat.bind(this);
        this.handleDownloadInventoryXLS = this.handleDownloadInventoryXLS.bind(this);
        this.handleInventoryPrint = this.handleInventoryPrint.bind(this);
        this.handleCalendarClick = this.handleCalendarClick.bind(this);
        this.handleChangeReportType = this.handleChangeReportType.bind(this);
        
        this.handleToggleCrDrReport = this.handleToggleCrDrReport.bind(this);
        
        
        this.onSort = this.onSort.bind(this);
        this.isMobile = utils.isMobile.bind(this);
        this.options = {
        responsive: true,
        title: {
          display: false,
          text: 'Sales Report Chart'
        },
        tooltips: {
          mode: 'label'
        },
        hover: {
          //mode: 'dataset'
          mode: 'index',
          intersect: false
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                show: false,
                labelString: 'Month'
              }
            }
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                show: false,
                labelString: 'Value'
              },
              ticks: {
                suggestedMin: 10,
                suggestedMax: 250
              }
            }
          ]
        }
      }
    }

    handleChangeRCLoadType = (value) =>{
      this.setState({retained_customer_load_type:value});
      let _this = this;
      setTimeout(() => {
        _this.hanldeShowRetainedCustomers();
      }, 100);
    }

    handleChangeMonth = (value) =>{
      this.setState({selected_month:value});
    }
    toggleDataSeries = (e) =>{
      if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
        e.dataSeries.visible = false;
      }
      else{
        e.dataSeries.visible = true;
      }
      this.chart.render();
    }
    handleChangePayableRecievableType = (value) =>{
      this.setState({pay_rcv_type:value});
    }
    handleSortListOnMargin = ()=>{
        let isort = localStorage.getItem('tsp_item_sort');
        let sort_by = 'asc';
        if(isort==='asc'){
          sort_by = 'desc';
        }
        localStorage.setItem('tsp_item_sort',sort_by);
        /* let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          name : 'top_selling_product_margin_sort',
          value : 'asc',
      }
      let npcapi = app_api+'/save_option';
      axios.post(npcapi, send_data)
        .then(function (response) {
          //window.location.reload();
      }.bind(this)).catch(function(res){
          
      }) */
      
      let data = this.state.top_selling_product_report;
      data.sort(function(a, b) {
        if(sort_by==='asc'){
          return parseFloat(a.margin) - parseFloat(b.margin);
        }else{
          return parseFloat(b.margin) - parseFloat(a.margin);
        }
      });
      this.setState({top_selling_product_report:data});
    }
    handleDownloadTransactionReport = () =>{
      this.setState({is_downloading_trans:true});
      setTimeout(() => {
        document.getElementById('donwload-transaction-xls-button').click();
      }, 200);
      let _this =  this;
      setTimeout(() => {
        _this.setState({is_downloading_trans:false});
      }, 500);
    }
    handleSelectTrialBalance = (e) =>{
      let thisvalue = e.currentTarget.value;
      this.setState({selected_trial_balance:thisvalue});
    }
    handleChangePendingReportFormatType = (e) =>{
      let thisvalue = e.currentTarget.value;
      this.setState({pending_report_format_type:thisvalue})
    }
    handleCheckIsBankAccount = () =>{
      let is_bank_account = localStorage.getItem('ledger_contact_type');
      if(is_bank_account==='bank account'){
        this.setState({is_bank_account:true});
      }else{
        this.setState({is_bank_account:false});
      }
    }

    handleChangeTransactionOpt = (e) =>{
      let tval = e.currentTarget.value;
      this.setState({transaction_opt:tval});
      this.setState({filter_option:'sales'});
    }

    handleChangeTaxSection =(e)=>{
      this.setState({tax_section:e.currentTarget.value,gst_json_file:''});
      this.setState({tax_report:[]});
      this.setState({loading_process:'Record(s) not found'});
      this.setState({total_collected_tax:0});
    }

    handleChangePendingInvoiceLoadType = (e)=>{
      this.setState({is_load_contact_dd:false});
        if(document.getElementById("contact_box")){
          document.getElementById("contact_box").value = '';
          document.getElementById("customer-dropdown").value = 0;
          document.getElementById("old-customer-dropdown").value = 0;
        }
        let thisval = e.currentTarget.value;
        this.setState({pending_invoice_load_type:thisval});
        if(thisval==="1" || thisval==="2" || thisval==="5"){
          this.setState({pending_contact_type:thisval});
          if(document.getElementById("handleCloseListOnOutSide")){
            document.getElementById("handleCloseListOnOutSide").click();
          }
          /* if(document.getElementById("handleRefreshContactList")){
            document.getElementById("handleRefreshContactList").click();
          } */
          let _this = this;
          setTimeout(() => {
            _this.setState({is_load_contact_dd:true});
          }, 200);
        }
        
    }

    handleToggleCrDrReport(e){
      let customer = e.currentTarget.getAttribute('data-id');
      let customers_div = document.querySelectorAll(".dr-cr-child-report-"+customer);
      for(let i=0;i<customers_div.length;i++){
        customers_div[i].classList.toggle('hide');
        customers_div[i].classList.toggle('is-show');
      }
      let carets = document.querySelectorAll(".expand-caret-"+customer);
      for(let i=0;i<carets.length;i++){
        carets[i].classList.toggle('hide');
      }
    }

    handleDrCrContactChange = (e) =>{
      let contact = e.currentTarget.value;
      let creditors_debitors_report_tmp = this.state.creditors_debitors_report_tmp;
      if(contact==="all"){
        this.setState({customers_ids:creditors_debitors_report_tmp.customers});
      }else{
        let report_customers = creditors_debitors_report_tmp.customers;
        let selected_customer = report_customers[contact];
        let customer_tmp = [];
        customer_tmp.push(selected_customer);
        this.setState({customers_ids:customer_tmp});
      }
    }
    handleChangeReportType(e){
      let report_type = e.currentTarget.value;
      this.setState({report_type:report_type});
    }

    handleCalendarClick(e){
      let id = e.currentTarget.id;
      document.getElementById(id+'-date').focus();
    }

    handleInventoryPrint(e){
        this.setState({is_print:true});
        
        setTimeout(function(){


        var divContents = document.getElementById("report-print").innerHTML; 

        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.write('<html><head>'); 
        a.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" type="text/css" />'); 
        a.document.write('<style type="text/css">body{font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";}@page{size:A4,margin:0}.ledger-table{zoom:80%}.table .thead-light th{color:#9a9494}.table .thead-light th{background-color:#e9ecef !important;border-color:#dee2e6}.table thead th{vertical-align:bottom;border:2px solid #dee2e6}.table th,.table tr td{white-space:nowrap}.table td,.table th{padding:.75rem;border:1px solid black !important; color:black;font-family:inherit}.no-print{display:none}.print-block{display:block}.hide.dr-cr-child-report{display:revert !important}.pending-invoice{border-bottom:1px solid #dee2e6;border-top:1px solid #dee2e6;}</style>'); 
        a.document.write('</head>'); 

        a.document.write('<body onload="window.print();">'); 
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
        a.focus();
        _this.setState({is_print:false});
        },500);
    }


    handleDownloadInventoryXLS(e){
      let contact_name = document.getElementById("contact_box").value;
      let customer = localStorage.getItem("selected_contact");
     
      if(contact_name==="" || contact_name==='All'){
        customer = 'All';
      }else if(contact_name==='No Customer'){
        customer = 'none';
      }

      let id = e.currentTarget.id;
      document.getElementById(id).classList.add("hide");
      document.getElementById(id+'-btn-spinner').classList.remove("hide");
      let body = {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          selected_category : this.state.selected_category,
          customer : customer,
       }
      
      let api = app_api+'/download_inventory_report';
      axios.post(api, body)
        .then(function (response) { 
          let resp = response.data;
          
          this.setState({inventory_xls_report:resp.data});
          setTimeout(function(){
            document.getElementById(id).classList.remove("hide");
            document.getElementById(id+'-btn-spinner').classList.add("hide");
            if(id==='print-inventory'){
              document.getElementById("print-inventory-hidden").click();
            }else{
              document.getElementById("donwload-inventory-xls-button").click();
            }
          },1000);
      }.bind(this)).catch(function(res){
          if(res.response){
            if(res.response.data){
                
            }
          }
      })
    }


    handleChangeLedgerFormat(e){
      this.setState({ledger_format:e.currentTarget.value,reports_list:[],loading_process:'Please adjust the filter and click on VIEW button to see the report.'});
    }

    handleStaffChange(e){
      let value = e.currentTarget.value;
      if(value=='no'){
        let trow = document.querySelectorAll(".transactions-row");
        let total_amount = 0;
        for(let i=0;i<trow.length;i++){
          trow[i].classList.remove('hide');
          let amount = trow[i].getAttribute("data_target");
          total_amount = total_amount+parseFloat(amount);
        }
        //document.getElementById("trans-total").innerHTML = this.currency_format(total_amount);
      }else{
        let trow = document.querySelectorAll(".transactions-row");
        for(let i=0;i<trow.length;i++){
          trow[i].classList.add('hide');
        }
        let tcrow = document.querySelectorAll("."+value);
        let total_amount = 0;
        for(let i=0;i<tcrow.length;i++){
          tcrow[i].classList.remove('hide');
          let amount = tcrow[i].getAttribute("data_target");
          total_amount = total_amount+parseFloat(amount);
        }
        //document.getElementById("trans-total").innerHTML = this.currency_format(total_amount);
      }
    }
    handlePaymentModeChange(e){
      let value = e.currentTarget.value;
      if(value=='no'){
        this.setState({selected_payment_mode:'all'});
        let transaction_report = this.state.transaction_report_temp;
        this.setState({transaction_report:transaction_report});
      }else{
        this.setState({selected_payment_mode:value});
        let transaction_report = this.state.payment_mode_transaction_report[value];
        this.setState({transaction_report:transaction_report});
      }
    }

    onSort(e){
      let sortKey = e.currentTarget.id;
      const transaction_report = this.state.transaction_report;
      
      let is_sort = this.state.sort_type;
      if(is_sort==='asc'){

        transaction_report.sort((a,b) => a[sortKey].localeCompare(b[sortKey]))
        this.setState({transaction_report});
        this.setState({sort_type:'desc'});
      }else{
        
        transaction_report.sort((b,a) => a[sortKey].localeCompare(b[sortKey]))
        this.setState({transaction_report});
        this.setState({sort_type:'asc'});
      }
    }

    getContactOnId(id){
      let version = localStorage.getItem('IdbVersion');
      version = parseInt(version);
      let dbReq = window.indexedDB.open('BaazarERP', version);
        let db;
        let contact_name = '';
        dbReq.onupgradeneeded = function(event) {
          db = event.target.result;
        }
       
        dbReq.onsuccess = function(event) {
          db = event.target.result;
          try{
            
            let transaction = db.transaction("contacts", "readwrite");
            let contacts = transaction.objectStore("contacts");
            let request = contacts.get(id);
            request.onsuccess = function(e) {
              let cursor =  e.target.result;
              let id = cursor.id;
              let type = cursor.type;
              let data = cursor.data;
              let bytes = CryptoJS.AES.decrypt(data, enc_key);
              let cval = bytes.toString(CryptoJS.enc.Utf8);
              let con =   JSON.parse(cval);
             
              db.close();
              contact_name = con.name;
              return contact_name;
            }; 
          }catch{}
        }
       
    }

    handleReportFilter(e){
      let thisvalue = e.currentTarget.value;
      this.setState({filter_option:thisvalue});
    }

  FromDate = date => {
    
    if(date!==null){
      this.setState({
        FromDate: date
      });
    }
  };
  ToDate = date => {
    if(date!==null){
    this.setState({
      ToDate: date
    });
  }
  };

  handleLoadMoreAuditTrial =()=>{
    this.setState({is_load_more_clicked:true})
    this.handleLoadAuditTrial(this.state.audit_trial_page);
  }

  handleLoadAuditTrial = (page)=>{
    let audit_trial_report = this.state.audit_trial_report;
    this.setState({audit_trial_page:page+1});
    let aubody = {};
    aubody = {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          page : page,
      }
    let api = app_api+'/get_audit_trial';
    axios.post(api, aubody)
      .then(function (response) {
        let cursor = response.data.data;
        this.setState({is_load_more_clicked:false})
        if(cursor.length===0){
          this.setState({loading_process:'Oops ! No record found.'});
          this.setState({is_loaded_audit_trial:true});
        }else{
          for (let index = 0; index < cursor.length; index++) {
            const element = cursor[index];
            audit_trial_report.push(element);
          }
          this.setState({ 'audit_trial_report': audit_trial_report }); 
        }
        
    }.bind(this)).catch(function(res){
        if(res.response){
            if(res.response.data){
                
            }
        }
    })
  }

  componentDidMount(){
    localStorage.removeItem('tsp_item_sort');
    let curl = window.location.href;
    const reports = curl.match(/reports$/i);
    const sales_report = curl.match(/reports\/daily-sales-report$/i);
    const transactions = curl.match(/reports\/transactions$/i);
    const transactions_book = curl.match(/reports\/transactions\/book$/i);
    const inventories = curl.match(/reports\/inventories$/i);
    const inventories_page = curl.match(/reports\/inventories\/page\/(\d+$)/i);
    const inventories_category = curl.match(/reports\/inventories\/category\/(\d+$)/i);
    const inventories_category_page = curl.match(/reports\/inventories\/category\/(\d+)\/page\/(\d+$)/i);
    const contact_specific_ledger = curl.match(/reports\/ledger\/contact\/(\d+$)/i);
    const expenses = curl.match(/reports\/expenses$/i);
    const profit_loss = curl.match(/reports\/profit-loss$/i);
    const tax_report = curl.match(/reports\/tax$/i);
    const tcs_tax_report = curl.match(/reports\/tcs-tax$/i);
    const tcs_customer_report = curl.match(/reports\/tcs-customer$/i);
    const customer_pending_inv = curl.match(/reports\/pending-invoice/i);
    const sales_man = curl.match(/reports\/sales-man/i);
    const top_selling_product = curl.match(/reports\/product-report/i);
    const reained_customer = curl.match(/reports\/retained-customer/i);
    const stock_ledger = curl.match(/reports\/stock-ledger/i);
    const audit_trial = curl.match(/reports\/audit-trial/i);
    const trial_balance = curl.match(/reports\/trial-balance/i);
    const accounting_report = curl.match(/reports\/accounting-report/i);
    const trading_account = curl.match(/reports\/trading-account/i);
    if((reports || contact_specific_ledger) && customer_pending_inv===null && sales_man===null){
      
      this.setState({active_tab:'reports'});
      this.setState({sub_title:'Reports'});
      if(contact_specific_ledger){
        
        let con_id = parseInt(contact_specific_ledger[1]);
       /*  if(localStorage.getItem('is_bank_account')==='true'){
          return false;
         } */
        let body = {};
          body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
                id : con_id,
                get : 'name',
             }
        let api = app_api+'/get_contact_on_id';
        axios.post(api, body)
          .then(function (response) {
            if(response.data[0]===undefined){
              window.location.href = '/404';
            }
            let res_data = response.data[0];
            let contact_name = res_data.name;
            this.setState({contact_name:contact_name});
            var priorDate = new Date();
            priorDate.setDate(priorDate.getDate() - 30);
            this.setState({FromDate:priorDate});
            document.getElementById("contact_box").value = contact_name;
            document.getElementById("customer-dropdown").value = con_id;
            document.getElementById("old-customer-dropdown").value = con_id;
            setTimeout(function(){
              if(document.getElementById("view-report")){
                document.getElementById("view-report").focus();
                document.getElementById("view-report").click();
                let qs = document.querySelectorAll(".auto-comp-opt .list-group-item");
                for(let i=0;i<qs.length;i++){
                  let con_name = qs[i].innerHTML;
                  if(contact_name===con_name){
                      qs[i].click();
                      return false
                  }
                }
              }
            },200);
        }.bind(this)).catch(function(res){
            if(res.response){
              
            }
        })
      }
      
    }else if(sales_report){
      this.setState({sub_title:'Sales Reports'});
      this.setState({active_tab:'sales_report'});
    }else if(stock_ledger){
      this.setState({sub_title:'Stock Ledger'});
      this.setState({active_tab:'stock-ledger'});
    }else if(sales_report){
      this.setState({sub_title:'Sales Reports'});
      this.setState({active_tab:'sales_report'});
    }else if(top_selling_product){
      this.setState({sub_title:'Product Report'});
      this.setState({active_tab:'product-report'});
    }else if(reained_customer){
      this.setState({sub_title:'Retained Customer'});
      this.setState({active_tab:'retained-customer'});
      let thisData = new Date();
      thisData.setMonth(thisData.getMonth() - 1);
      this.setState({FromDate:thisData});
      this.hanldeShowRetainedCustomers();
    }else if(expenses){
      this.setState({sub_title:'Expense Reports'});
      this.setState({active_tab:'expenses'});
      this.setState({loading_process:'Please adjust the filter to view the expenses report'});
      setTimeout(function(){
        if(document.getElementById("contact_box")){
          document.getElementById("contact_box").value = 'All';
          document.getElementById("customer-dropdown").value = 0;
          document.getElementById("old-customer-dropdown").value = 0;
        }
      },100);
    }else if(profit_loss){
      this.setState({sub_title:'Profit Loss Reports'});
      this.setState({active_tab:'profit_loss'});
      this.setState({loading_process:'Please adjust the filter to view the report'});
    }else if(tax_report){
      this.setState({sub_title:'Tax Reports'});
      this.setState({active_tab:'tax'});
      this.setState({loading_process:'Please adjust the filter to view the tax report'});
      
    }else if(tcs_tax_report){
      this.setState({sub_title:'TCS Tax Reports'});
      this.setState({active_tab:'tcs_tax'});
      this.setState({loading_process:'Please adjust the filter to view the TCS report'});
      
    }else if(tcs_customer_report){
      this.setState({sub_title:'TCS Customer Reports'});
      this.setState({active_tab:'tcs_customer'});
      this.setState({loading_process:'Loading...'});
      
    }else if(customer_pending_inv){
      this.setState({sub_title:'Customer Pending Report'});
      this.setState({active_tab:'customer-pending'});
      setTimeout(function(){
        if(document.getElementById("contact_box")){
          //document.getElementById("contact_box").value = 'All';
          document.getElementById("customer-dropdown").value = 0;
          document.getElementById("old-customer-dropdown").value = 0;
        }
      },100);
      //this.handleLoadPendingInvoice(1);
      //this.setState({loading_process:'Please adjust the filter to view the Pending Invoice'});
    }else if(sales_man){
      this.setState({sub_title:'Salesman Report'});
      this.setState({active_tab:'sales-man'});
      //this.handleLoadPendingInvoice(1);
      //this.setState({loading_process:'Please adjust the filter to view the Pending Invoice'});
      
    
        let pmbody = {};
        pmbody = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              contact_type : 4,
          }
        let pmapi = app_api+'/get_contact';
        axios.post(pmapi, pmbody)
          .then(function (response) {
            let cursor = response.data;
            let ContactOptions = [];  
            cursor.map( (value, key) => {
              let id = value.contact_id;
              ContactOptions[id]={id:id,name:value.name};
            });
            this.setState({ 'staff_options': ContactOptions }); 
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })
      
    }else if(transactions){
      this.setState({sub_title:'Transactions Reports'});
      this.setState({filter_option:'sales',active_tab:'transactions',loading_process:'Please adjust filter to view the transaction'});
    }else if(transactions_book){
      this.setState({sub_title:'Transactions Reports'});
      this.setState({filter_option:'today',active_tab:'transactions-book',loading_process:'Please adjust filter to view the report'});
    }else if(inventories || inventories_page || inventories_category || inventories_category_page){
      let cpage;
      let current_page;
      let body = {};
      this.setState({sub_title:'Inventories Reports'});
      this.setState({active_tab:'inventories'});
      if(inventories){
        this.setState({current_page:1});
        this.setState({prev_link:'#'});
        this.setState({next_link:'/reports/inventories/page/2'});
        cpage = 1;
        body = {
            page : cpage,
            target:'report',
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
            type:'All',
         }
      }else if(inventories_page){
        
        cpage = parseInt(inventories_page[1]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/reports/inventories/page/'+next;
        let prev_link = '/reports/inventories/page/'+prev;
        if(prev===1){
          prev_link = '/reports/inventories';
        }
        this.setState({current_page:cpage});
        this.setState({prev_link:prev_link});
        this.setState({next_link:next_link});
        body = {
            page : cpage,
            target:'report',
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
            type:'All',
         }
      }else if(inventories_category){
        let category = inventories_category[1];
        this.setState({selected_category:category});
        this.setState({current_page:1});
        this.setState({prev_link:'#'});
        this.setState({next_link:'/reports/inventories/category/'+category+'/page/2'});
        cpage = 1;
        body = {
            page : cpage,
            target:'inventory_category_report',
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            category : category,
         }
      }else if(inventories_category_page){
        
        let category = parseInt(inventories_category_page[1]);
        this.setState({selected_category:category});
        cpage = parseInt(inventories_category_page[2]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/reports/inventories/category/'+category+'/page/'+next;
        let prev_link = '/reports/inventories/category/'+category+'/page/'+prev;
        if(prev===1){
          prev_link = '/reports/inventories/category/'+category;
        }
        this.setState({current_page:cpage});
        this.setState({prev_link:prev_link});
        this.setState({next_link:next_link});
        body = {
            page : cpage,
            target:'inventory_category_report',
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            category : category,
         }
      }

      if(inventories_page!==null || inventories_category!==null || inventories_category_page!==null){

        this.setState({loading_process:'Loading...'});
        
        let api = app_api+'/inventory/page';
        axios.post(api, body)
          .then(function (response) {
              let page_count = response.data.number_of_page;
            if(response.data.is_empty){
              this.setState({loading_process:'Record(s) not found'});
              this.setState({current_page:0});
              this.setState({total_page:0});
              this.setState({total_inv_available:0});
              this.setState({total_inv_pp:0});
              this.setState({total_inv_sp:0});
              this.setState({total_purchase_value:0});
              this.setState({total_purchase_tax:0});
            }else{
              this.setState({total_available:response.data.total_available});
              this.setState({total_amount:response.data.total_amount});
              this.setState({total_sp:response.data.total_sp});
              this.setState({total_pp:response.data.total_pp});
              this.setState({total_page:page_count});
              this.setState({inventory_list: response.data.data});

              this.setState({total_inv_available:response.data.total_quantity});
              this.setState({total_inv_pp:response.data.total_purchase_price});
              this.setState({total_inv_sp:response.data.total_selling_price});

              this.setState({total_purchase_value:response.data.total_purchase_value});
              this.setState({total_purchase_tax:response.data.total_tax});
              if(cpage===page_count){
                this.setState({next_link:'#'});
              }
              setTimeout(function(){
                if(document.querySelector(".option-active")){
                  document.querySelector(".option-active").click();
                }
              },500);
            }
        }.bind(this)).catch(function(res){
            if(res.response){
               _this.setState({loading_process:'Record(s) not found'});
            }
        })
      }
    }else if(audit_trial){
      this.setState({sub_title:'Audit Trial Report'});
      this.setState({active_tab:'audit-trial'});
      this.setState({loading_process:'Loading...'});
      this.handleLoadAuditTrial(1);
    }else if(trial_balance){
      this.setState({sub_title:'Trial Balance Report'});
      this.setState({active_tab:'trial-balance'});
      this.handleGetGeneratedTrialBalance();
    }else if(accounting_report){
      this.setState({sub_title:'Accounting Report'});
      this.setState({active_tab:'accounting-report'});
      
    }else if(trading_account){
      this.setState({sub_title:'Trading Account'});
      this.setState({active_tab:'trading-account'});
      this.handleGetGeneratedTrialBalance();
      this.handleGetGeneratedTradingAccount();
    }

      let bc_send_data = {
        store_name:user_info.store_name,
        branch_id:user_info.login_id,
        user_id : user_info.user_id,
        access_level : user_info.access_level,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
        keys:"'enable_tcs','financial_year','is_generated_tcs','enable_audit_trial','enable_orders','is_item_commission'"
      }
      let bc_api = app_api+'/get_settings';
      axios.post(bc_api, bc_send_data)
        .then(function (response) {
          let resp = response.data;
          if(resp.enable_orders){
            this.setState({enable_orders:resp.enable_orders});
          }
          if(resp.is_item_commission){
            this.setState({is_item_commission:resp.is_item_commission});
          }
          if(resp.enable_tcs){
            this.setState({enable_tcs:resp.enable_tcs});
          }
          if(resp.enable_audit_trial){
            this.setState({enable_audit_trial:resp.enable_audit_trial});
          }
          if(resp.is_generated_tcs){
            this.setState({is_generated_tcs:resp.is_generated_tcs});
            if(tcs_customer_report){
              this.handleShowCustomerTCSReport();
            }
          }else{
            this.setState({is_generated_tcs:false});
          }
          if(resp.financial_year){
            if(reports){
              let from_date = resp.financial_year.start_from;
              this.setState({FromDate:new Date(from_date)});
            }
          }
         
      }.bind(this)).catch(function(res){
          if(res.response){
            if(res.response.data){
                
            }
          }
      })
  }

  handleGetGeneratedTrialBalance =()=>{
    let body = {};
          body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
          }
        let api = app_api+'/get_generated_trial_balance';
        axios.post(api, body)
          .then(function (response) {
              let data = response.data;
              let resp = data.response;
              this.setState({generated_trial_balance:resp});
              let _this = this;
              
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })
  }
  handleGetGeneratedTradingAccount =()=>{
    let body = {};
          body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
          }
        let api = app_api+'/get_generated_trading_account';
        axios.post(api, body)
          .then(function (response) {
              let data = response.data;
              let resp = data.response;
              this.setState({generated_trading_accounts:resp});
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })
  }

  handleProcessCustomerTCSReport = (type,customer_id,index)=>{
    let total_contact_loaded = this.state.total_contact_loaded;
    total_contact_loaded = total_contact_loaded + 1;
    let body = {};
      body = {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          total_contact : this.state.total_contact,
          load_type : type,
          contact_id : customer_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
      }
    let api = app_api+'/generate_customer_tcs';
    axios.post(api, body)
      .then(function (response) {
        let data = response.data;
        let total_tcs = data.total_tcs;
        if(type==='all'){
          let total_contact = data.total_contact;
          let progress = (total_contact_loaded / total_contact) * 100;
          progress = utils.currency_format(progress);
          progress = Math.round(progress);
          
          this.setState({total_contact:total_contact})
          let con_id = data.contact_id;
          this.setState({total_contact_loaded:total_contact_loaded})
          if(con_id>0){
            this.setState({ progress });
            this.handleProcessCustomerTCSReport(type,customer_id,index);
          }else{
            var element = document.getElementById('process-tcs-transaction');
            element.classList.add("fade");
            element.style.display="none"; 
            this.setState({progress:0});
            window.location.reload();
          }
        }else{
          let customer_tcs_report = this.state.customer_tcs_report;
          customer_tcs_report[index].amount = total_tcs;
          this.setState({customer_tcs_report:customer_tcs_report});
          document.getElementById('refresh-tcs-'+customer_id).classList.remove("hide");
          document.getElementById('refresh-tcs-'+customer_id+'-spin').classList.add("hide");
          //window.location.reload();
        }
    }.bind(this)).catch(function(res){
        if(res.response){
            if(res.response.data){
            }
        }
    })
  }

  handleGenerateCustomerTCSReport = (e) =>{
    let type = e.currentTarget.getAttribute('data-type');
    if(type==='all'){
      var element = document.getElementById('process-tcs-transaction');
      element.classList.remove("fade");
      element.style.display="block"; 
      this.handleProcessCustomerTCSReport(type,0,0);
    }else if(type==='customer'){
      let customer_id = e.currentTarget.getAttribute('data-id');
      let id = e.currentTarget.id;
      let index = e.currentTarget.getAttribute('data-index');
      document.getElementById(id).classList.add("hide");
      document.getElementById(id+'-spin').classList.remove("hide");
      /*var element = document.getElementById('process-tcs-transaction');
      element.classList.remove("fade");
      element.style.display="block";  */
      this.handleProcessCustomerTCSReport(type,customer_id,index);
    }
  }

  handleShowInventoryReport = e =>{
    let contact_name = document.getElementById("contact_box").value;
    let customer = localStorage.getItem("selected_contact");
    let page = 0;
    if(contact_name==="" || contact_name==='All'){
      page = 1;
      customer = 'All';
    }else if(contact_name==='No Customer'){
      customer = 'none';
    }else{
      customer = document.getElementById("customer-dropdown").value;
    }
    let id = e.currentTarget.id;
    document.getElementById(id).classList.add("hide");
    document.getElementById(id+'-btn-spinner').classList.remove("hide");
    let body = {
        page : page,
        target:'report',
        store_name : user_info.store_name,
        branch_id : user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
        type:customer,
     }
     this.setState({inventory_list: []});
    this.setState({loading_process:'Loading...'});
    let api = app_api+'/inventory/page';
    axios.post(api, body)
      .then(function (response) {
          let page_count = response.data.number_of_page;
        if(response.data.is_empty){
          this.setState({loading_process:'Record(s) not found'});
          this.setState({current_page:0});
          this.setState({total_page:0});
          this.setState({total_inv_available:0});
          this.setState({total_inv_pp:0});
          this.setState({total_inv_sp:0});
        }else{
          this.setState({total_available:response.data.total_available});
          this.setState({total_amount:response.data.total_amount});
          this.setState({total_sp:response.data.total_sp});
          this.setState({total_pp:response.data.total_pp});
          this.setState({total_page:page_count});
          this.setState({inventory_list: response.data.data});

          this.setState({total_inv_available:response.data.total_quantity});
          this.setState({total_inv_pp:response.data.total_purchase_price});
          this.setState({total_inv_sp:response.data.total_selling_price});

          this.setState({total_purchase_value:response.data.total_purchase_value});
          this.setState({total_purchase_tax:response.data.total_tax});
        }
        document.getElementById(id).classList.remove("hide");
        document.getElementById(id+'-btn-spinner').classList.add("hide");
    }.bind(this)).catch(function(res){
        if(res.response){
           this.setState({loading_process:'Record(s) not found'});
        }
    })
  }


  reload_contact_pending_invoice = () =>{
      let contact_ids = this.state.contact_ids;
      for (let index = 0; index < contact_ids.length; index++) {
        const id = contact_ids[index];
        const contact_info = this.state.customer_pending_invoice_report;
        let contact_type = contact_info[id][0].contact_type;
        this.get_contact_pending_invoice_on_id(id,contact_type)
      }
  }


  get_contact_pending_invoice_on_id = async (contact_id,contact_type) =>{
    let body = {
        store_name : user_info.store_name,
        branch_id : user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
        active_tab : 'customer-pending',
        contact_type : contact_type,
        contact_id : contact_id,
    }
    let api = app_api+'/show_report';
    await axios.post(api, body)
      .then(function (response) { 
        let resp = response.data;
        let pending_invoices = resp.data[contact_id];
        if(pending_invoices!==undefined){
          let pen_invoice = pending_invoices.length;
          let total_pendings = resp.total_pendings[contact_id];
          let contact_ids = resp.contact_ids;
          let contact_names = resp.contact_names[contact_id];
          let share_data = resp.share_data[contact_id].data;
          let customer_info = this.state.customer_pending_invoice_report;
          customer_info[contact_id][0].pen_invoice = pen_invoice;
          customer_info[contact_id][0].pending_amount = total_pendings;
          customer_info[contact_id][0].invoices = pending_invoices;
          customer_info[contact_id][0].contact_ids = contact_ids;
          customer_info[contact_id][0].contact_names = contact_names;
          customer_info[contact_id][0].share_data = share_data;
          this.setState({customer_pending_invoice_report : customer_info});
        }else{
          let contact_ids = this.state.contact_ids;
          const index = contact_ids.indexOf(contact_id);
          if (index > -1) {
            contact_ids.splice(index, 1); // 2nd parameter means remove one item only
          }
          this.setState({contact_ids:contact_ids});
        }
    }.bind(this)).catch(function(res){
        if(res.response){ 
          if(res.response.data){
              
          }
        }
    })
  }

  handleLoadPendingInvoice = (e)=>{

      if(document.getElementById("tag-customer-dropdown")===null){
        return false;
      }

      if(document.getElementById("tag-customer-dropdown") && document.getElementById("tag-customer-dropdown").value===""){
        document.getElementById("contact_box").focus();
        return false;
      }

      this.setState({is_showing_pending_invoice:true,customer_pending_invoice_report:[],contact_ids:[],contact_names:[],contact_types:[],total_pendings:[],loading_process:'Loading...',customer_pending_invoices:[],customer_pending_payments:[]});
      let id = e.currentTarget.id;
      document.getElementById(id).classList.add("hide");
      document.getElementById(id+'-btn-spinner').classList.remove("hide");
      let format_type = document.getElementById("pending-invoice-format-type").value;
      let load_type = document.getElementById("pending-invoice-load-type").value;

      let load_key = '';
      if(load_type==="1" || load_type==="2" || load_type==="5"){
        load_key = 'contact';
      }else{
        load_key = load_type;
      }
      this.setState({selected_pending_invoice_contact_type:load_type});
      let load_value = '';
      let contact_name = '';
      
      if(document.getElementById("customer-dropdown")){
        //load_value = localStorage.getItem('selected_contact');
        contact_name = document.getElementById("contact_box").value;
        load_value = document.getElementById("tag-customer-dropdown").value;
      }
      if(document.getElementById("group_id")){
        load_value = document.getElementById("group_id").value;
        if(load_value===""){
          document.getElementById("group_id").focus();
          return false;
        }
      }
      if(document.getElementById("pending-invoice-state")){
        load_value = document.getElementById("pending-invoice-state").value;
        if(load_value===""){
          document.getElementById("pending-invoice-state").focus();
          return false;
        }
      }
      
      this.setState({is_all_contact:false});
      this.setState({clicked_show_more_pi_btn:false});
      //this.setState({is_show_more_pending_invoice:true});
      //this.setState({cpipage:2});
      let body = {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          active_tab : 'customer-pending',
          contact_name : contact_name,
          format_type : format_type,
          load_type : load_type,
          load_key : load_key,
          load_value : load_value,
          from_date : this.state.FromDate,
          to_date : this.state.ToDate,
          page : 1,
      }
      let api = app_api+'/show_report';
      axios.post(api, body)
        .then(function (response) { 
          let resp = response.data;
          document.getElementById(id).classList.remove("hide");
          document.getElementById(id+'-btn-spinner').classList.add("hide");
            
          //if(is_all_contact===false){
            if(resp.data.length===undefined){
              this.setState({customer_pending_invoice_report:resp.data,customer_pending_invoices:resp.pending_invoices,customer_pending_payments:resp.pending_payments,contact_ids:resp.contact_ids,contact_names:resp.contact_names,contact_types:resp.contact_types,total_pendings:resp.total_pendings,pending_share_data:resp.share_data,report_company_info:resp.company_info,pending_report_opening_balance:resp.ob_data});
            }else{
              this.setState({customer_pending_invoice_report:[],customer_pending_invoices:[],customer_pending_payments:[],contact_ids:[],contact_names:[],pending_share_data:[],pending_report_opening_balance:[]});
              this.setState({loading_process:'Record(s) not found'});
            }
           /* }else{
            if(resp.data.length===undefined){
              this.setState({customer_pending_invoice_report:resp.data,contact_ids:resp.contact_ids,contact_names:[],pending_share_data:[]});
              this.reload_contact_pending_invoice();
            }else{
              this.setState({customer_pending_invoice_report:[],contact_ids:[],contact_names:[],pending_share_data:[]});
              this.setState({loading_process:'Record(s) not found'});
            }
          } */
      }.bind(this)).catch(function(res){
        document.getElementById(id).classList.remove("hide");
        document.getElementById(id+'-btn-spinner').classList.add("hide");
      })
  }

  /* handleLoadPendingInvoice = (e) =>{
    this.setState({is_showing_pending_invoice:true,customer_pending_invoice_report:[],contact_ids:[],contact_names:[],total_pendings:[],loading_process:'Loading...',});
    let contact_type = document.getElementById("contact_type").value;
    this.setState({selected_pending_invoice_contact_type:contact_type});
    let id = e.currentTarget.id;
    document.getElementById(id).classList.add("hide");
    document.getElementById(id+'-btn-spinner').classList.remove("hide");
    let contact_id = document.getElementById("customer-dropdown").value;
    let is_all_contact = true;
    if(contact_id!=='' && contact_id!=='0'){
      is_all_contact = false;
    }
    this.setState({is_all_contact:is_all_contact});
    this.setState({clicked_show_more_pi_btn:false});
    this.setState({is_show_more_pending_invoice:true});
    this.setState({cpipage:2});
    let body = {
        store_name : user_info.store_name,
        branch_id : user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
        active_tab : 'customer-pending',
        contact_type : contact_type,
        contact_id : contact_id,
        page : 1,
     }
    let api = app_api+'/show_report';
    axios.post(api, body)
      .then(function (response) { 
        let resp = response.data;
        document.getElementById(id).classList.remove("hide");
        document.getElementById(id+'-btn-spinner').classList.add("hide");

        if(is_all_contact===false){
          if(resp.data.length===undefined){
            this.setState({customer_pending_invoice_report:resp.data,contact_ids:resp.contact_ids,contact_names:resp.contact_names,total_pendings:resp.total_pendings,pending_share_data:resp.share_data});
          }else{
            this.setState({customer_pending_invoice_report:[],contact_ids:[],contact_names:[],pending_share_data:[]});
            this.setState({loading_process:'Record(s) not found'});
          }
        }else{
          if(resp.data.length===undefined){
            this.setState({customer_pending_invoice_report:resp.data,contact_ids:resp.contact_ids,contact_names:[],pending_share_data:[]});
            this.reload_contact_pending_invoice();
          }else{
            this.setState({customer_pending_invoice_report:[],contact_ids:[],contact_names:[],pending_share_data:[]});
            this.setState({loading_process:'Record(s) not found'});
          }
        }
    }.bind(this)).catch(function(res){
        if(res.response){
          if(res.response.data){
              
          }
        }
    })
  } */

  loadSalesmanReport =(e) =>{
    this.setState({sales_man_ids:[]});
    this.setState({sales_man_names:[]});
    this.setState({sales_man_grand_total:0});
    this.setState({total_sm_comission:0});
    this.setState({sales_man_report:[],loading_process:'Loading...',});
    let id = e.currentTarget.id;
  
    document.getElementById(id).classList.add("hide");
    document.getElementById(id+'-btn-spinner').classList.remove("hide");
    let contact_id = document.getElementById("staff-dropdown").value;
    if(contact_id!==""){
      this.setState({is_staff_selected:true});
    }else{
      this.setState({is_staff_selected:false});
    }
    let body = {
        store_name : user_info.store_name,
        branch_id : user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
        active_tab : 'sales-man',
        contact_id : contact_id,
        FromDate : this.state.FromDate,
        ToDate : this.state.ToDate,
     }
    let api = app_api+'/show_report';
    axios.post(api, body)
      .then(function (response) { 
        let resp = response.data;
        document.getElementById(id).classList.remove("hide");
        document.getElementById(id+'-btn-spinner').classList.add("hide");
       
        if(resp.contact_ids.length>0){
          this.setState({sales_man_report:resp.data});
          this.setState({sales_man_names:resp.contact_names});
          this.setState({sales_man_ids:resp.contact_ids});
          this.setState({sales_man_grand_total:resp.total_amount});
          this.setState({total_sm_comission:resp.total_comission});
        }else{
          this.setState({sales_man_report:[]});
          this.setState({sales_man_ids:[]});
          this.setState({sales_man_names:[]});
          this.setState({loading_process:'Record(s) not found'});
          this.setState({sales_man_grand_total:0});
          this.setState({total_sm_comission:0});
        }
    }.bind(this)).catch(function(res){
        if(res.response){
          if(res.response.data){
              
          }
        }
    })
  }

  handleGenerateTaxReport = () =>{
    var element = document.getElementById('process-tax-report');
    element.classList.remove("fade");
    element.style.display="block"; 
    this.handleLoadTaxReport(1);
  }

  hanldeLoadedAllLoder = (progress)=>{
    
    let  _this = this;
    this.gst_progress_interval = setInterval(() => {
      _this.setState({progress:progress});
      progress = progress + 1;
    }, 500);
  }

  handleShowGSTTaxReport = ()=>{
    
    let _this = this;
    let progress = 0;
  /*   setTimeout(() => {
      _this.setState({progress:progress+1});
    }, 500);
 */
    
    this.setState({progress:0});
    this.setState({gst_tax_report_download_msg:'Please wait while generating tax report'});
    const config = {
      onUploadProgress: progressEvent => {
       // let { progress } = this.state;
        progress = (progressEvent.loaded / progressEvent.total) * 100;
        progress = utils.currency_format(progress);
        progress = Math.round(progress);
        
        this.setState({ progress });
        if(progress>=100){
          this.setState({progress:0});
          this.setState({gst_tax_report_download_msg:'All Set! Please wait for a while...'});
          this.hanldeLoadedAllLoder(1);
        }
      }
    }
    
    this.setState({tax_report:[]});
    
    this.setState({loading_process:'Loading...'});
    
    let tax_type = "";
    if(document.getElementById("tax-type")){
      tax_type = document.getElementById("tax-type").value;
    }
    let tax_section = "";
    if(document.getElementById("tax-section")){
      tax_section = document.getElementById("tax-section").value;
    }

    let body = {
        FromDate : this.state.FromDate,
        ToDate : this.state.ToDate,
        store_name : user_info.store_name,
        branch_id : user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
        tax_type : tax_type,
        tax_section : tax_section,
        gst_sales : this.state.gst_sales,
        gst_sales_return : this.state.gst_sales_return,
     }
    
    let api = app_api+'/show_gst_report';
    axios.post(api, body,config)
      .then(function (response) { 
          this.setState({progress:100});
          let resp = response.data.data;
          let gst_json_file = response.data.gst_json_file;
          if(resp.length>0 || resp.length===undefined){
            this.setState({no_hsn_found_data:response.data.no_hsn_found_data});
            this.setState({total_b2b:response.data.total_b2b});
            this.setState({total_b2c:response.data.total_b2c});
            this.setState({total_cdnr:response.data.total_cdnr});
            this.setState({total_hsn:response.data.total_hsn});
            this.setState({tax_report:resp});
            this.setState({total_collected_tax:response.data.total_collected_tax,gst_json_file:gst_json_file});
          }else{
            this.setState({tax_report:[]});
            this.setState({loading_process:'Record(s) not found'});
            this.setState({total_collected_tax:0});
          }  
          
          var element = document.getElementById('process-tax-report');
          element.classList.add("fade");
          element.style.display="none"; 
          this.gst_progress_interval.clearInterval();
          this.setState({progress:0,gst_tax_report_download_msg:'Please wait while fetching tax report from server',gst_page_no:1,number_of_gst_record:0,number_of_gst_pages:0,gst_sales:[],gst_sales_return:[]});

    }.bind(this)).catch(function(res){
        if(res.response){
          if(res.response.data){
              
          }
        }
    })
  }

  handleLoadTaxReport = (page) =>{
    
    let body = {
        store_name : user_info.store_name,
        branch_id : user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
        FromDate : this.state.FromDate,
        ToDate : this.state.ToDate,
        ToDate : this.state.ToDate,
        count : this.state.number_of_gst_record,
        page : page,
    }
    let api = app_api+'/get_gst_report';
    axios.post(api, body)
      .then(function (response) { 
        let resp = response.data;
        let number_of_page   = resp.number_of_page;
        let number_of_record   = resp.number_of_record;
        let sales   = resp.sales;
        let sales_return   = resp.sales_return;
        if(this.state.number_of_gst_pages===0){
          this.setState({number_of_gst_pages:number_of_page});
        }
        this.setState({number_of_gst_record:number_of_record});
        let sales_record = this.state.gst_sales;
        let sales_return_record = this.state.gst_sales_return;
        for (let index = 0; index < sales.length; index++) {
          const element = sales[index];
          sales_record.push(element);
        }
        for (let index = 0; index < sales_return.length; index++) {
          const element = sales_return[index];
          sales_return_record.push(element);
        }
        this.setState({gst_sales:sales_record,gst_sales_return:sales_return_record});

        let total_loaded = sales_return_record.length+sales_record.length;
        let progress = (total_loaded/number_of_record)*100;
        //progress = progress/1.5;
        progress = utils.currency_format(progress);
        if(sales.length>0 || sales_return.length>0){
          let next_page = page+1;
          this.setState({gst_page_no:next_page});
          if(progress>100){
            progress = 100;
          }
          this.setState({progress:Math.round(progress)});
          this.handleLoadTaxReport(next_page);
        }else{
          this.handleShowGSTTaxReport();
        }
      }.bind(this)).catch(function(res){
        if(res.response){
          if(res.response.data){
              
          }
        }
    })
  }

  handleFetchPayableReceivableReport = (id,page) =>{
    let total_contact_loaded = this.state.total_contact_loaded;
    total_contact_loaded = total_contact_loaded + 25;
    let format = "";
    if(document.getElementById("creditors-debitors")){
      format = document.getElementById("creditors-debitors").value;
    }
    let group = "all";
    if(document.getElementById("group_id")){
      group = document.getElementById("group_id").value;
    }
    let body = {
      FromDate : this.state.FromDate,
      ToDate : this.state.ToDate,
      store_name : user_info.store_name,
      branch_id : user_info.login_id,
      user_id : user_info.user_id,
      uuid : utils.uuid(),
      role_id : user_info.access_level,
      token : user_info.token,
      _schema : user_info._schema,
      format : format,
      group : group,
      customers:this.state.payable_receivable_customers,
      customers_dropdown:this.state.payable_receivable_customers_dropdown,
      customer_data:this.state.payable_receivable_customers_data,
      total_contact : this.state.total_contact,
      page : page,
      transaction_opt : this.state.transaction_opt
    }
  
    let api = app_api+'/get_payable_receivable_report';
    axios.post(api, body)
      .then(function (response) { 
          let data = response.data;
          let total_contact = data.total_contact;
          let total_page = data.total_page;
          let progress = (page/total_page) * 100;
          progress = utils.currency_format(progress);
          progress = Math.round(progress);
          if(progress>100){
            progress = 100;
          }
          this.setState({total_contact:total_contact})
          this.setState({payable_receivable_customers:data.customers})
          this.setState({payable_receivable_customers_data:data.customers_data})
          this.setState({payable_receivable_customers_dropdown:data.customers_dropdown})
          let con_id = data.contact_id;
          this.setState({total_contact_loaded:total_contact_loaded})
          if(data.customers.length>0){
            //let creditors_debitors_report = this.state.creditors_debitors_report;
            //creditors_debitors_report.push(data);
            this.setState({creditors_debitors_report:data});
            this.setState({creditors_debitors_report_tmp:data});
            this.setState({customers_ids:data.customers});
          }
          
          if(con_id>0){
            this.setState({ progress });
            page = page+1;
            this.handleFetchPayableReceivableReport(id,page);
          }else{
            this.setState({progress:100});
            document.getElementById(id).classList.remove("hide");
            document.getElementById(id+'-btn-spinner').classList.add("hide");
            let _th = this;
            setTimeout(() => {
              var element = document.getElementById('process-payable-receivable-report');
              element.style.display="none"; 
              this.setState({progress:0});
            }, 100);
           //window.location.reload();
          }
       
        /* this.setState({customers_ids:data.customers});
        this.setState({total_contact:data.total_contact});
        this.setState({customer_total:data.total_count});
        this.setState({customer_adjusted:data.total_adjusted});
        this.setState({customer_balance:data.total_count - data.total_adjusted}); */
        if(response.data.response.length===0){
          this.setState({loading_process:'Record(s) not found'});
        }
        
    }.bind(this)).catch(function(res){
      document.getElementById('view-report').classList.remove("hide");
      document.getElementById('view-report-btn-spinner').classList.add("hide");
      this.setState({progress:-1});
    }.bind(this))
  }

  handleProcessSalesChartReport = (id,page) =>{
    
    let report_type = "";
    if(document.getElementById("report-type")){
      report_type = document.getElementById("report-type").value;
    }
    let body = {
      FromDate : this.state.FromDate,
      ToDate : this.state.ToDate,
      store_name : user_info.store_name,
      branch_id : user_info.login_id,
      user_id : user_info.user_id,
      uuid : utils.uuid(),
      role_id : user_info.access_level,
      token : user_info.token,
      _schema : user_info._schema,
      active_tab : this.state.active_tab,
      filter_option : this.state.filter_option,
      total_record : this.state.total_record,
      labels : this.state.labels,
      count_data : this.state.count_data,
      amount_data : this.state.amount_data,
      total_amount_arr : this.state.total_amount_arr,
      total_sales_arr : this.state.total_sales_arr,
      page : page,
      report_type : report_type,
    }
  
    let api = app_api+'/show_sales_report_chart';
    axios.post(api, body)
      .then(function (response) { 
        let resp = response.data.resp;
        let number_of_page = response.data.number_of_page;
        let total_record = response.data.total_record;
        this.setState({total_record:total_record})

        let total_sales_arr = response.data.total_sales_arr;
        this.setState({total_sales_arr:total_sales_arr})

        let total_amount_arr = response.data.total_amount_arr;
        this.setState({total_amount_arr:total_amount_arr})
        
        let total_earning = response.data.total_sale_amount;
        let total_sale = response.data.total_sale_count;

        let total_sale_count = this.state.total_sale;
        total_sale_count = total_sale_count + total_sale;
        this.setState({total_sale:total_sale_count});

        let total_sale_amount = this.state.total_earning;
        total_sale_amount = total_sale_amount + total_earning;
        this.setState({total_earning:total_sale_amount});


        let labels = response.data.labels;
        let count_data = response.data.count_data;
        let amount_data = response.data.amount_data;
        
        let labels_tmp = this.state.labels;
        let count_data_tmp = this.state.count_data;
        let amount_data_tmp = this.state.amount_data;
        for (let index = 0; index < labels.length; index++) {
          const element = labels[index];
          labels_tmp.push(element);
        }
        for (let index = 0; index < count_data.length; index++) {
          const element = count_data[index];
          count_data_tmp.push(element);
        }
        for (let index = 0; index < amount_data.length; index++) {
          const element = amount_data[index];
          amount_data_tmp.push(element);
        }
        let progress = (page/number_of_page)*100;
        progress = utils.currency_format(progress);
        
        
        if(progress>100){
          progress = 100;
        }
        this.setState({progress:Math.round(progress)});
      
        if(page<=number_of_page){
          page = page+1;
          this.handleProcessSalesChartReport(id,page);
        }else{
          let chart_table_format = response.data.table_format;
          let total_earning = response.data.total_sale_amount;
          let total_sale = response.data.total_sale_count;
          let labels = response.data.labels;
          let count_data = response.data.count_data;
          let amount_data = response.data.amount_data;
          this.setState({total_earning:total_earning});
          this.setState({total_sale:total_sale});
          this.setState({chart_table_format:chart_table_format});

          this.setState({chart_data:resp});
          document.getElementById(id).classList.remove("hide");
          document.getElementById(id+'-btn-spinner').classList.add("hide");
        }
    }.bind(this)).catch(function(res){
        if(res.response){
          if(res.response.data){
              
          }
        }
    })
  }

  handleShowSalesChartReport = (e) =>{
    this.setState({progress:0})
    this.setState({total_record:0})
    this.setState({total_sales_arr:[]})
    this.setState({chart_data:[]});
    this.setState({labels:[]});
    this.setState({amount_data:[]});
    this.setState({count_data:[]});
    this.setState({total_amount_arr:[]})
    this.setState({total_sale:0});
    this.setState({total_earning:0});

    let id = e.currentTarget.id;
    document.getElementById(id).classList.add("hide");
    document.getElementById(id+'-btn-spinner').classList.remove("hide");
    let _this = this;
    setTimeout(() => {
      _this.handleProcessSalesChartReport(id,1);
    }, 1000);
  }

  handleShowPayableReceivableReport = (e) =>{
    this.setState({payable_receivable_customers:[]})
    this.setState({payable_receivable_customers_data:[]})
    this.setState({payable_receivable_customers_dropdown:[]})
    this.setState({creditors_debitors_report:[]})
    this.setState({creditors_debitors_report_tmp:[]})
    this.setState({customers_ids:[]})
    this.setState({total_contact_loaded:0});
    this.setState({total_contact:0});
    this.setState({progress:0});
    let id = e.currentTarget.id;
    if(document.getElementById(id)){
      document.getElementById(id).classList.add("hide");
      document.getElementById(id+'-btn-spinner').classList.remove("hide");
    }
    var element = document.getElementById('process-payable-receivable-report');
    
    element.style.display="block"; 
    let _this = this;
    setTimeout(() => {
      _this.handleFetchPayableReceivableReport(id,1);
    }, 100);
  }

  handleShowTransactionBookReport = (e) =>{
    document.getElementById('view-book-report').classList.add("hide");
    document.getElementById('view-book-report-btn-spinner').classList.remove("hide");
    this.setState({transaction_book_report:[]});
    let report_type = document.getElementById("report-type").value;
    let filter_option = this.state.filter_option;

    this.setState({loading_process:'Loading...'});
    let body = {
      FromDate : this.state.FromDate,
      ToDate : this.state.ToDate,
      store_name : user_info.store_name,
      branch_id : user_info.login_id,
      user_id : user_info.user_id,
      uuid : utils.uuid(),
      role_id : user_info.access_level,
      token : user_info.token,
      _schema : user_info._schema,
      report_type : report_type,
      filter_option : filter_option,
      transaction_opt : this.state.transaction_opt
    }
  
    let api = app_api+'/get_transaction_book_report';
    axios.post(api, body)
      .then(function (response) { 
      document.getElementById('view-book-report').classList.remove("hide");
      document.getElementById('view-book-report-btn-spinner').classList.add("hide");
      let data = response.data.response;
      if(data.length>0){
        this.setState({transaction_book_report:data});
      }else{
        this.setState({loading_process:'Oops ! No Record(s) found'});
      }
    }.bind(this)).catch(function(res){
      document.getElementById('view-book-report').classList.remove("hide");
      document.getElementById('view-book-report-btn-spinner').classList.add("hide");
    })
  }

  handleShowReport= e =>{
    let id = e.currentTarget.id;
    this.setState({reports_list:[]});
    this.setState({transaction_report:[]});
    this.setState({expenses_report:[]});
    this.setState({profit_loss_report:[]});
    this.setState({tax_report:[]});
    this.setState({tcs_tax_report:[]});
    this.setState({total_grand_amount:0});
    if(document.getElementById(id)){
      document.getElementById(id).classList.add("hide");
      document.getElementById(id+'-btn-spinner').classList.remove("hide");
    }
    let customer = "";
    let format = "";
    let report_type = "";
    let expense_type = "";
    if(document.getElementById("legder-format")){
      format = document.getElementById("legder-format").value;
    }
    if(document.getElementById("report-type")){
      report_type = document.getElementById("report-type").value;
    }

    if(document.getElementById("creditors-debitors")){
      format = document.getElementById("creditors-debitors").value;
    }

    if(document.getElementById("expense-type")){
      expense_type = document.getElementById("expense-type").value;
    }

   
    this.setState({contact_info: [] }); 
    this.setState({loading_process:'Loading...'});
    if(document.getElementById("customer-dropdown")){
        customer = document.getElementById("customer-dropdown").value;
        if(document.getElementById("handleCloseListOnOutSide")){
          document.getElementById("handleCloseListOnOutSide").click();
        }
        if(customer!=="" && customer!=="0" && customer!==0){
          if(localStorage.getItem('is_bank_account')!=='true'){
            customer = parseInt(customer);
            let body = {};
              body = {
                  store_name : user_info.store_name,
                  branch_id : user_info.login_id,
                  user_id : user_info.user_id,
                  id : customer,
                  uuid : utils.uuid(),
                  role_id : user_info.access_level,
                  token : user_info.token,
                  _schema : user_info._schema,
              }
            let api = app_api+'/get_contact_on_id';
            axios.post(api, body)
              .then(function (response) {
                  let data = response.data;
                  this.setState({contact_info: data[0] }); 
            }.bind(this)).catch(function(res){
                if(res.response){
                    if(res.response.data){
                    }
                }
            })
          }
         /*  let version = localStorage.getItem('IdbVersion');
          version = parseInt(version);
          let dbReq = window.indexedDB.open('BaazarERP', version);
          let db;
          dbReq.onupgradeneeded = function(event) {
            db = event.target.result;
          }
       
          dbReq.onsuccess = function(event) {
            db = event.target.result;
            try{
              
              let transaction = db.transaction("contacts", "readwrite");
              let contacts = transaction.objectStore("contacts");
              let request = contacts.get(customer);
              request.onsuccess = function(e) {
                let cursor =  e.target.result;
                if(cursor!==undefined){
                  let id = cursor.id;
                  let type = cursor.type;
                  let data = cursor.data;
                  let bytes = CryptoJS.AES.decrypt(data, enc_key);
                  let cval = bytes.toString(CryptoJS.enc.Utf8);
                  let con =   JSON.parse(cval);
                  
                _this.setState({contact_info: con }); 
              }
                db.close();
              }; 
            }catch{}
          } */
      }
    }

    let group = "all";
    if(document.getElementById("group_id")){
      group = document.getElementById("group_id").value;
    }
    let tax_type = "";
    if(document.getElementById("tax-type")){
      tax_type = document.getElementById("tax-type").value;
    }
    let tax_section = "";
    if(document.getElementById("tax-section")){
      tax_section = document.getElementById("tax-section").value;
    }
    let led_con_type = localStorage.getItem('ledger_contact_type');
    let is_hide_opening_balnace = false;
    if(document.getElementById("is-hide-opening-balnace")){
      is_hide_opening_balnace = document.getElementById("is-hide-opening-balnace").checked;
    }
    
    this.handleCheckIsBankAccount();
    
    let body = {
        customer : customer,
        FromDate : this.state.FromDate,
        ToDate : this.state.ToDate,
        store_name : user_info.store_name,
        branch_id : user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
        filter_option : this.state.filter_option,
        active_tab : this.state.active_tab,
        format : format,
        ledger_contact_type : led_con_type,
        report_type : report_type,
        expense_type : expense_type,
        group : group,
        tax_type : tax_type,
        tax_section : tax_section,
        contact_type : id,
        transaction_opt : this.state.transaction_opt,
        is_hide_opening_balnace : is_hide_opening_balnace,
        gst_sales : this.state.gst_sales,
        gst_sales_return : this.state.gst_sales_return,
     }
    
    let api = app_api+'/show_report';
    axios.post(api, body)
      .then(function (response) { 
        document.getElementById(id).classList.remove("hide");
        document.getElementById(id+'-btn-spinner').classList.add("hide");
        if(this.state.active_tab==='reports'){
          let resp = response.data.response;
          
          let report_date = response.data.date;
          let report_time = response.data.time;
          let ledger_from_date = response.data.from_date;
          let ledger_to_date = response.data.to_date;
          if((format==='format1' || format==='format2' || format==='format3') && resp.sp_report_data.report_data.length>0){
            this.setState({ledger_from_date:ledger_from_date,ledger_to_date:ledger_to_date});
            this.setState({reports_list:resp,report_date:report_date,report_time:report_time});
          }else if(resp.sp_report_data.report_data.length>0 || (resp.sr_pr_report_data && resp.sr_pr_report_data.report_data.length>0) || (resp.pay_report_data && resp.pay_report_data.report_data.length>0)){
            this.setState({reports_list:resp,report_date:report_date,report_time:report_time});
          }else{
            this.setState({loading_process:'Record(s) not found'});
            this.setState({reports_list:[],report_date:'',report_time:''});
          }
        }else if(this.state.active_tab==='sales_report' && this.state.report_type==='Chart'){
          this.setState({chart_data:response.data.resp,total_earning:response.data.total_sale_amount,total_sale:response.data.total_sale_count});
        }else if(this.state.active_tab==='sales_report' && this.state.report_type==='CreditDebit'){

          this.setState({creditors_debitors_report:response.data});
          this.setState({customers_ids:response.data.customers});
          this.setState({customer_total:response.data.total_count});
          this.setState({customer_adjusted:response.data.total_adjusted});
          this.setState({customer_balance:response.data.total_count - response.data.total_adjusted});
          if(response.data.response.length===0){
            this.setState({loading_process:'Record(s) not found'});
          }
        }else if(this.state.active_tab==='transactions'){
          let resp = response.data.response;
          let payment_mode_data = response.data.payment_mode_data;
          let payment_mode_total = response.data.payment_mode_total;
          this.setState({payment_mode_total:payment_mode_total});
          if(response.data.total_count>0){
            if(document.getElementById("transaction-total")){
              if(this.state.filter_option==='today'){
                document.getElementById("transaction-total").classList.add('hide');
              }else{
                document.getElementById("transaction-total").classList.remove('hide');
              }
            }
            this.setState({transaction_report:resp,transaction_report_temp:resp,total_transaction:response.data.total_count,assisted_by_option:response.data.assisted_by_option,payment_mode_option:response.data.payment_mode_option,payment_mode_transaction_report:payment_mode_data});
          }else{
            this.setState({transaction_report:[],total_transaction:response.data.total_count,assisted_by_option:[],payment_mode_option:[],payment_mode_transaction_report:[]});
            this.setState({loading_process:'Record(s) not found'});
          }
        }else if(this.state.active_tab==='expenses'){
          let resp = response.data.response;
          if(resp.length>0){
            this.setState({expenses_report:resp});
            this.setState({total_grand_amount:response.data.total_grand_amount});
          }else{
            this.setState({expenses_report:[]});
            this.setState({loading_process:'Record(s) not found'});
            this.setState({total_grand_amount:0});
          }
        }else if(this.state.active_tab==='profit_loss'){
          let resp = response.data;
          if(resp.length>0){
            resp = resp[0];
            this.setState({profit_loss_report:resp,profit_loss_from:resp.profit_loss_from,profit_loss_to:resp.profit_loss_to});
            //this.setState({total_grand_amount:response.data.total_grand_amount});
          }else{
            this.setState({profit_loss_report:[]});
            //this.setState({loading_process:'Record(s) not found'});
            //this.setState({total_grand_amount:0});
          }
        }else if(this.state.active_tab==='tax'){
          let resp = response.data.data;
          //let gst_json = response.data.gst_json;
          let gst_json_file = response.data.gst_json_file;
          if(resp.length>0 || resp.length===undefined){
            this.setState({no_hsn_found_data:response.data.no_hsn_found_data});
            this.setState({total_b2b:response.data.total_b2b});
            this.setState({total_b2c:response.data.total_b2c});
            this.setState({total_cdnr:response.data.total_cdnr});
            this.setState({total_hsn:response.data.total_hsn});
            this.setState({tax_report:resp});
            this.setState({total_collected_tax:response.data.total_collected_tax,gst_json_file:gst_json_file});
          }else{
            this.setState({tax_report:[]});
            this.setState({loading_process:'Record(s) not found'});
            this.setState({total_collected_tax:0});
          }
        }else if(this.state.active_tab==='tcs_tax'){
          let resp = response.data.data;
          //let gst_json = response.data.gst_json;
          let gst_json_file = response.data.gst_json_file;
          if(resp.length>0 || resp.length===undefined){
            this.setState({tcs_tax_report:resp});
          }else{
            this.setState({tcs_tax_report:[]});
            this.setState({loading_process:'Record(s) not found'});
            
          }
        }
        
    }.bind(this)).catch(function(res){
        if(res.response){
          if(res.response.data){
              
          }
        }
    })
  }

  handleLoadMoreCustomerTCSReport = () =>{
    this.setState({is_load_more_clicked:true});
    this.handleShowCustomerTCSReport();
  }

  handleShowCustomerTCSReport = () =>{
    let customer_tcs_page = this.state.customer_tcs_page;
    let body = {
        store_name : user_info.store_name,
        branch_id : user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
        page : customer_tcs_page,
    }
    let api = app_api+'/show_customer_tcs_report';
    axios.post(api, body)
      .then(function (response) { 
        customer_tcs_page = customer_tcs_page+1;
        let resp = response.data;
        let tcs_report = resp.response;
        let customer_tcs_report = this.state.customer_tcs_report;
        if(tcs_report.length<50){
          this.setState({is_show_customer_tcs_load_more:false})
        }
        for (let index = 0; index < tcs_report.length; index++) {
          const element = tcs_report[index];
          customer_tcs_report.push(element);
        }
        if(customer_tcs_report.length===0){
          this.setState({loading_process:'Oops ! No record(s) found'})
        }
        this.setState({customer_tcs_report:customer_tcs_report});
        this.setState({is_load_more_clicked:false});
        this.setState({customer_tcs_page:customer_tcs_page});
    }.bind(this)).catch(function(res){
        if(res.response){
          if(res.response.data){
              
          }
        }
    })
  }


  handleLoadMorePendingInvoice = (e)=>{
    let contact_type = document.getElementById("contact_type").value;
    let contact_id = document.getElementById("customer-dropdown").value;
   
    this.setState({is_all_contact:true,clicked_show_more_pi_btn:true});

    let body = {
        store_name : user_info.store_name,
        branch_id : user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
        active_tab : 'customer-pending',
        contact_type : contact_type,
        contact_id : contact_id,
        page : this.state.cpipage,
     }
    let api = app_api+'/show_report';
    axios.post(api, body)
      .then(function (response) { 
        let resp = response.data;
      /*   document.getElementById(id).classList.remove("hide");
        document.getElementById(id+'-btn-spinner').classList.add("hide");
 */
        this.setState({clicked_show_more_pi_btn:false});
        let customer_pending_invoice_report = this.state.customer_pending_invoice_report;
        let contact_ids = this.state.contact_ids;
        if(resp.contact_ids.length>0){

          Object.keys(resp.data).map(function(key, index) {
            customer_pending_invoice_report[key] = resp.data[key];
          });
          Object.keys(resp.contact_ids).map(function(key, index) {
            contact_ids.push(resp.contact_ids[key]);
          });
         
        }else{
          this.setState({is_show_more_pending_invoice:false});
        }
        this.setState({customer_pending_invoice_report:customer_pending_invoice_report,contact_ids:contact_ids,cpipage:this.state.cpipage+1});
        this.reload_contact_pending_invoice();
         
    }.bind(this)).catch(function(res){
        if(res.response){
          if(res.response.data){
              
          }
        }
    })
  }

  handleShowStockLedgerReport = (e) =>{
    let id = e.currentTarget.id;
    document.getElementById(id).classList.add("hide");
    document.getElementById(id+'-btn-spinner').classList.remove("hide");
    let product = document.getElementById("iitem-name").value;
    let body = {
      store_name : user_info.store_name,
        branch_id : user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
        active_tab : 'stock-ledger',
        product : product,
        FromDate : this.state.FromDate,
        ToDate : this.state.ToDate,
     }
    this.setState({inventory_list: []});
    this.setState({loading_process:'Loading...'});
    let api = app_api+'/show_report';
    axios.post(api, body)
      .then(function (response) {
        document.getElementById(id).classList.remove("hide");
        document.getElementById(id+'-btn-spinner').classList.add("hide");
        this.setState({loading_process:'Oops ! No record found.'});
        if(response.data.data.length>0){
          this.setState({inventory_list:response.data.data})
          this.setState({total_stock_ledger_quantity:response.data.tot_stock_quantity})
          this.setState({selected_stock_item:response.data.selected_item_name});
        }
    }.bind(this)).catch(function(res){
        if(res.response){
          // this.setState({loading_process:'Record(s) not found'});
        }
    })
  }
  handleShowTopSellingProduct = (e) =>{
    let show_type = document.getElementById("top-selling-price-for").value;
    let sub_opt = '';
    if(this.state.product_report_type==='supplier'){
      sub_opt = document.getElementById("tag-customer-dropdown").value;
    }
    if(this.state.product_report_type==='category'){
      sub_opt = document.getElementById("product-category-dd").value;
    }
    let id = e.currentTarget.id;
    document.getElementById(id).classList.add("hide");
    document.getElementById(id+'-btn-spinner').classList.remove("hide");
    let body = {
      store_name : user_info.store_name,
        branch_id : user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
        active_tab : 'product-report',
        option_type : this.state.product_report_type,
        sub_option : sub_opt,
        filter_option : show_type,
        FromDate : this.state.FromDate,
        ToDate : this.state.ToDate,
     }
    this.setState({top_selling_product_report: []});
    this.setState({loading_process:'Loading...'});
    let api = app_api+'/show_report';
    axios.post(api, body)
      .then(function (response) {
        let data = response.data.response;
        let grand_profit = response.data.grand_profit;
        let grand_quantity = response.data.grand_quantity;
        let grand_revenue = response.data.grand_revenue;
        let total_global_discount = response.data.total_global_discount;
        let total_selling_price = response.data.total_selling_price;
        let average_margin = response.data.average_margin;

        document.getElementById(id).classList.remove("hide");
        document.getElementById(id+'-btn-spinner').classList.add("hide");
        this.setState({loading_process:'Oops ! No record found.'});
        this.setState({top_selling_product_report:data,grand_profit:grand_profit,grand_quantity:grand_quantity,grand_revenue:grand_revenue,average_margin:average_margin,total_global_discount:total_global_discount,total_selling_price:total_selling_price});
    }.bind(this)).catch(function(res){
        if(res.response){
          // this.setState({loading_process:'Record(s) not found'});
        }
    })
  }

  hanldeShowRetainedCustomers = ()=>{
    let body = {
        store_name : user_info.store_name,
        branch_id : user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
        load_type : this.state.retained_customer_load_type
     }
     let api = app_api+'/generate_retained_customers';
     axios.post(api, body)
       .then(function (response) {
         let data = response.data;
         let new_percent = data.new_percent;
         let retained_percent = data.retained_percent;
         this.setState({new_customer_percent:new_percent,retained_customer_percent:retained_percent});
         const ret_graph_options = {
           animationEnabled: true,
           exportEnabled: true,
           title: {
             text: "Retained Customer",
             fontFamily: "verdana"
           },
           axisY: {
             title: "",
             includeZero: true,
           },
           toolTip: {
             shared: true,
             reversed: true
           },
           legend: {
             cursor: "pointer",
             itemclick: this.toggleDataSeries
           },
           data: [
             {
               type: "stackedColumn",
               name: "First Time",
               showInLegend: true,
               toolTipContent: "{y} (#percent%)",
               valueFormatString: "#0.#,.",
               dataPoints: data.new_customers
             },
             {
               type: "stackedColumn",
               name: "Returning",
               showInLegend: true,
               toolTipContent: "{y} (#percent%)",
               valueFormatString: "#0.#,.",
               dataPoints: data.retained_customers
             }
           ]
         }
 
         this.setState({retained_customer_graph:ret_graph_options})
         
     }.bind(this)).catch(function(res){
         if(res.response){
           // this.setState({loading_process:'Record(s) not found'});
         }
     })
  }

  handleShowRetainedCustomers = (e) =>{
   
    let id = e.currentTarget.id;
    document.getElementById(id).classList.add("hide");
    document.getElementById(id+'-btn-spinner').classList.remove("hide");
    let body = {
      store_name : user_info.store_name,
        branch_id : user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
        FromDate : this.state.FromDate,
        ToDate : this.state.ToDate,
        month : this.state.selected_month,
     }
    this.setState({retained_customer_report: []});
    this.setState({loading_process:'Loading...'});
    let api = app_api+'/generate_retained_customers';
    axios.post(api, body)
      .then(function (response) {
        let data = response.data;
        document.getElementById(id).classList.remove("hide");
        document.getElementById(id+'-btn-spinner').classList.add("hide");
       /*  this.setState({loading_process:'Oops ! No record found.'});
        const ret_graph_options = {
          animationEnabled: true,
          exportEnabled: true,
          title: {
            text: "Retained Customer",
            fontFamily: "verdana"
          },
          axisY: {
            title: "",
            includeZero: true,
          },
          toolTip: {
            shared: true,
            reversed: true
          },
          legend: {
            cursor: "pointer",
            itemclick: this.toggleDataSeries
          },
          data: [
            {
              type: "stackedColumn",
              name: "First Time",
              showInLegend: true,
              valueFormatString: "#0.#,.",
              dataPoints: data.new_customers
            },
            {
              type: "stackedColumn",
              name: "Returning",
              showInLegend: true,
              valueFormatString: "#0.#,.",
              dataPoints: data.retained_customers
            }
          ]
        }

        this.setState({retained_customer_graph:ret_graph_options}) */
        this.hanldeShowRetainedCustomers();
    }.bind(this)).catch(function(res){
        if(res.response){
          // this.setState({loading_process:'Record(s) not found'});
        }
    })
  }
  handleHideTransactionFromList = (e) =>{
    let hide_transaction_list_items = this.state.hide_transaction_list_items;
    if(localStorage.getItem('hide_transaction_list_items')){
      hide_transaction_list_items = JSON.parse(localStorage.getItem('hide_transaction_list_items'));
    }
    let thisvalue = e.currentTarget.value;
    let is_checked = e.currentTarget.checked;
    if(is_checked===true){
      hide_transaction_list_items.push(thisvalue);
    }else{
      const index = hide_transaction_list_items.indexOf(thisvalue);
      if (index > -1) {
        hide_transaction_list_items.splice(index, 1);
      }
    }
    this.setState({hide_transaction_list_items:hide_transaction_list_items});
    localStorage.setItem('hide_transaction_list_items',JSON.stringify(hide_transaction_list_items))
  }
  handleHidePendingInvoiceFromList = (e) =>{
    let show_pending_invoice_list_items = this.state.show_pending_invoice_list_items;
   
    let thisvalue = e.currentTarget.value;
    let is_checked = e.currentTarget.checked;
    if(is_checked===true){
      show_pending_invoice_list_items.push(thisvalue);
    }else{
      const index = show_pending_invoice_list_items.indexOf(thisvalue);
      if (index > -1) {
        show_pending_invoice_list_items.splice(index, 1);
      }
    }
    this.setState({show_pending_invoice_list_items:show_pending_invoice_list_items});
    
  }

  handleViewTrialBalance = (index) =>{
    this.setState({is_visible_trial_balance:true});
    let response = this.state.generated_trial_balance[index];
    let selected_trial_balance = response.trial_balance_id;
    this.setState({selected_trial_balance:selected_trial_balance})
    let data = response.data.response;
    let trial_balance_total_credit = response.data.total_credit;
    let trial_balance_total_debit = response.data.total_debit;
    let debit_sym = response.data.debit_sym;
    let credit_sym = response.data.credit_sym;
    this.setState({debit_sym});
    this.setState({credit_sym});
    let trial_balance_total_credit_debit = response.data.total_credit_debit;
    let trial_balance_total_credit_credit = response.data.total_credit_credit;
    let trial_balance_total_debit_debit = response.data.total_debit_debit;
    let trial_balance_total_debit_credit = response.data.total_debit_credit;

    
    this.setState({trial_balance_total_credit_debit});
    this.setState({trial_balance_total_credit_credit});
    this.setState({trial_balance_total_debit_debit});
    this.setState({trial_balance_total_debit_credit});

    this.setState({trial_balance_total_credit});
    this.setState({trial_balance_total_debit});
    this.setState({trial_balance_report:data});
  }
  handleViewTradingAccount = (index) =>{
    this.setState({selected_trading_account:index});
    this.setState({is_visible_trading_account:true});
    let response = this.state.generated_trading_accounts[index];
    this.setState({view_trading_accounts:response});
    setTimeout(() => {
      _this.handleCalulateTotalTradingAccount();
    }, 500);
  }
  handleShowTrialBalance = (e) =>{
      this.setState({is_generating_trial_balance:true});
      this.setState({progress:0});
      let progress = 0;
      const config = {
        onUploadProgress: progressEvent => {
         // let { progress } = this.state;
          progress = (progressEvent.loaded / progressEvent.total) * 100;
          progress = utils.currency_format(progress);
          progress = Math.round(progress);
          
          this.setState({ progress });
          if(progress>=100){
            this.setState({progress:0});
            this.setState({gst_tax_report_download_msg:'All Set! Please wait for a while...'});
            //this.hanldeLoadedAllLoder(1);
          }
        }
      }
      let id = e.currentTarget.id;
      document.getElementById(id).classList.add("hide");
      document.getElementById(id+'-btn-spinner').classList.remove("hide");
      let trial_balance_filter = document.getElementById("trial-balance-select-filter").value;
      let body = {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          FromDate : this.state.FromDate,
          ToDate : this.state.ToDate,
          trial_balance_filter : trial_balance_filter
      }
      this.setState({trial_balance_report: []});
      this.setState({loading_process:'Loading...'});
      let api = app_api+'/show_trial_balance_new';
      axios.post(api, body,config)
        .then(function (response) {
          this.setState({is_generating_trial_balance:false});
          
          let data = response.data.response;
          let trial_balance_total_credit = response.data.total_credit;
          let trial_balance_total_debit = response.data.total_debit;
          let debit_sym = response.data.debit_sym;
          let credit_sym = response.data.credit_sym;
          this.setState({debit_sym});
          this.setState({credit_sym});
          let trial_balance_total_credit_debit = response.data.total_credit_debit;
          let trial_balance_total_credit_credit = response.data.total_credit_credit;
          let trial_balance_total_debit_debit = response.data.total_debit_debit;
          let trial_balance_total_debit_credit = response.data.total_debit_credit;

          
          this.setState({trial_balance_total_credit_debit});
          this.setState({trial_balance_total_credit_credit});
          this.setState({trial_balance_total_debit_debit});
          this.setState({trial_balance_total_debit_credit});

          this.setState({trial_balance_total_credit});
          this.setState({trial_balance_total_debit});
          document.getElementById(id).classList.remove("hide");
          document.getElementById(id+'-btn-spinner').classList.add("hide");
          if(data){
            this.setState({trial_balance_report:data});
          }
          if(data && data.length===0){
            this.setState({loading_process:'No Data Found'});
          }
          this.handleGetGeneratedTrialBalance();
      }.bind(this)).catch(function(res){
          document.getElementById('show-inventory-report').classList.remove("hide");
          document.getElementById('show-inventory-report-btn-spinner').classList.add("hide");
      })
  }
  handleChangeTrialBalanceFilter = (e) =>{
    let filter_type = e.currentTarget.value;
    this.setState({trial_balance_filter_type:filter_type})
  }
  handleProcessReloadTrailBalance = (page,target) =>{
    let trial_balance_filter = document.getElementById("trial-balance-select-filter").value;
    let body = {
        store_name : user_info.store_name,
        branch_id : user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
        FromDate : this.state.FromDate,
        ToDate : this.state.ToDate,
        target : target,
        page : page,
        target_count : this.state.trail_balance_target_count,
        trial_balance_filter : trial_balance_filter
    }
    let pmapi = app_api+'/reload_trial_balance';
    axios.post(pmapi, body)
      .then(function (response) {
        let resp = response.data;
        if(resp.is_tb_exist){
          this.setState({trial_balance_generate_type:'payment'});
          this.setState({is_reload_trial_balance:false});
          document.getElementById('show-inventory-report').click();
        }else{ 
          let target_count = resp.target_count;
          let number_of_page = resp.number_of_page;
          let is_completed = resp.is_completed;
          let target = resp.target;
          
          this.setState({trail_balance_target_count:target_count});
          
          let progress = (page/number_of_page)*100;
          progress = utils.currency_format(progress);
          if(is_completed===false){
            let next_page = page+1;
            if(progress>100){
              progress = 100;
            }
            this.setState({progress:Math.round(progress)});
            this.handleProcessReloadTrailBalance(next_page,target);
          }else{
            this.setState({trail_balance_target_count:0});
            this.setState({progress:0});
            if(target==='payment'){
              target = 'expense';
              this.setState({trial_balance_generate_type:target});
              this.handleProcessReloadTrailBalance(1,'expense');
            }else if(target==='expense'){
              target = 'cash_entry';
              this.setState({trial_balance_generate_type:target});
              this.handleProcessReloadTrailBalance(1,'cash_entry');
            }else if(target==='cash_entry'){
              this.setState({trial_balance_generate_type:'payment'});
              this.setState({is_reload_trial_balance:false});
              document.getElementById('show-inventory-report').click();
            }
          }
        }
    }.bind(this)).catch(function(res){
      this.setState({is_reload_trial_balance:false});
    }.bind(this))
  }
  handleReloadTrialBalance = () =>{
      this.setState({is_reload_trial_balance:true});
      this.handleProcessReloadTrailBalance(1,'payment');
  }

    handleGenerateTradingAccount = () =>{
      this.setState({is_generating_trading_account:true});;
        let body = {
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            trial_balance_id : this.state.selected_trial_balance
        }
        let pmapi = app_api+'/generate_trading_account';
        axios.post(pmapi, body)
          .then(function (response) {
            let cursor = response.data; 
            this.setState({is_generating_trading_account:false});
            window.location.reload();
        }.bind(this)).catch(function(res){
          this.setState({is_generating_trading_account:false});
        }.bind(this))
    }

    handleSaveTradingAccount = () =>{
      let generated_trading_accounts = this.state.generated_trading_accounts;
      let selected_trading_account = this.state.selected_trading_account;
      let trading_account_id = this.state.view_trading_accounts.trading_account_id;
      generated_trading_accounts[selected_trading_account] = this.state.view_trading_accounts;
      this.setState({is_save_trading_account:true});
      let body = {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          trading_account_data : this.state.view_trading_accounts.data,
          trading_account_id : trading_account_id
      }
      let pmapi = app_api+'/save_trading_account';
      axios.post(pmapi, body)
        .then(function (response) {
          let cursor = response.data; 
          this.setState({is_save_trading_account:false});
      }.bind(this)).catch(function(res){
        this.setState({is_save_trading_account:false});
      }.bind(this))
    }
    handleDeleteTrialBalance = () =>{
      let text;
      if (window.confirm("Are you sure? You want to delete this!") !== true) {
        return false;
      }

      let selected_trial_balance = this.state.selected_trial_balance;
      this.setState({is_delete_trial_balance:true});
      let body = {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          trial_balance_id : selected_trial_balance
      }
      let pmapi = app_api+'/delete_trial_balance';
      axios.post(pmapi, body)
        .then(function (response) {
          let cursor = response.data; 
          this.setState({is_delete_trial_balance:false});
          window.location.reload();
      }.bind(this)).catch(function(res){
        this.setState({is_delete_trial_balance:false});
      }.bind(this))
    }

    handleCalulateTotalTradingAccount = () =>{
      let view_trading_accounts = this.state.view_trading_accounts;
      
      let list_item_data = view_trading_accounts.data;
      let left_side_total =0;
      let right_side_total =0;
      for (let index = 0; index < list_item_data.length; index++) {
        const element = list_item_data[index];
        let this_val = element.value;
        if(this_val===""){
          this_val = 0;
        }
        this_val = parseFloat(this_val);
        if(index===0 || index===2 || index===3 || index===5){
          left_side_total = left_side_total + this_val;
        }else{
          right_side_total = right_side_total + this_val;
        }
      }
      this.setState({trading_account_left_total:left_side_total});
      this.setState({trading_account_right_total:right_side_total});
    }

    handleChangeTradingAccountValue = (index,value) =>{
      let element = this.state.view_trading_accounts;
      element.data[index].value = value;
      this.setState({view_trading_accounts:element});
      let _this = this;
      
        this.handleCalulateTotalTradingAccount();
      
    }
    handleSelectAllTransaction = (e) =>{
      let is_checked = e.target.checked;
      let qs = document.querySelectorAll('.tr-cb-item');
      for (let index = 0; index < qs.length; index++) {
        const element = qs[index];
        element.checked = is_checked;
      }
      let is_all_checked = is_checked;
      this.setState({is_show_trans_action:is_all_checked});
    }
    handleChangeTransItemAction = () =>{
      let qs = document.querySelectorAll('.tr-cb-item');
      let is_all_checked = false;
      for (let index = 0; index < qs.length; index++) {
        const is_checked = qs[index].checked;
        if(is_checked===true){
          is_all_checked = true;
        }
      }
      this.setState({is_show_trans_action:is_all_checked});
    }
    handleChangeTranAction = (e) =>{
      let thisvalue = e.currentTarget.value;
      this.setState({trans_action:thisvalue});
    }
    handleProceedTransAction = ()=>{
      let trans_action = this.state.trans_action;
      if(trans_action==='delete'){
        this.setState({is_delete_trans:true});
      }
    }
    handleProcessDeleteTransaction = (id,without_effecting_inv)=>{
      let body = {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          id : id,
          without_effecting_inv : without_effecting_inv,
      }
      
      let api = app_api+'/delete_bulk_transaction';
      axios.post(api, body)
        .then(function (response) { 
          let resp = response.data;
          this.setState({is_deleting_trans:false});
          document.getElementById("trans-list-items-"+id).remove();
      }.bind(this)).catch(function(res){
          
      })
    }
    handleDeleteTransaction = () =>{
      
      let without_effecting_inv = document.getElementById('without-effecting-inv').checked;
      this.handleCloseDelete();
      let qs = document.querySelectorAll('.tr-cb-item');
      let del_trans_id = [];
      for (let index = 0; index < qs.length; index++) {
        const is_checked = qs[index].checked;
        const value = qs[index].value;
        if(is_checked===true){
          this.setState({is_deleting_trans:true});
          let element = document.getElementById("trans-list-items-"+value);
          let msg = 'Deleting...';
          let str = '<td class="row-trash" colspan="7">'+msg+'</td>';
          element.innerHTML = str;
          del_trans_id.push(value);
          this.handleProcessDeleteTransaction(value,without_effecting_inv);
        }
      }
    }
    handleCloseDelete = () =>{
      this.setState({is_delete_trans:false});
    }
    handleChangeProductReportType = (value) =>{
      this.setState({product_report_type:value});
    }
    render() {

      

      let is_show_pending_sr_pr = false;
      let is_show_pending_cr_dr = false;
      let total_transaction_amount = 0;
        let none_assisted_by = '';
        let customers_data = [];
        let customers_info = [];

        let b2b_inv_total = 0;
        let b2b_cgst_total = 0;
        let b2b_sgst_total = 0;
        let b2b_igst_total = 0;
        let b2b_taxable_total = 0;

        let b2c_inv_total = 0;
        let b2c_cgst_total = 0;
        let b2c_sgst_total = 0;
        let b2c_igst_total = 0;
        let b2c_taxable_total = 0;

        let cdnr_inv_total = 0;
        let cdnr_cgst_total = 0;
        let cdnr_sgst_total = 0;
        let cdnr_igst_total = 0;
        let cdnr_taxable_total = 0;

        let hsn_quan_total = 0;
        let hsn_cgst_total = 0;
        let hsn_sgst_total = 0;
        let hsn_igst_total = 0;
        let hsn_taxable_total = 0;

        let tcs_gross_total = 0;
        let tcs_total_disc = 0;
        let tcs_total_net_amt = 0;
        let tcs_total_tax = 0;
        let tcs_total_taxable_amt = 0;
        let tcs_total = 0;
        let tcs_total_inv_value = 0;
        let customer_pending_return_total = 0;
        let customer_pending_note_total = 0;
        let customer_pending_invoice_total = 0;
        let customer_pending_payment_total = 0;


        let paybl_rcvbl_total = 0;
        let paybl_rcvbl_adj = 0;
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-6">
                        <Breadcrumbs link="/reports" title={this.state.title} sub_title={this.state.sub_title}/>
                    </div>
                    <div className="col-md-6">
                      <div className="pull-right">
                          <Notification /> 
                      </div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                
                  <div className="nav-container">
                    <ul className="nav nav-pills">
                      
                      <li className={this.state.active_tab==='reports'?'active':''}><a href="/reports">Ledger</a></li>
                      <li className={this.state.active_tab==='sales_report'?'active':''}><a href="/reports/daily-sales-report">Reports</a></li>
                      <li className={(this.state.active_tab==='transactions' || this.state.active_tab==='transactions-book')?'active':''}><a href="/reports/transactions">Transactions</a></li>
                      <li className={this.state.active_tab==='inventories'?'active':''}><a href="/reports/inventories">Inventories</a></li>
                      <li className={this.state.active_tab==='stock-ledger'?'active':''}><a href="/reports/stock-ledger">Stock Ledger</a></li>
                      <li className={this.state.active_tab==='expenses'?'active':''}><a href="/reports/expenses">Expenses</a></li>
                      <li className={this.state.active_tab==='profit_loss'?'active':''}><a href="/reports/profit-loss">Profit Loss</a></li>
                      <li className={this.state.active_tab==='tax'?'active':''}><a href="/reports/tax">Taxes</a></li>
                      {(this.state.enable_tcs===true) &&
                        <li className={(this.state.active_tab==='tcs_tax' || this.state.active_tab==='tcs_customer')?'active':''}><a href="/reports/tcs-tax">TCS Report</a></li>
                      }
                      <li className={this.state.active_tab==='customer-pending'?'active':''}><a href="/reports/pending-invoice">Pending Invoices</a></li>
                      <li className={this.state.active_tab==='sales-man'?'active':''}><a href="/reports/sales-man">Salesman Report</a></li>
                      <li className={this.state.active_tab==='product-report'?'active':''}><a href="/reports/product-report">Product Report</a></li>
                      {(this.state.enable_audit_trial===true) &&
                        <li className={this.state.active_tab==='audit-trial'?'active':''}><a href="/reports/audit-trial">Audit Trial</a></li>
                      }
                      <li className={(this.state.active_tab==='accounting-report' || this.state.active_tab==='trial-balance' || this.state.active_tab==='trading-account')?'active':''}><a href="/reports/accounting-report">Accounting Report</a></li>
                      <li className={(this.state.active_tab==='retained-customer')?'active':''}><a href="/reports/retained-customer">Retained Customers</a></li>
                     
                    </ul>
                
                  </div>
                  <div className="content-area">
                     <div className="content-filter">
                    {(this.state.active_tab==='inventories') &&
                      <>
                      <div className="row" style={{marginBottom:'30px', borderBottom:'1px solid #d7d7d7',paddingBottom:'30px'}}>
                          <div className="col-md-4">
                              <label className="lbl">SHOW INVENTORY</label>
                              <ContactsDropdown show="dropdown" label="Type to select contact" input_id="contact_box" contact_info={[]} index="inventory-reports"/>
                          </div>
                          <div className="col-md-8">
                              <button type="button" id="show-inventory-report" className="btn btn-success mrtop-30" onClick={this.handleShowInventoryReport}>SHOW</button>
                              <button type="button" id="show-inventory-report-btn-spinner" className="hide btn btn-success mrtop-30"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </div>
                      </div>
                      <div className="row" style={{marginBottom:'10px'}}>
                        <div className="col-md-9">
                       {/*  <p>
                          Total Availability : <b>{this.currency_format(this.state.total_inv_available)}</b> |
                          Total Purchase Cost : <b>₹{this.currency_format(this.state.total_inv_pp)}</b> |
                          Total Tax : <b>₹{this.currency_format(this.state.total_purchase_tax)}</b> |
                          Total Purchase Value : <b>₹{this.currency_format(this.state.total_purchase_value)}</b> |
                          Total Selling Value : <b>₹{this.currency_format(this.state.total_inv_sp)}</b>
                        </p> */}
                        </div>
                        <div className="col-md-3">
                          <div>
                            <button type="button" className="btn btn-success pull-right" onClick={this.handleDownloadInventoryXLS} id="down-inv-xls">Download as XLS</button>
                            <button type="button" id="down-inv-xls-btn-spinner" className="hide btn btn-success  pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                            
                            <button type="button" className="btn btn-success pull-right" style={{marginRight:'5px'}} onClick={this.handleDownloadInventoryXLS} id="print-inventory">Print</button>

                            <button type="button" className="hide" id="print-inventory-hidden" onClick={this.handleInventoryPrint}>Print Hidden</button>

                            <button type="button" id="print-inventory-btn-spinner" className="hide btn btn-success  pull-right" style={{marginRight:'5px'}}><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </div>
                          <div  style={{display:'none'}}>
                            <ReactHTMLTableToExcel
                              id="donwload-inventory-xls-button"
                              className="btn btn-success pull-right"
                              table="inventory-report-table"
                              filename="inventory_report"
                              sheet="inventory_report"
                              buttonText="Download as XLS"/>
                          </div>
                        </div>
                      </div>
                      <div id="report-print" className="hide">
                        <table className="table" id="inventory-report-table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Product Name</th>
                            <th scope="col">Purchases Date</th>
                            <th scope="col">Last Sold On</th>
                            <th scope="col">Category</th>
                            <th scope="col" className="text-center">Quantity</th>
                            <th scope="col" className="text-center">Pr Price</th>
                            <th scope="col" className="text-center">Pr Total</th>
                            <th scope="col" className="text-center">S Price</th>
                            <th scope="col" className="text-center">S Total</th>
                            <th scope="col" className="text-center" colSpan="2">Margin</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.inventory_xls_report && this.state.inventory_xls_report.length > 0) ?this.state.inventory_xls_report.map( (value, index) => {
                          
                           return (
                            <tr key={ index } className={"contact-row "+value.dead_stock}>
                              <td>
                                <p style={{margin:'0px'}}>{value.item_name}</p>
                                <i style={{fontSize:'14px',color:'gray'}}>{value.item_desc}</i>
                              </td>
                              <td className="text-center">
                                <p style={{margin:'0px'}}>{value.created_on}</p>
                                <i style={{fontSize:'14px',color:'gray'}}>{value.age}</i>
                              </td>
                              <td className="text-center">
                                <p style={{margin:'0px'}}>{value.last_sold_on}</p>
                                <i style={{fontSize:'14px',color:'gray'}}>{value.last_sold_on_age}</i>
                              </td>
                              <td>{value.category}</td>
                              <td className="text-center">{value.availability}</td>  
                              <td className="text-center"><span>₹</span>{this.currency_format(value.purchase_price)}</td>
                              <td className="text-center"><span>₹</span>{this.currency_format(value.total_pp)}</td>
                              <td className="text-center"><span>₹</span>{this.currency_format(value.selling_price)}</td>
                              <td className="text-center"><span>₹</span>{this.currency_format(value.total_sp)}</td>
                              <td className="text-right">
                                {value.margin_percent}
                              </td>
                              <td className="text-left">
                                <b>₹{this.currency_format(value.margin)}</b>
                              </td>
                            </tr>
                          )
                         }) : <tr><td colSpan={11} className="text-center">No Record found</td></tr> }
                        </tbody>
                        <tfoot>
                            <tr>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                            
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                            
                            </tr>
                        </tfoot>
                      </table>
                      </div>
                      <div className="table-responsive">
                        <div>
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Product Name</th>
                            <th scope="col">Purchased On</th>
                            <th scope="col">Last Sold On</th>
                            <th scope="col">Category</th>
                            <th scope="col" className="text-right">Quantity</th>
                            <th scope="col" className="text-right">Pr Price</th>
                            <th scope="col" className="text-right">Pr Total</th>
                            <th scope="col" className="text-right">S Price</th>
                            <th scope="col" className="text-right">S Total</th>
                            <th scope="col" className="text-center">Margin</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.inventory_list && this.state.inventory_list.length > 0) ?this.state.inventory_list.map( (value, index) => {
                          
                           return (
                            <tr key={ index } className={"contact-row "+value.dead_stock}>
                              <td>
                                <p style={{margin:'0px'}}>
                                <a className="no-print" href={"/products/view/"+value.inventory_id} target="_blank">{value.item_name}</a></p>
                                <p className="inv-rep-desc">{value.item_desc}</p>
                              </td>
                              <td>
                                <p style={{margin:'0px'}}>{value.created_on}</p>
                                <i style={{fontSize:'14px !important',color:'gray !important'}}>{value.age}</i>
                              </td>
                              <td>
                                <p style={{margin:'0px'}}>{value.last_sold_on}</p>
                                <i style={{fontSize:'14px !important',color:'gray !important'}}>{value.last_sold_on_age}</i>
                              </td>
                              <td id={value.category_id} data-id={value.inventory_id} data-value={value.category} style={{cursor:'pointer'}}>
                                <a className="no-print" href={"/reports/inventories/category/"+value.category_id}>{value.category}</a>
                                <span className="print-block">{value.category}</span>
                              </td>
                              <td className="text-right">{value.availability}</td>  
                              <td className="text-right"><span>₹</span>{this.currency_format(value.purchase_price)}</td>
                              <td className="text-right"><span>₹</span>{this.currency_format(value.total_pp)}</td>
                              <td className="text-right"><span>₹</span>{this.currency_format(value.selling_price)}</td>
                              <td className="text-right"><span>₹</span>{this.currency_format(value.total_sp)}</td>
                              <td className="text-center"> 
                                {value.margin_percent} 
                                <b>₹{this.currency_format(value.margin)}</b>
                              </td>
                            </tr>
                          )
                         }) : <tr><td colSpan={10} className="text-center">{this.state.loading_process}</td></tr> }
                        </tbody>
                        <tfoot>
                            <tr>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                            
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                            
                            
                            </tr>
                        </tfoot>
                      </table>
                      </div>
                      {(this.state.total_page>1) &&
                       <div className="page-nav">
                        <div className="navd">
                          <a href={this.state.prev_link}>
                            <div className="navd">
                              <FontAwesomeIcon className="cont" icon={faCaretLeft}/>
                            </div>
                          </a>
                        </div>
                        <div className="navd center"><p className="msg">{this.state.current_page} of {this.state.total_page}</p></div>
                        <div className="navd">
                          <a href={this.state.next_link}>
                            <div className="navd">
                              <FontAwesomeIcon  className="cont" icon={faCaretRight}/>
                            </div>
                          </a>
                        </div>
                      </div>
                    }
                     </div>
                      </>
                    }
                    {(this.state.active_tab==='stock-ledger') &&
                      <>
                      <div className="row" style={{marginBottom:'30px', borderBottom:'1px solid #d7d7d7',paddingBottom:'30px'}}>
                          <div className="col-md-4">
                              <label className="lbl">SHOW INVENTORY</label>
                              <Autocomplete
                              options={[]}
                              data_target="billing"
                              title="Start typing product name..."  
                              id={"iitem-name"}
                              className="auto-comp-opt"
                              data_id={0}
                              active_tab={0}
                              input_id={"iitem-box"}
                              item_info={[]}
                              target="iitem-box"
                              
                            />
                          </div>
                        
                          <div className="col-md-2">
                            <label className="lbl">FROM</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text no-border-right" onClick={this.handleCalendarClick} id="cal-from">
                                  <FontAwesomeIcon icon={faCalendarAlt} />
                                </span>
                                <DatePicker className="form-control  no-border-left" selected={this.state.FromDate} onChange={this.FromDate} dateFormat="dd/MM/yyyy" id="cal-from-date"/>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <label className="lbl">TO</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text  no-border-right" onClick={this.handleCalendarClick}  id="cal-to"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                <DatePicker className="form-control  no-border-left" selected={this.state.ToDate} onChange={this.ToDate} dateFormat="dd/MM/yyyy" id="cal-to-date"/>
                              </div>
                            </div>
                          </div>
                          
                          <div className="col-md-4">
                            
                                <button type="button" id="show-inventory-report" className="btn btn-success mrtop-30" onClick={this.handleShowStockLedgerReport}>SHOW</button>
                                <button type="button" id="show-inventory-report-btn-spinner" className="hide btn btn-success mrtop-30"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                              
                          </div>
                      </div>
                      <div className="row" style={{marginBottom:'10px'}}>
                        <div className="col-md-9">
                      
                        </div>
                        <div className="col-md-3">
                        {(this.state.inventory_list.length>0) &&
                          <>
                            <div>
                            
                              <ReactHTMLTableToExcel
                                id="donwload-stock_ledger-xls-button"
                                className="btn btn-success pull-right"
                                table="stock_ledger_report_table"
                                filename="stock_ledger_report"
                                sheet="stock_ledger_report"
                                buttonText="Download as XLS"/>
                            
                              
                              <button type="button" className="btn btn-success pull-right" style={{marginRight:'5px'}} onClick={this.handleInventoryPrint} id="print-inventory">Print</button>

                              <button type="button" id="print-inventory-btn-spinner" className="hide btn btn-success  pull-right" style={{marginRight:'5px'}}><FontAwesomeIcon icon={faSpinner} pulse/></button>
                            </div>
                            
                          </>
                        }
                        </div>
                      </div>
                      <div id="report-print" style={{overflowY:'auto'}}>
                        
                        <table className="table" id="stock_ledger_report_table">
                        <thead className="thead-light">
                          <tr>
                            <td colSpan={8} style={{textAlign:'center'}}><h1>{this.state.selected_stock_item}</h1></td>
                          </tr>
                          <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Type</th>
                            <th scope="col">Bill No</th>
                            <th scope="col">Name</th>
                            <th scope="col" style={{textAlign:'right'}}>Quantity</th>
                            <th scope="col" style={{textAlign:'right'}}>Purchase Rate</th>
                            <th scope="col" style={{textAlign:'right'}}>Sale Rate</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.inventory_list && this.state.inventory_list.length > 0) ?this.state.inventory_list.map( (value, index) => {
                          
                           return (
                            <tr key={ index } className={"contact-row"}>
                              <td>{value.created_on}</td>
                              <td>{value.type}</td>
                              <td>
                              {(value.invoice_url!=='' && value.type!=='Quotation') &&
                                <DrawerLink target="billing" url={value.invoice_url} name={value.invoice} schema={value.schema}/>
                              }
                              {(value.invoice_url!=='' && value.type=='Quotation') &&
                                <a href={value.invoice_url} target="_blank" className="drawer-link">{value.invoice}</a>
                              }
                              </td>
                              <td>
                                
                                <Link link={'/view-contact/'+value.contact_id} target="_blank" name={value.party_name} />
                              </td>
                              <td style={{textAlign:'right'}}><span style={{color:(value.quantity<=0)?'red':'#50df50'}}>{value.quantity}</span></td>
                              <td style={{textAlign:'right'}}>{(value.price_info.purchase_price!==undefined && value.price_info.purchase_price!=='')?utils.currency_format(value.price_info.purchase_price):''}</td>
                              <td style={{textAlign:'right'}}>{(value.price_info.selling_price!==undefined && value.price_info.selling_price!=='')?utils.currency_format(value.price_info.selling_price):''}</td>
                              <td>{value.operation}</td>
                            </tr>
                          )
                         }) : <tr><td colSpan={8} className="text-center">{this.state.loading_process}</td></tr> }
                        </tbody>
                        <tfoot>
                            <tr>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th style={{textAlign:'right'}}>
                                <span style={{color:(this.state.total_stock_ledger_quantity<=0)?'red':'#50df50'}}>{this.state.total_stock_ledger_quantity}</span>
                              </th>
                              <th></th>
                              <th></th>
                              <th></th>
                            
                            </tr>
                        </tfoot>
                      </table>
                      </div>
                      </>
                    }
                     {(this.state.active_tab==='accounting-report' || this.state.active_tab==='trial-balance' || this.state.active_tab==='trading-account') &&
                      <div className="nav-container">
                        <ul className="nav nav-pills">
                        <li className={this.state.active_tab==='trial-balance'?'active':''}><a href="/reports/trial-balance">Trial Balance</a></li>
                        <li className={this.state.active_tab==='trading-account'?'active':''}><a href="/reports/trading-account">Trading Account</a></li>
                        </ul>
                      </div>
                     }
                    {(this.state.active_tab==='trial-balance') &&
                      <>
                      <div className="row" style={{marginBottom:'30px', borderBottom:'1px solid #d7d7d7',paddingBottom:'30px',marginTop:'20px'}}>
                      <div className="col-md-2">
                            <label htmlFor="trial-balance-select-filter">SELECT ACTION</label>
                            <select className="form-control" id="trial-balance-select-filter" onChange={this.handleChangeTrialBalanceFilter}>
                              <option value='thisyear'>This Year</option>
                              <option value='lastyear'>Last Year</option>
                              <option value='custom'>Custom</option>
                            </select>
                          </div>
                          {(this.state.trial_balance_filter_type==='custom') &&
                          <>
                            
                            <div className="col-md-2">
                              <label className="lbl">FROM</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text no-border-right" onClick={this.handleCalendarClick} id="cal-from">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                  </span>
                                  <DatePicker className="form-control  no-border-left" selected={this.state.FromDate} onChange={this.FromDate} dateFormat="dd/MM/yyyy" id="cal-from-date"/>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <label className="lbl">TO</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text  no-border-right" onClick={this.handleCalendarClick}  id="cal-to"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                  <DatePicker className="form-control  no-border-left" selected={this.state.ToDate} onChange={this.ToDate} dateFormat="dd/MM/yyyy" id="cal-to-date"/>
                                </div>
                              </div>
                            </div>
                          </>
                          }
                          <div className="col-md-2">
                                <div className="hide">
                                <button type="button" id="show-inventory-report" className="btn btn-success mrtop-30" onClick={this.handleShowTrialBalance}>GENERATE</button>
                                <button type="button" id="show-inventory-report-btn-spinner" className="btn btn-success mrtop-30 hide"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                </div>
                                {(this.state.is_reload_trial_balance===false && this.state.is_generating_trial_balance===false) &&
                                  <button type="button" className="btn btn-success mrtop-30" onClick={this.handleReloadTrialBalance}>GENERATE</button>
                                }
                                {(this.state.is_reload_trial_balance===true && this.state.is_generating_trial_balance===true) &&
                                  <button type="button" className="btn btn-success mrtop-30"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                }
                          </div>
                      </div>
                      
                        {((this.state.is_reload_trial_balance===true || this.state.is_generating_trial_balance===true) && this.state.progress<=100) &&
                        <div className="row" style={{marginBottom:'30px', borderBottom:'1px solid #d7d7d7',paddingBottom:'30px'}}>
                            <div className="col-md-12">
                                <div className="csv-load-blk" style={{paddingTop:'10px',paddingBottom:'0px'}}>
                                  <div className="parent-bar">
                                    <div className="child-bar" style={{width:this.state.progress+'%'}}></div>
                                  </div>
                                  {(this.state.is_reload_trial_balance===true) &&
                                    <p>Please wait while fetching {this.state.trial_balance_generate_type.replace('_',' ')} ({this.state.progress}%)</p>
                                  }
                                  {(this.state.is_generating_trial_balance===true) &&
                                    <p>All Set! Generating Report ({this.state.progress}%)</p>
                                  }
                                </div>
                            </div>
                        </div>
                        }
                      <div className="row" style={{marginBottom:'10px'}}>
                          <div className="col-md-12">
                            <h4>Generated Trial Balance</h4>
                          </div>
                          <div className="col-md-12">
                            <hr />
                          </div>
                          <div className="col-md-12">
                            <div className="row">
                              {(this.state.generated_trial_balance.length>0) &&
                              <div className="col-md-3">
                              {(this.state.generated_trial_balance.map((value,index)=>{
                                return(
                                  <div className="row mrbtm-10">
                                  <div className="col-md-12">
                                    <div style={{border:'1px solid #d7d7d7',cursor:'pointer'}} onClick={()=>this.handleViewTrialBalance(index)}>
                                      <p style={{textAlign:'center',margin: '0px',background: '#339970',color: '#fff',padding: '10px',fontSize:'14px',position:'relative'}}>
                                        <p style={{transform: 'rotate(-37deg)',position: 'absolute',background: 'red', padding: '0px 5px',left: '-20px',top: '0px'}}>
                                          {(value.filter_name==='thisyear') &&
                                            <>This Year</>
                                          }
                                          {(value.filter_name==='lastyear') &&
                                            <>Last Year</>
                                          }
                                          {(value.filter_name==='custom') &&
                                            <>Custom</>
                                          }
                                        </p>
                                        {value.filter}
                                      </p>
                                      <p style={{textAlign:'center',margin: '0px',padding: '10px',fontSize:'14px'}}>
                                        Generated On <br/>{value.generated_on}
                                      </p>
                                    </div>
                                  </div>
                                  </div>
                                )
                              }))}
                              </div>
                              }
                              {(this.state.is_visible_trial_balance===true) &&
                                <div className="col-md-9">
                                  <div className="row">
                                    <div className="col-md-12">
                                    {(this.state.trial_balance_report.length>0) &&
                                      <>
                                        <div>
                                        
                                          <ReactHTMLTableToExcel
                                            id="donwload-trial_balance-xls-button"
                                            className="btn btn-success pull-right"
                                            table="trial_balance_report_table"
                                            filename="trial_balance_report"
                                            sheet="trial_balance_report"
                                            buttonText="Download as XLS"/>
                                        
                                          
                                          <button type="button" className="btn btn-success pull-right" style={{marginRight:'5px'}} onClick={this.handleInventoryPrint} id="print-inventory">Print</button>

                                          <button type="button" id="print-inventory-btn-spinner" className="hide btn btn-success  pull-right" style={{marginRight:'5px'}}><FontAwesomeIcon icon={faSpinner} pulse/></button>

                                          {(this.state.is_delete_trial_balance===false) &&
                                            <button type="button" className="btn btn-danger pull-right" style={{marginRight:'5px'}} onClick={this.handleDeleteTrialBalance}>Delete</button>
                                          }
                                          {(this.state.is_delete_trial_balance===true) &&
                                            <button type="button" className="btn btn-danger  pull-right" style={{marginRight:'5px'}}><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                          }
                                        </div>
                                        
                                      </>
                                    }
                                    </div>
                                    <div className="col-md-12">
                                      <div id="report-print" style={{overflowY:'auto'}}>
                                        <table className="table" id="trial_balance_report_table">
                                        <thead className="thead-light">
                                        
                                          <tr>
                                            <th scope="col">Account</th>
                                            <th scope="col" colSpan={2} style={{textAlign:'center'}}>Debit</th>
                                            <th scope="col" colSpan={2} style={{textAlign:'center'}}>Credit</th>
                                          </tr>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col" style={{width:'140px'}}>Debit</th>
                                            <th scope="col" style={{width:'140px'}}>Credit</th>
                                            <th scope="col" style={{width:'140px'}}>Debit</th>
                                            <th scope="col" style={{width:'140px'}}>Credit</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {(this.state.trial_balance_report && this.state.trial_balance_report.length > 0) ?this.state.trial_balance_report.map( (value, index) => {
                                          
                                          return (
                                            <>
                                            {(value.debit!==0 || value.credit!==0) &&
                                            <>
                                              <tr key={ index } className={"contact-row"}  style={{fontWeight:'bold'}}>
                                                <td style={{paddingLeft:(value.is_child)?'50px':''}}>
                                                  <p style={{margin:'0px'}}>{value.name}</p>
                                                </td>
                                                <td>
                                                  {(value.debit>0 && value.group_type==='debit') &&
                                                  <>
                                                    {utils.currency_format(value.debit)}
                                                  </>
                                                  }
                                                </td>
                                                <td>
                                                  {(value.credit>0 && value.group_type==='debit') &&
                                                  <>
                                                    {utils.currency_format(value.credit)}
                                                  </>
                                                  }
                                                </td>
                                                <td>
                                                  {(value.debit>0 && value.group_type==='credit') &&
                                                  <>
                                                    {utils.currency_format(value.debit)}
                                                  </>
                                                  }
                                                </td>
                                                <td>
                                                  {(value.credit>0  && value.group_type==='credit') &&
                                                  <>
                                                    {utils.currency_format(value.credit)}
                                                  </>
                                                  }
                                                </td>
                                              </tr>
                                              {(value.group_data) &&
                                                <>
                                                  {(value.group_data.map((gdvalue,gdidx)=>{
                                                    return (
                                                      <>
                                                      {(gdvalue.debit>0 || gdvalue.credit>0) &&
                                                      <tr key={ gdidx } className={"contact-row"}>
                                                        <td style={{paddingLeft:'100px'}}>
                                                          <p style={{margin:'0px'}}>{gdvalue.contact_info.name}</p>
                                                        </td>
                                                        <td>
                                                          {(gdvalue.debit>0 && gdvalue.group_type==='debit') &&
                                                          <>
                                                            {utils.currency_format(gdvalue.debit)}
                                                          </>
                                                          }
                                                        </td>
                                                        <td>
                                                          {(gdvalue.credit>0 && gdvalue.group_type==='debit') &&
                                                          <>
                                                            {utils.currency_format(gdvalue.credit)}
                                                          </>
                                                          }
                                                        </td>
                                                        <td>
                                                          {(gdvalue.debit>0 && gdvalue.group_type==='credit') &&
                                                          <>
                                                            {utils.currency_format(gdvalue.debit)}
                                                          </>
                                                          }
                                                        </td>
                                                        <td>
                                                          {(gdvalue.credit>0 && gdvalue.group_type==='credit') &&
                                                          <>
                                                            {utils.currency_format(gdvalue.credit)}
                                                          </>
                                                          }
                                                        </td>
                                                      </tr>
                                                  }
                                                      </>
                                                    )
                                                  }))}
                                                </>
                                              }
                                              </>
                                            }
                                            </>
                                          )
                                        }) : <tr><td colSpan={5} className="text-center">{this.state.loading_process}</td></tr> }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                              <th colSpan={5}></th>
                                            </tr>
                                            <tr>
                                              <th></th>
                                              <th>{utils.currency_format(this.state.trial_balance_total_debit_debit)} Dr</th>
                                              <th>{utils.currency_format(this.state.trial_balance_total_debit_credit)} Cr</th>
                                              <th>{utils.currency_format(this.state.trial_balance_total_credit_debit)} Dr</th>
                                              <th>{utils.currency_format(this.state.trial_balance_total_credit_credit)} Cr</th>
                                            </tr>
                                            <tr>
                                              <th style={{textAlign:'right'}}>Total Amount</th>
                                              <th colSpan={2} style={{textAlign:'center'}}>{utils.currency_format(this.state.trial_balance_total_debit)}{this.state.debit_sym}</th>
                                              
                                              <th colSpan={2} style={{textAlign:'center'}}>{utils.currency_format(this.state.trial_balance_total_credit)}{this.state.credit_sym}</th>
                                            </tr>
                                        </tfoot>
                                      </table>
                                      </div>
                                    </div>
                                    
                                  </div>
                                </div>
                              }
                              {(this.state.generated_trial_balance.length===0) &&
                              <div className="col-md-3"><p>No Trial Balance Generate yet!</p></div>
                              }
                              </div>
                          </div>
                      </div>
                      
                      </>
                    }
                    {(this.state.active_tab==='trading-account') &&
                          <>
                          {(this.state.generated_trading_accounts.length==0 && this.state.generated_trial_balance.length>0) &&
                              <div className="modal" id="previous-list-items" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title">Trial Balance List</h5>
                                  </div>
                                  <div className="modal-body">
                                    <div className="row">
                                      <div className="col-md-12">
                                          <select className="form-control" onChange={this.handleSelectTrialBalance}>
                                          <option value={''}>Select Trial Balance</option>
                                          {(this.state.generated_trial_balance.map((value,index)=>{
                                            return(
                                            <option value={value.trial_balance_id}>
                                            {(value.filter_name==='thisyear')?'This Year':(value.filter_name==='lastyear')?'Last Year':'Custom'}/{value.filter}
                                            </option>
                                            )
                                          }))}
                                          </select>
                                      </div>
                                    </div>
                                  </div>
                                  {(this.state.selected_trial_balance!=='') &&
                                  <div className="modal-footer">
                                    {/* <button type="button" className="btn btn-secondary" id="closeTradkingAccount" onClick={this.closeTradkingAccount}>Close</button> */}
                                    {(this.state.is_generating_trading_account===false) &&
                                      <button type="button" id="generateTradingAccount" className="btn btn-success pull-right" onClick={this.handleGenerateTradingAccount}>Generate Trading Account</button>
                                    }
                                    {(this.state.is_generating_trading_account===true) &&
                                      <button type="button" className="btn btn-success pull-right" style={{marginRight:'5px'}}><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                    }
                                  </div>
                                  }
                                </div>
                              </div>
                            </div> 
                          }
                          <div className="row" style={{marginBottom:'10px'}}>
                            {(this.state.generated_trading_accounts.length>0) &&
                                <>
                                  <div className="col-md-12" style={{marginTop:'20px'}}>
                                    <h4>Generated Trading Account</h4>
                                  </div>
                                  <div className="col-md-12">
                                    <hr />
                                  </div>
                                </>
                            }
                              <div className="col-md-12">
                                <div className="row">
                                  {(this.state.generated_trading_accounts.length>0) &&
                                    <div className="col-md-3">
                                    {(this.state.generated_trading_accounts.map((value,index)=>{
                                      return(
                                        <div className="row mrbtm-10">
                                        <div className="col-md-12">
                                          <div style={{border:'1px solid #d7d7d7',cursor:'pointer'}} onClick={()=>this.handleViewTradingAccount(index)}>
                                            <p style={{textAlign:'center',margin: '0px',background: '#339970',color: '#fff',padding: '10px',fontSize:'14px',position:'relative'}}>
                                              <p style={{transform: 'rotate(-37deg)',position: 'absolute',background: 'red', padding: '0px 5px',left: '-20px',top: '0px'}}>
                                                {(value.filter_name==='thisyear') &&
                                                  <>This Year</>
                                                }
                                                {(value.filter_name==='lastyear') &&
                                                  <>Last Year</>
                                                }
                                                {(value.filter_name==='custom') &&
                                                  <>Custom</>
                                                }
                                              </p>
                                              {value.filter}
                                            </p>
                                            <p style={{textAlign:'center',margin: '0px',padding: '10px',fontSize:'14px'}}>
                                              Generated On <br/>{value.generated_on}
                                            </p>
                                          </div>
                                        </div>
                                        </div>
                                      )
                                    }))}
                                    </div>
                                  }
                                  {(this.state.is_visible_trading_account===true) &&
                                    <div className="col-md-9">
                                      <div className="row">
                                        <div className="col-md-12">
                                        {(this.state.view_trading_accounts && this.state.view_trading_accounts.data && this.state.view_trading_accounts.data.length>0) &&
                                          <>
                                            <div>
                                            
                                              <ReactHTMLTableToExcel
                                                id="donwload-trial_balance-xls-button"
                                                className="btn btn-success pull-right"
                                                table="view_trading_accounts_table"
                                                filename="trading accounts"
                                                sheet="trading account"
                                                buttonText="Download as XLS"/>
                                            
                                              
                                              <button type="button" className="btn btn-success pull-right" style={{marginRight:'5px'}} onClick={this.handleInventoryPrint} id="print-inventory">Print</button>

                                              <button type="button" id="print-inventory-btn-spinner" className="hide btn btn-success  pull-right" style={{marginRight:'5px'}}><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                              
                                             
                                            </div>
                                            
                                          </>
                                        }
                                        </div>
                                        <div className="col-md-12 mrtop-20">
                                          <div id="report-print" style={{overflowY:'auto'}}>
                                            <table className="table trading-acc-tbl" style={{border:'1px solid #d7d7d7'}} id="view_trading_accounts_table">
                                                <thead>
                                                <tr>
                                                    <th>Particulars</th>
                                                    <th>Amount</th>
                                                    <th>Particulars</th>
                                                    <th>Amount</th>
                                                </tr>
                                                </thead>
                                              <tbody>
                                           
                                                  <tr>
                                                    <td>
                                                      {this.state.view_trading_accounts.data[0].key}
                                                    </td>
                                                    <td>
                                                      <input type="text" defaultValue={this.state.view_trading_accounts.data[0].value} className="form-control" onChange={(event)=>this.handleChangeTradingAccountValue(0,event.target.value)}/>
                                                    </td>
                                                    <td>
                                                      
                                                    </td>
                                                    <td>
                                                      
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      {this.state.view_trading_accounts.data[2].key}
                                                    </td>
                                                    <td>
                                                      {utils.currency_format(this.state.view_trading_accounts.data[2].value)}
                                                    </td>
                                                    <td>
                                                      {this.state.view_trading_accounts.data[1].key}
                                                    </td>
                                                    <td>
                                                      {utils.currency_format(this.state.view_trading_accounts.data[1].value)}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      {this.state.view_trading_accounts.data[3].key}
                                                    </td>
                                                    <td>
                                                      {utils.currency_format(this.state.view_trading_accounts.data[3].value)}
                                                    </td>
                                                   
                                                    <td>
                                                      
                                                    </td>
                                                    <td>
                                                     
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                     
                                                    </td>
                                                    <td>
                                                      
                                                    </td>
                                                    <td>
                                                      {this.state.view_trading_accounts.data[4].key}
                                                    </td>
                                                    <td>
                                                    <input type="text" defaultValue={this.state.view_trading_accounts.data[4].value} className="form-control" onChange={(event)=>this.handleChangeTradingAccountValue(4,event.target.value)}/>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                    {this.state.view_trading_accounts.data[5].key}
                                                    </td>
                                                    <td>
                                                    <input type="text" defaultValue={this.state.view_trading_accounts.data[5].value} className="form-control" onChange={(event)=>this.handleChangeTradingAccountValue(5,event.target.value)}/>
                                                    </td>
                                                    <td>
                                                      {this.state.view_trading_accounts.data[6].key}
                                                    </td>
                                                    <td>
                                                    <input type="text" defaultValue={this.state.view_trading_accounts.data[6].value} className="form-control" onChange={(event)=>this.handleChangeTradingAccountValue(6,event.target.value)}/>
                                                    </td>
                                                  </tr>
                                            </tbody>
                                            <tfoot>
                                              <tr>
                                                <td></td>
                                                <td>{this.state.trading_account_left_total}</td>
                                                <td></td>
                                                <td>{this.state.trading_account_right_total}</td>
                                              </tr>
                                            </tfoot>
                                            </table>
                                          </div>
                                          
                                        </div>
                                        <div className="col-md-12">
                                              {(this.state.is_save_trading_account===false) &&
                                                <button type="button" className="btn btn-success pull-right" style={{marginRight:'5px'}} onClick={this.handleSaveTradingAccount}>Save Trading Account</button>
                                              }
                                              {(this.state.is_save_trading_account===true) &&
                                                <button type="button" className="btn btn-success  pull-right" style={{marginRight:'5px'}}><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                              }
                                        </div>
                                      </div>
                                    </div>
                                  }
                                  {(this.state.generated_trial_balance.length===0) &&
                                  <div className="col-md-3"><p>No Trial Balance Generate yet!</p></div>
                                  }
                              </div>
                              </div>
                          </div>
                          </>
                        }
                    {(this.state.active_tab==='expenses') &&
                        <div className="row">
                          <div className="col-md-2">
                              <label className="lbl">TYPE / ACCOUNT</label>
                              <ExpenseTypes show="dropdown" id="expense-type" target="report" />
                          </div>
                          <div className="col-md-3">
                              <label className="lbl">PAYEE</label>
                              <ContactsDropdown show="dropdown" label="Type to select contact" input_id="contact_box" contact_info={[]} index="reports"/>
                          </div>

                          <div className="col-md-2">
                            <label className="lbl">FOR</label>
                            <select className="form-control" onChange={this.handleReportFilter} id="expense-for">
                              <option value="today">Today</option>
                              <option value="yesterday">Yesterday</option>
                              <option value="this_week">This Week</option>
                              <option value="last_week">Last Week</option>
                              <option value="last_30_days">Last 30 Days</option>
                              <option value="this_month">This Month</option>
                              <option value="last_month">Last Month</option>
                              <option value="this_quarter">This Quarter</option>
                              <option value="last_quarter">Last Quarter</option>
                              <option value="this_year">This Year</option>
                              <option value="last_year">Last Year</option>
                              <option value="other">Custom</option>
                            </select>
                          </div>
                          {(this.state.filter_option==='other') &&
                          <>
                            <div className="col-md-2">
                              <label className="lbl">FROM</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text no-border-right" onClick={this.handleCalendarClick} id="cal-from">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                  </span>
                                  <DatePicker className="form-control  no-border-left" selected={this.state.FromDate} onChange={this.FromDate} dateFormat="dd/MM/yyyy" id="cal-from-date"/>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <label className="lbl">TO</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text  no-border-right" onClick={this.handleCalendarClick}  id="cal-to"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                  <DatePicker className="form-control  no-border-left" selected={this.state.ToDate} onChange={this.ToDate} dateFormat="dd/MM/yyyy" id="cal-to-date"/>
                                </div>
                              </div>
                            </div>
                            </>
                          }
                          {(this.state.filter_option!=='other') &&
                          <div className="col-md-4"></div>
                          }
                          <div className="col-md-1" style={{marginTop:'29px'}}>
                            <button type="button" className="btn btn-success" onClick={this.handleShowReport} id="view-report" data-target={this.state.active_tab}>VIEW</button>
                            <button type="button" id="view-report-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </div>
                        </div>
                        }
                      {(this.state.active_tab==='product-report') &&
                        <>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="nav-container">
                              <ul className="nav nav-pills">
                                <li className={this.state.active_tab==='product-report'?'active':''}>
                                  <a href="/reports/product-report/top-selling-product">Top Selling Product</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          
                          <div className="col-md-2">
                            <label className="lbl">TYPE</label>
                            <select className="form-control" onChange={(e)=>this.handleChangeProductReportType(e.target.value)} id="product-report-type">
                              <option value="quantity">Quantity wise</option>
                              <option value="amount">Revenue wise</option>
                              <option value="profit">Profit wise</option>
                              <option value="supplier">Supplier wise</option>
                              <option value="category">Category wise</option>
                              {/* <option value="custom-field">Inventory Custom Field</option> */}
                            </select>
                          </div>
                          {(this.state.product_report_type==='supplier') &&
                            <div className="col-md-2">
                              <label className="lbl">SUPPLIER</label>
                              <ContactsDropdown show="dropdown" label="Type to select contact" input_id="contact_box" contact_info={[]} contact_type={2} target="pending-invoice" is_enable_tag={true}/>
                            </div>
                          }
                          {(this.state.product_report_type==='category') &&
                            <div className="col-md-2">
                              <label className="lbl">CATEGORY</label>
                              <ProductCategory show="dropdown" target="product-category-dd" id="product-category-dd"/>
                            </div>
                          }
                          <div className="col-md-2">
                            <label className="lbl">FOR</label>
                            <select className="form-control" onChange={this.handleReportFilter} id="top-selling-price-for">
                              <option value="today">Today</option>
                              <option value="yesterday">Yesterday</option>
                              <option value="this_week">This Week</option>
                              <option value="last_week">Last Week</option>
                              <option value="last_30_days">Last 30 Days</option>
                              <option value="this_month">This Month</option>
                              <option value="last_month">Last Month</option>
                              <option value="this_quarter">This Quarter</option>
                              <option value="last_quarter">Last Quarter</option>
                              <option value="this_year">This Year</option>
                              <option value="last_year">Last Year</option>
                              <option value="other">Custom</option>
                            </select>
                          </div>
                          {(this.state.filter_option==='other') &&
                          <>
                            <div className="col-md-2">
                              <label className="lbl">FROM</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text no-border-right" onClick={this.handleCalendarClick} id="cal-from">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                  </span>
                                  <DatePicker className="form-control  no-border-left" selected={this.state.FromDate} onChange={this.FromDate} dateFormat="dd/MM/yyyy" id="cal-from-date"/>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <label className="lbl">TO</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text  no-border-right" onClick={this.handleCalendarClick}  id="cal-to"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                  <DatePicker className="form-control  no-border-left" selected={this.state.ToDate} onChange={this.ToDate} dateFormat="dd/MM/yyyy" id="cal-to-date"/>
                                </div>
                              </div>
                            </div>
                            </>
                          }
                          
                          <div className="col-md-1" style={{marginTop:'29px'}}>
                            <button type="button" className="btn btn-success" onClick={this.handleShowTopSellingProduct} id="view-report" data-target={this.state.active_tab}>VIEW</button>
                            <button type="button" id="view-report-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </div>
                          
                        </div>
                        </>
                        }
                      {(this.state.active_tab==='retained-customer') &&
                        <div className="row">
                          {/* <div className="col-md-2">
                          <label className="lbl">PERIOD 1</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text no-border-right" onClick={this.handleCalendarClick} id="cal-from">
                                  <FontAwesomeIcon icon={faCalendarAlt} />
                                </span>
                                <DatePicker className="form-control  no-border-left" selected={this.state.FromDate} onChange={this.FromDate} dateFormat="MMMM yyyy" id="cal-from-date"/>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2">
                          <label className="lbl">PERIOD 2</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text no-border-right" onClick={this.handleCalendarClick} id="cal-to">
                                  <FontAwesomeIcon icon={faCalendarAlt} />
                                </span>
                                <DatePicker className="form-control  no-border-left" selected={this.state.ToDate} onChange={this.ToDate} dateFormat="MMMM yyyy" id="cal-to-date"/>
                              </div>
                            </div>
                          </div> */}
                         {/*  <div className="col-md-2">
                              <select className="form-control" onChange={(e)=>this.handleChangeMonth(e.target.value)}>
                                  <option value='Apr'>Apr</option>
                                  <option value='May'>May</option>
                                  <option value='Jun'>Jun</option>
                                  <option value='Jul'>Jul</option>
                                  <option value='Aug'>Aug</option>
                                  <option value='Sep'>Sep</option>
                                  <option value='Oct'>Oct</option>
                                  <option value='Nov'>Nov</option>
                                  <option value='Dec'>Dec</option>
                                  <option value='Jan'>Jan</option>
                                  <option value='Feb'>Feb</option>
                                  <option value='Mar'>Mar</option>
                              </select>
                          </div> */}
                          <div className="col-md-12">
                           {/*  <button type="button" className="btn btn-success" onClick={this.handleShowRetainedCustomers} id="view-report" data-target={this.state.active_tab}>GENERATE REPORT</button>
                            <button type="button" id="view-report-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button> */}
                          </div>
                        </div>
                        }
                    {(this.state.active_tab==='sales-man') &&
                        <div className="row">
                          <div className="col-md-3">
                              <label className="lbl">STAFF</label>
                              <select className="form-control" id="staff-dropdown" >
                                  <option value="">All</option>
                                  {(this.state.staff_options.length > 0) ?this.state.staff_options.map( (value, index) => {
                                  return (
                                      <option value={value.id} key={index}>{value.name}</option>
                                    )
                                  }) : ''}
                              </select>
                          </div>

                          <div className="col-md-4">
                           
                          </div>
                        
                          <div className="col-md-2">
                            <label className="lbl">FROM</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text no-border-right" onClick={this.handleCalendarClick} id="cal-from">
                                  <FontAwesomeIcon icon={faCalendarAlt} />
                                </span>
                                <DatePicker className="form-control  no-border-left" selected={this.state.FromDate} onChange={this.FromDate} dateFormat="dd/MM/yyyy" id="cal-from-date"/>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <label className="lbl">TO</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text  no-border-right" onClick={this.handleCalendarClick}  id="cal-to"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                <DatePicker className="form-control  no-border-left" selected={this.state.ToDate} onChange={this.ToDate} dateFormat="dd/MM/yyyy" id="cal-to-date"/>
                              </div>
                            </div>
                          </div>
                            
                       
                          <div className="col-md-1" style={{marginTop:'29px'}}>
                            <button type="button" className="btn btn-success" onClick={this.loadSalesmanReport} id="view-report" data-target={this.state.active_tab}>VIEW</button>
                            <button type="button" id="view-report-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </div>
                        </div>
                        }

                        {(this.state.active_tab==='tax') &&
                        <div className="row">
                            <div className="col-md-3">
                              <label className="lbl">TAX SECTION</label>
                              <select className="form-control" id="tax-section" onChange={this.handleChangeTaxSection}>
                                <option value="b2b">B2B, SEZ, DE(4A, 4B, 6B, 6C)</option>
                                {/* <option value="b2cl">B2C Large(5)</option> */}
                                <option value="b2cs">B2C Small(7)</option>
                                <option value="b2b_b2cs">B2B & B2C</option>
                                <option value="hsn">HSN</option>
                                <option value="cdnr">CDNR (CREDIT DEBIT NOTES REGISTERED)</option>
                                <option value="all">ALL THE ABOVE</option>
                              </select>
                            </div>
                            <div className="col-md-2">
                              {(this.state.tax_section!='cdnr' && this.state.tax_section!='all') &&
                                <>
                                  <label className="lbl">TAX TYPE</label>
                                  <select className="form-control" id="tax-type">
                                    <option value="sale">Sales</option>
                                    <option value="purchase">Purchase</option>
                                  </select>
                                </>
                              }
                            </div>
                            <div className="col-md-2">
                              <label className="lbl">FROM</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text no-border-right" onClick={this.handleCalendarClick} id="cal-from">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                  </span>
                                  <DatePicker className="form-control  no-border-left" selected={this.state.FromDate} onChange={this.FromDate} dateFormat="dd/MM/yyyy" id="cal-from-date"/>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <label className="lbl">TO</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text  no-border-right" onClick={this.handleCalendarClick}  id="cal-to"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                  <DatePicker className="form-control  no-border-left" selected={this.state.ToDate} onChange={this.ToDate} dateFormat="dd/MM/yyyy" id="cal-to-date"/>
                                </div>
                              </div>
                            </div>
                          <div className="col-md-2"></div>                 
                          <div className="col-md-1" style={{marginTop:'29px'}}>
                            <button type="button" className="btn btn-success" onClick={this.handleGenerateTaxReport} id="view-report" data-target={this.state.active_tab}>VIEW</button>
                            <button type="button" id="view-report-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </div>
                        </div>
                        }
                        {(this.state.active_tab==='tcs_tax' || this.state.active_tab==='tcs_customer') &&
                        <>
                        <div className="row">
                          <div className="col-md-12">
                          <div className="nav-container">
                            <ul className="nav nav-pills">
                              <li className={this.state.active_tab==='tcs_tax'?'active':''}><a href="/reports/tcs-tax">Bill Report</a></li>
                              <li className={this.state.active_tab==='tcs_customer'?'active':''}><a href="/reports/tcs-customer">Customer Report</a></li>
                              
                            </ul>
                          </div>
                          {(this.state.active_tab==='tcs_customer' && this.state.customer_tcs_report.length>0) &&
                              
                              <div className="content-area">
                                <div className="main-content">
                                  <button className="btn btn-success pull-right mrtop-5 mrbtm-5" onClick={this.handleGenerateCustomerTCSReport} data-type="all">Reset List</button>
                                  <div className="table-responsive" id="report-print">
                                  <table className="table" id="tax-report-xls">
                          
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">PARTY NAME</th>
                                  <th scope="col">AMOUNT</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                              {(this.state.customer_tcs_report.length>0) &&
                                <>
                                  {(this.state.customer_tcs_report.map((value,index) =>{
                                    return (
                                      <tr key={ index } className="contact-row">
                                        <td>
                                          <a href={"/view-contact/"+value.contact_id} target="_blank">{value.contact_name}</a>
                                        </td>
                                        <td>{utils.currency_format(value.amount)}</td>
                                        <td style={{textAlign:'right'}}>
                                          <button type="button" className="btn btn-outline-info" onClick={this.handleGenerateCustomerTCSReport} data-type="customer" id={"refresh-tcs-"+value.contact_id} data-id={value.contact_id} data-index={index}>Refresh</button>
                                          <button type="button" id={"refresh-tcs-"+value.contact_id+'-spin'} className="btn btn-outline-info hide"><FontAwesomeIcon icon={faSpinner} spin/></button>
                                          
                                        </td>
                                      </tr>
                                    )
                                  }))}
                                </>
                              }
            {(this.state.customer_tcs_report.length===0) &&
              <tr className="contact-row">
                                  <td style={{textAlign:'center'}} colSpan={3}>
                                      <p>{this.state.loading_process}</p>
                                  </td>
                                </tr>
                }
                              
                              {(this.state.customer_tcs_report.length>0 && this.state.is_show_customer_tcs_load_more==true) &&
                                <tr className="contact-row">
                                  <td style={{textAlign:'center'}} colSpan={3}>
                                    {(this.state.is_load_more_clicked===false) &&
                                      <button type="button" className="btn btn-outline-info" onClick={this.handleLoadMoreCustomerTCSReport}>Load More</button>
                                    }
                                    {(this.state.is_load_more_clicked===true) &&
                                      <button type="button" className="btn btn-outline-info">Loading...</button>
                                    }
                                  </td>
                                </tr>
                              }
                              
                                
                              </tbody>
                                
                                    </table>
                                  </div>
                                
                                </div>
                              </div>
                            
                          }
                          </div>
                        </div>
                        {(this.state.active_tab==='tcs_tax') &&
                          <div className="row">
                              <div className="col-md-2">
                              <label className="lbl">TAX TYPE</label>
                                <select className="form-control" id="tax-type">
                                  <option value="sale">Sales</option>
                                  <option value="purchase">Purchase</option>
                                </select>
                              </div>
                              <div className="col-md-5"></div>
                              
                              <div className="col-md-2">
                                <label className="lbl">FROM</label>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text no-border-right" onClick={this.handleCalendarClick} id="cal-from">
                                      <FontAwesomeIcon icon={faCalendarAlt} />
                                    </span>
                                    <DatePicker className="form-control  no-border-left" selected={this.state.FromDate} onChange={this.FromDate} dateFormat="dd/MM/yyyy" id="cal-from-date"/>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <label className="lbl">TO</label>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text  no-border-right" onClick={this.handleCalendarClick}  id="cal-to"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                    <DatePicker className="form-control  no-border-left" selected={this.state.ToDate} onChange={this.ToDate} dateFormat="dd/MM/yyyy" id="cal-to-date"/>
                                  </div>
                                </div>
                              </div>
                                            
                            <div className="col-md-1" style={{marginTop:'29px'}}>
                              <button type="button" className="btn btn-success" onClick={this.handleShowReport} id="view-report" data-target={this.state.active_tab}>VIEW</button>
                              <button type="button" id="view-report-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                            </div>
                          </div>
                        }
                        {(this.state.active_tab==='tcs_customer' && this.state.is_generated_tcs===false) &&
                        <>
                          <div className="row">
                            <div className="col-md-12 text-center mrtop-30">
                                <button className="btn btn-success" onClick={this.handleGenerateCustomerTCSReport} data-type="all">Generate Customer TCS Report</button>
                            </div>
                          </div>
                         
                        </>
                        }
                         <div id="process-tcs-transaction" className="modal fade">
                            <div className="modal-dialog">
                              <div className="modal-content csv-load-blk">
                                <div className="parent-bar">
                                  <div className="child-bar" style={{width:this.state.progress+'%'}}></div>
                                </div>
                                <p>Please wait while tcs transaction process complete ({this.state.progress}%)</p>
                              </div>
                            </div>
                          </div>
                        </>
                        }

                        {(this.state.active_tab==='customer-pending') &&
                        <>
                          <div className="row">
                              <div className={(this.state.enable_orders===true)?"col-md-2":"col-md-2 hide"}>
                              <label className="lbl">FORMAT TYPE</label>
                                <select className="form-control" id="pending-invoice-format-type" onChange={this.handleChangePendingReportFormatType}>
                                  <option value="billing">Billing</option>
                                  {(this.state.enable_orders===true) &&
                                  <option value="orders">Orders</option>
                                  }
                                </select>
                              </div>
                              <div className="col-md-2">
                                <label className="lbl">REPORT TYPE</label>
                                <select className="form-control" id="pending-invoice-load-type" onChange={this.handleChangePendingInvoiceLoadType}>
                                  <option value="1">Customer</option>
                                  <option value="2">Supplier</option>
                                  <option value="5">Agent</option>
                                  <option value="group">Group</option>
                                  <option value="state">State</option>
                                  {/* <option value="city">City</option> */}
                                  <option value="date">Date Range</option>
                                </select>
                              </div>
                              {(this.state.is_load_contact_dd) &&
                                <div className="col-md-4">
                                  <label className="lbl">CONTACT</label>
                                  <ContactsDropdown show="dropdown" label="Type to select contact" input_id="contact_box" contact_info={[]} contact_type={this.state.pending_contact_type} target="pending-invoice" is_enable_tag={true}/>
                                </div>
                              }
                              {(this.state.pending_invoice_load_type==='date') &&
                              <>
                                <div className="col-md-2">
                                  <label className="lbl">FROM</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text no-border-right" onClick={this.handleCalendarClick} id="cal-from">
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                      </span>
                                      <DatePicker className="form-control  no-border-left" selected={this.state.FromDate} onChange={this.FromDate} dateFormat="dd/MM/yyyy" id="cal-from-date"/>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <label className="lbl">TO</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text  no-border-right" onClick={this.handleCalendarClick}  id="cal-to"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                      <DatePicker className="form-control  no-border-left" selected={this.state.ToDate} onChange={this.ToDate} dateFormat="dd/MM/yyyy" id="cal-to-date"/>
                                    </div>
                                  </div>
                                </div>
                              </>
                              }
                              {(this.state.pending_invoice_load_type==='group') &&
                                <div className="col-md-4">
                                  <label className="lbl">GROUPS</label>
                                  <ContactGroups show="dropdown" name="group_id" id="group_id" target="pending-invoice"/>
                                </div>
                              }
                              {(this.state.pending_invoice_load_type==='state') &&
                                <div className="col-md-4">
                                  <label className="lbl">STATES</label>
                                  <select tabindex="8" name="state" className="form-field form-control" id="pending-invoice-state" >
                                      <option key="select_state" value="" >Select State</option>
                                      {(states.length > 0) ? states.map( (value, index) => {
                                      return (
                                          <option key={index} value={value}>{value}</option>
                                        )
                                      }) : ''}
                                      </select>
                                </div>
                              }
                              <div className="col-md-4" style={{marginTop:'29px'}}>
                                <button type="button" className="btn btn-success" onClick={this.handleLoadPendingInvoice} id="view-pending-report" data-target={this.state.active_tab}>Generate Report</button>
                                <button type="button" id="view-pending-report-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                              </div>
                            
                            
                          </div>
                          <div className="row" style={{color:'gray'}}>
                            <div className="col-md-12 mrtop-30">
                                <label>
                                  <u>SHOW IN THE LIST</u>
                                </label>
                            </div>
                            <div className="col-md-12" style={{textTransform:'uppercase'}}>
                             
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="checkbox" id="sale_purchase_return_cb" value="return"  onChange={this.handleHidePendingInvoiceFromList} defaultChecked={true}/>
                                  <label className="form-check-label" for="sale_purchase_return_cb">Sales/Purchase Return</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="checkbox" id="credit_debit_note_cb" value="note"  onChange={this.handleHidePendingInvoiceFromList} defaultChecked={true}/>
                                  <label className="form-check-label" for="credit_debit_note_cb">Credit/Debit Note</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="checkbox" id="payments_cb" value="payments"  onChange={this.handleHidePendingInvoiceFromList} defaultChecked={true}/>
                                  <label className="form-check-label" for="payments_cb">PAYMENTS</label>
                                </div>
                                {(this.state.pending_report_format_type==='orders') &&
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="opening_balance_cb" value="opening_balance"  onChange={this.handleHidePendingInvoiceFromList} defaultChecked={true}/>
                                    <label className="form-check-label" for="opening_balance_cb">OPENING BALANCE</label>
                                  </div>
                                }
                                
                            </div>
                          </div>
                        </>
                        }
                        {(this.state.active_tab==='reports') &&
                        <div className="row">
                          <div className="col-md-3">
                              <label className="lbl">SHOW TRANSACTIONS</label>
                              <ContactsDropdown show="dropdown" label="Type to select contact" input_id="contact_box" contact_info={[]} index="reports"/>
                              <input type="hidden" id="type-ledger" value="yes"/>
                          </div>
                          <div  className={(this.state.enable_orders===true)?"col-md-2":"col-md-2 hide"}>
                              <label className="lbl">SELECT FORMAT</label>
                              <select className="form-control" id="legder-format" onChange={this.handleChangeLedgerFormat} value={this.state.ledger_format}>
                                {(this.state.enable_orders===true) &&
                                  <option value="format1">ALL</option>
                                }
                                <option value="format2">BILLING</option>
                                {(this.state.enable_orders===true) &&
                                  <option value="format3">ORDERS</option>
                                }
                                {/* <option value="format2">Format 2</option> */}
                              </select>
                          </div>
                          <div className="col-md-2">
                            <label className="lbl">FROM</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text no-border-right" onClick={this.handleCalendarClick} id="cal-from">
                                  <FontAwesomeIcon icon={faCalendarAlt} />
                                </span>
                                <DatePicker className="form-control  no-border-left" selected={this.state.FromDate} onChange={this.FromDate} dateFormat="dd/MM/yyyy" id="cal-from-date"/>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <label className="lbl">TO</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text  no-border-right" onClick={this.handleCalendarClick}  id="cal-to"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                <DatePicker className="form-control  no-border-left" selected={this.state.ToDate} onChange={this.ToDate} dateFormat="dd/MM/yyyy" id="cal-to-date"/>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2" style={{marginTop:'35px'}}>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="checkbox" id="is-hide-opening-balnace" value="yes" />
                              <label className="form-check-label chklbl" for="is-hide-opening-balnace">HIDE OPENING BALANCE</label>
                            </div>
                          </div>
                          <div className="col-md-1 text-right" style={{marginTop:'29px'}}>
                            <button type="button" className="btn btn-success" onClick={this.handleShowReport} id="view-report" data-target={this.state.active_tab}>VIEW</button>
                            <button type="button" id="view-report-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </div>
                        </div>
                        }
                      {(this.state.active_tab==='profit_loss') &&
                        <div className="row">
                          <div className="col-md-4">
                             
                          </div>
                          <div className="col-md-3">
                              
                          </div>
                          <div className="col-md-2">
                            <label className="lbl">FROM</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text no-border-right" onClick={this.handleCalendarClick} id="cal-from">
                                  <FontAwesomeIcon icon={faCalendarAlt} />
                                </span>
                                <DatePicker className="form-control  no-border-left" selected={this.state.FromDate} onChange={this.FromDate} dateFormat="dd/MM/yyyy" id="cal-from-date"/>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <label className="lbl">TO</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text  no-border-right" onClick={this.handleCalendarClick}  id="cal-to"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                <DatePicker className="form-control  no-border-left" selected={this.state.ToDate} onChange={this.ToDate} dateFormat="dd/MM/yyyy" id="cal-to-date"/>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-1" style={{marginTop:'29px'}}>
                            <button type="button" className="btn btn-success" onClick={this.handleShowReport} id="view-report" data-target={this.state.active_tab}>VIEW</button>
                            <button type="button" id="view-report-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </div>
                        </div>
                        }

                        {(this.state.active_tab==='transactions' || this.state.active_tab==='transactions-book') &&
                          <div className="row">
                            <div className="col-md-12">
                            <div className="nav-container">
                              <ul className="nav nav-pills">
                                <li className={this.state.active_tab==='transactions'?'active':''}><a href="/reports/transactions">Transactions</a></li>
                                <li className={this.state.active_tab==='transactions-book'?'active':''}><a href="/reports/transactions/book">Book</a></li>
                              </ul>
                            </div>
                            </div>
                          </div>
                        }

                        {(this.state.active_tab==='sales_report' || this.state.active_tab==='transactions'  || this.state.active_tab==='transactions-book') &&
                        <>
                          <div className="row"> 
                          {(this.state.active_tab==='sales_report') &&
                            <div className="col-md-2">
                              <label className="lbl">REPORT TYPE</label>
                              <select className="form-control" id="report-type" onChange={this.handleChangeReportType}>
                                <option value="Chart">Sales Report</option>
                                <option value="CreditDebit">Receivable/Payable</option>
                              </select>
                            </div>
                          }
                          {(this.state.active_tab==='transactions-book') &&
                            <div className="col-md-3">
                              <label className="lbl">REPORT TYPE</label>
                              <select className="form-control" id="report-type" onChange={this.handleChangeReportType}>
                                <option value="sales_book">Sales Book</option>
                                <option value="sales_return_book">Sales Return Book</option>
                                <option value="purchase_book">Purchase Book</option>
                                <option value="purchase_return_book">Purchase Return Book</option>
                              </select>
                            </div>
                          }
                            <div className={(this.state.report_type==='CreditDebit')?"col-md-5":"col-md-4"}>
                              {( this.state.report_type==='Chart' || this.state.report_type==='sales_book' || this.state.report_type==='sales_return_book' || this.state.report_type==='purchase_book' || this.state.report_type==='purchase_return_book') &&
                              <>
                                <label className="lbl">OPTIONS</label>
                                
                                {(this.state.active_tab==='sales_report' || this.state.active_tab==='transactions-book') &&
                                  <select className="form-control" onChange={this.handleReportFilter}>
                                    <option value="today">Today</option>
                                    <option value="yesterday">Yesterday</option>
                                    <option value="this_week">This Week</option>
                                    <option value="last_week">Last Week</option>
                                    <option value="last_30_days">Last 30 Days</option>
                                    <option value="this_month">This Month</option>
                                    <option value="last_month">Last Month</option>
                                    <option value="this_quarter">This Quarter</option>
                                    <option value="last_quarter">Last Quarter</option>
                                    <option value="this_year">This Year</option>
                                    <option value="last_year">Last Year</option>
                                    <option value="other">Custom</option>
                                  </select>
                                }
                                
                                </>
                              }
                              {(this.state.report_type==='CreditDebit') &&
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="lbl">Payable/Receivable</label>
                                    <select className="form-control" id="creditors-debitors" onChange={(e)=>this.handleChangePayableRecievableType(e.target.value)}>
                                      <option value="all">All</option>
                                      <option value="creditors">Receivable</option>
                                      <option value="debitors">Payable</option>
                                    </select>
                                  </div> 
                                  <div  className={(this.state.enable_orders===true)?"col-md-4":"col-md-4 hide"}>
                                  
                                    <label className="lbl">Type</label>
                                    <select className="form-control" onChange={this.handleChangeTransactionOpt} defaultValue={this.state.transaction_opt}>
                                      <option value="billing">Billings</option>
                                      {(this.state.enable_orders===true) &&
                                        <option value="orders">Orders</option>
                                      }
                                    </select>
                                    
                                  </div>
                                  <div className="col-md-4">
                                    <label className="lbl">GROUPS</label>
                                    <ContactGroups show="dropdown" name="group_id" id="group_id" target="reports"/>
                                  </div>
                                </div>
                              }
                              {(this.state.active_tab==='transactions') &&
                                <select  className={(this.state.enable_orders===true)?"form-control":"form-control hide"} onChange={this.handleChangeTransactionOpt} defaultValue={this.state.transaction_opt}>
                                  <option value="billing">Billings</option>
                                  {(this.state.enable_orders===true) &&
                                    <option value="orders">Orders</option>
                                  }
                                </select>
                              }
                            </div>
                            {(this.state.filter_option==='other' || this.state.active_tab==='transactions' || this.state.report_type==='CreditDebit') &&
                              <>
                            {(this.state.active_tab==='transactions') &&
                            <div className="col-md-3">
                              <label className="lbl">TYPE</label>
                              <select className="form-control" onChange={this.handleReportFilter}>
                                <option value="sales">Sales</option>
                               
                                <option value="purchase">Purchases</option>
                                <option value="sales_return">Sales Retrun</option>
                                <option value="purchase_return">Purchases Retrun</option>
                            
                                <option value="payment_in">Payment IN(+)</option>
                              
                                <option value="payment_out">Payment OUT(-)</option>
                                <option value="today">All</option>
                              
                              </select>
                            </div>
                            }
                            <div className="col-md-2">
                              <label className="lbl">FROM</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text no-border-right" onClick={this.handleCalendarClick}  id="cal-from">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                  </span>
                                  <DatePicker className="form-control  no-border-left" selected={this.state.FromDate} onChange={this.FromDate} dateFormat="dd/MM/yyyy" id="cal-from-date"/>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <label className="lbl">TO</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text  no-border-right"  onClick={this.handleCalendarClick}  id="cal-to"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                  <DatePicker className="form-control  no-border-left" selected={this.state.ToDate} onChange={this.ToDate} dateFormat="dd/MM/yyyy" id="cal-to-date"/>
                                </div>
                              </div>
                            </div>
                            </>
                          }
                          {(this.state.active_tab==='sales_report' && this.state.report_type==='CreditDebit') &&
                          <div className="col-md-1" style={{marginTop:'29px'}}>
                            <button type="button" className="btn btn-success" onClick={this.handleShowPayableReceivableReport} id="view-report" data-target={this.state.active_tab}>VIEW</button>
                            <button type="button" id="view-report-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </div>
                          }
                          {(this.state.active_tab!=='sales_report' && this.state.active_tab!=='transactions-book') &&
                            <div className="col-md-1" style={{marginTop:'29px'}}>
                              <button type="button" className="btn btn-success" onClick={this.handleShowReport} id="view-report" data-target={this.state.active_tab}>VIEW</button>
                              <button type="button" id="view-report-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                            </div>
                          }
                          {(this.state.active_tab==='transactions-book') &&
                            <div className="col-md-1" style={{marginTop:'29px'}}>
                              <button type="button" className="btn btn-success" onClick={this.handleShowTransactionBookReport} id="view-book-report" data-target={this.state.active_tab}>VIEW</button>
                              <button type="button" id="view-book-report-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                            </div>
                          }
                          {(this.state.active_tab==='sales_report' && this.state.report_type==='Chart') &&
                            <div className="col-md-1" style={{marginTop:'29px'}}>
                              <button type="button" className="btn btn-success" onClick={this.handleShowSalesChartReport} id="view-report" data-target={this.state.active_tab}>VIEW</button>
                              <button type="button" id="view-report-btn-spinner" className="hide btn btn-success" style={{display:'inline-flex',alignItems:'center'}}><FontAwesomeIcon icon={faSpinner} pulse style={{marginRight:'5px'}}/> ({this.state.progress+'%'})</button>
                            </div>
                          }
                          </div>
                          {(this.state.active_tab==='sales_report') &&
                          <div className="row">
                            <div className="col-md-12 mrtop-10">
                                <div id="process-payable-receivable-report" style={{display:'none'}}>
                                  <div className="csv-load-blk" style={{padding:'0px'}}>
                                    <div className="parent-bar" style={{width:'100%'}}>
                                      {(this.state.progress<0) &&
                                        <div className="child-bar" style={{width:'100%',background:'red'}}></div>
                                      }
                                      {(this.state.progress>=0) &&
                                        <div className="child-bar" style={{width:this.state.progress+'%'}}></div>
                                      }
                                    </div>
                                    {(this.state.progress>=0) &&
                                      <p style={{margin:'0px'}}>Please wait while process complete ({this.state.progress}%)</p>
                                    }
                                    {(this.state.progress<0) &&
                                    <p style={{margin:'0px',color:'red'}}>Oops ! something went wrong.</p>
                                    }
                                  </div>
                                </div>
                            </div>
                          </div>
                          }
                        </>
                        }
                        {(this.state.active_tab==='transactions') &&
                          <div className="row" style={{color:'gray'}}>
                            <div className="col-md-12 mrtop-30">
                                <label>
                                  <u>SHOW IN THE LIST</u>
                                </label>
                            </div>
                            <div className="col-md-12 " style={{textTransform:'uppercase'}}>
                            <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="checkbox" id="payment_mode_cb" value="payment_mode" onChange={this.handleHideTransactionFromList} defaultChecked={(this.state.hide_transaction_list_items.indexOf('payment_mode')>=0)?true:false}/>
                                  <label className="form-check-label" for="payment_mode_cb">Payment mode</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="checkbox" id="assisted_by_cb" value="assisted_by"  onChange={this.handleHideTransactionFromList} defaultChecked={(this.state.hide_transaction_list_items.indexOf('assisted_by')>=0)?true:false}/>
                                  <label className="form-check-label" for="assisted_by_cb">Assisted by</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="checkbox" id="customer_cb" value="customer"  onChange={this.handleHideTransactionFromList} defaultChecked={(this.state.hide_transaction_list_items.indexOf('customer')>=0)?true:false}/>
                                  <label className="form-check-label" for="customer_cb">Customer</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="checkbox" id="before_tax_cb" value="before_tax_amount"  onChange={this.handleHideTransactionFromList} defaultChecked={(this.state.hide_transaction_list_items.indexOf('before_tax_amount')>=0)?true:false}/>
                                  <label className="form-check-label" for="before_tax_cb">Before tax amount</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="checkbox" id="tax_cb" value="tax"  onChange={this.handleHideTransactionFromList} defaultChecked={(this.state.hide_transaction_list_items.indexOf('tax')>=0)?true:false}/>
                                  <label className="form-check-label" for="tax_cb">Tax (cgst | sgst | igst)</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="checkbox" id="discount_cb" value="discount"  onChange={this.handleHideTransactionFromList} defaultChecked={(this.state.hide_transaction_list_items.indexOf('discount')>=0)?true:false}/>
                                  <label className="form-check-label" for="discount_cb">Discount</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="checkbox" id="tcs_cb" value="tcs"  onChange={this.handleHideTransactionFromList} defaultChecked={(this.state.hide_transaction_list_items.indexOf('tcs')>=0)?true:false}/>
                                  <label className="form-check-label" for="tcs_cb">TCS</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="checkbox" id="total_cb" value="total"  onChange={this.handleHideTransactionFromList} defaultChecked={(this.state.hide_transaction_list_items.indexOf('total')>=0)?true:false}/>
                                  <label className="form-check-label" for="total_cb">Total</label>
                                </div>
                            </div>
                          </div>
                        }
                      </div>
                  </div>
                </div>
                {(this.state.active_tab==='sales_report' && this.state.report_type==='Chart' && this.state.chart_data.labels) &&
                  <div className="container-fluid">
                    <div className="content-area">
                       <div className="content-filter">
                          <div className="row">
                            <div className="col-md-12">
                             
                                <Line data={this.state.chart_data} options={this.options} height={80}/>
                            
                            </div>
                          </div>
                          <div className="row mrtop-10">
                            <div className="col-md-12">
                              <p style={{margin:'0px'}}>Total earnings for the period shown : ₹{this.currency_format(this.state.total_earning)}</p>
                              <p style={{margin:'0px'}}>Total sales for the period shown : {this.currency_format(this.state.total_sale)}</p>
                            </div>
                          </div>
                          <div className="row mrtop-10">
                            <div className="col-md-12">
                            <ReactHTMLTableToExcel
                            id="sales-chart-table-button"
                            className="btn btn-success mrright-5  pull-right  mrtop-10 mrbtm-10"
                            table="sales-chart-table"
                            filename="sales-chart-report"
                            sheet="sales-chart-table"
                            buttonText="Download as XLS"/>
                              <table className="table" id="sales-chart-table">
                                <thead>
                                  <th>LABEL</th>
                                  <th>TOTAL SALES</th>
                                  <th>TOTAL EARNINGS</th>
                                </thead>
                                <tbody>
                                  {(this.state.chart_table_format.map((value,index)=>{
                                    return(
                                      <tr>
                                        <td>{value.label}</td>
                                        <td>{value.total_sale}</td>
                                        <td>₹{utils.currency_format(value.total_earning)}</td>
                                      </tr>
                                    )
                                  }))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                }

                {(this.state.active_tab==='sales_report' && this.state.report_type==='CreditDebit') &&
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content">
                     {(this.state.creditors_debitors_report_tmp && this.state.creditors_debitors_report_tmp.customers && this.state.creditors_debitors_report_tmp.customers.length > 0) &&
                        <div className="row">
                         <div className="col-md-6"></div>
                         <div className="col-md-3 mrtop-10">
                           <select className="form-control" onChange={this.handleDrCrContactChange}>
                            <option value="all">All</option>
                            {(this.state.creditors_debitors_report_tmp.customers_dropdown.map((value,index)=>{
                                let customers_dropdown = value;
                                return (
                                  <option value={index} key={customers_dropdown.id}>{customers_dropdown.name}</option>
                                )
                            }))}
                           </select>
                         </div>
                         <div className="col-md-3">
                           <button type="button" className="btn btn-success mrright-5 pull-right mrtop-10 mrbtm-10" onClick={this.handleInventoryPrint}>Print</button>
                           <ReactHTMLTableToExcel
                            id="donwload-transaction-xls-button"
                            className="btn btn-success mrright-5  pull-right  mrtop-10 mrbtm-10"
                            table="debitors-report-xls"
                            filename="transactions-report"
                            sheet="transactions-report"
                            buttonText="Download as XLS"/>
                          </div>
                        </div>
                      
                      }
                     <div className="table-responsive" id="report-print">
                        <table className="table" id="debitors-report-xls">
                        <thead className="thead-light">
                        {(this.state.is_print===true) &&
                          <tr>
                                <th colSpan={5} style={{textTransform:'uppercase',textAlign:'center'}}>
                                    
                                    <h2 style={{margin:'0px',fontWeight:'bold',fontSize:'16px'}}>
                                        {(this.state.creditors_debitors_report && this.state.creditors_debitors_report.company_info)?this.state.creditors_debitors_report.company_info[0].company_name:''
                                        }
                                    </h2>
                                        {(this.state.creditors_debitors_report && this.state.creditors_debitors_report.company_info && this.state.creditors_debitors_report.company_info[0]!==undefined && this.state.creditors_debitors_report.company_info[0].company_sub_title!=="" && this.state.creditors_debitors_report.company_info[0].company_sub_title!==null) &&
                                        <>
                                        <p style={{color:'gray',fontSize:'14px',margin:'0px'}}>{this.state.creditors_debitors_report.company_info[0].company_sub_title}</p>
                                        </>
                                        }
                                    
                                  
                                        <p style={{margin:'0px'}}>
                                        {(this.state.creditors_debitors_report && this.state.creditors_debitors_report.company_info && this.state.creditors_debitors_report.company_info[0].address!=='' && this.state.creditors_debitors_report.company_info[0].address!==null)?this.state.creditors_debitors_report.company_info[0].address:''
                                        }
                                        </p>
                                        <p style={{margin:'0px'}}>
                                        {(this.state.creditors_debitors_report && this.state.creditors_debitors_report.company_info && this.state.creditors_debitors_report.company_info[0].city!=='' && this.state.creditors_debitors_report.company_info[0].city!==null)?this.state.creditors_debitors_report.company_info[0].city:''
                                        }
                                        {(this.state.creditors_debitors_report && this.state.creditors_debitors_report && this.state.creditors_debitors_report.company_info && this.state.creditors_debitors_report.company_info[0].pin_code!=='' && this.state.creditors_debitors_report.company_info[0].pin_code!==null )?'-'+this.state.creditors_debitors_report.company_info[0].pin_code:''
                                        }
                                        {(this.state.creditors_debitors_report && this.state.creditors_debitors_report && this.state.creditors_debitors_report.company_info && this.state.creditors_debitors_report.company_info[0].state!=='' && this.state.creditors_debitors_report.company_info[0].state!==null )?' '+this.state.creditors_debitors_report.company_info[0].state:''
                                        }
                                        </p>
                                        {(this.state.creditors_debitors_report && this.state.creditors_debitors_report && this.state.creditors_debitors_report.company_info && this.state.creditors_debitors_report.company_info[0].phone!=='') &&
                                        <p style={{margin:'0px'}}>
                                       
                                            {(this.state.creditors_debitors_report && this.state.creditors_debitors_report && this.state.creditors_debitors_report.company_info && this.state.creditors_debitors_report.company_info[0].phone!=="" && this.state.creditors_debitors_report.company_info[0].phone!==null)?this.state.creditors_debitors_report.company_info[0].phone:''
                                            }
                                            
                                        </p>
                                        }
                                </th>
                          </tr>
                        }
                          <tr>
                            <th scope="col">CUSTOMER</th>
                            <th scope="col"></th>
                            <th scope="col">TOTAL AMOUNT</th>
                            <th scope="col">TOTAL ADJUSTED</th>
                            <th scope="col">TOTAL BALANCE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.customers_ids.length > 0) ?this.state.customers_ids.map( (contact_id, index) => {
                           customers_info = this.state.creditors_debitors_report.customers_data[contact_id];
                           if(this.state.creditors_debitors_report.customers_data[contact_id] && this.state.creditors_debitors_report.customers_data[contact_id].data!==undefined){
                             customers_data = this.state.creditors_debitors_report.customers_data[contact_id].data;
                           }
                           let amount = (customers_info && customers_info.amount!==undefined)?customers_info.amount:0;
                           let adjusted = (customers_info && customers_info.adjusted!==undefined)?customers_info.adjusted:0;

                           let contact_type = (customers_info && customers_info.contact_type!==undefined)?customers_info.contact_type:'';

                           paybl_rcvbl_total = paybl_rcvbl_total + amount;
                           paybl_rcvbl_adj = paybl_rcvbl_adj + adjusted;

                           let balance = amount - adjusted;
                           let crdr = (balance>=0)?'Cr':'Dr';
                           if(contact_type==='customer'){
                            if(balance>=0){
                              crdr = 'Dr';
                            }else{
                              crdr = 'Cr';
                            }
                           }
                           balance = Math.abs(balance);
                           balance = this.currency_format(balance)+''+crdr;
                           return (
                           <>
                           {(customers_info) &&
                           <>
                            <tr className={"dr-cr-report dr-cr-report-"+contact_id} data-id={contact_id} onClick={this.handleToggleCrDrReport}>
                              <th>{customers_info.name}</th>
                              <td></td>
                              
                              <td>
                                <span className={"dr-cr-child-report dr-cr-child-report-"+contact_id}><span>₹</span>{this.currency_format(amount)} {(contact_type==='customer')?'Dr':'Cr'}</span>
                              </td>
                              <td><span className={"dr-cr-child-report dr-cr-child-report-"+contact_id}><span>₹</span>{this.currency_format(customers_info.adjusted)}{(contact_type==='customer')?'Cr':'Dr'}</span></td>
                              <td>
                                <span className={"dr-cr-child-report dr-cr-child-report-"+contact_id}><span>₹</span>{balance}</span>
                                <span className="pull-right">
                                  <FontAwesomeIcon className={"expand-caret-"+contact_id} icon={faCaretRight}/>
                                  <FontAwesomeIcon className={"hide expand-caret-"+contact_id} icon={faCaretDown}/>
                                </span>
                              </td>
                            </tr>
                            <tr className={"hide child-row dr-cr-child-report dr-cr-child-report-"+contact_id}>
                              <th>Date</th>
                              <th>Bill No.</th>
                              <th>Bill Amount</th>
                              <th>Adjusted</th>
                              <th>Balance Amount</th>
                            </tr>
                            {customers_data.map((v, i) =>
                              <tr className={"contact-row child-row hide dr-cr-child-report dr-cr-child-report-"+contact_id} key={i}>
                                <td>{v.created_on}</td>
                                <td>
                                  <span className="no-print">
                                    {(v.target==='payment') &&
                                      <DrawerLink target="payment" url={'/view-payment/'+v.id} name={v.invoice_number} schema={v.schema}/>
                                    }
                                    {(v.target!=='payment') &&
                                      <DrawerLink target="billing" url={'/view-billing/'+v.target+'/'+v.id} name={v.invoice_number} schema={v.schema}/>
                                    }
                                  </span>
                                  {(this.state.is_print===true) &&
                                    <span className="print-block">
                                      {v.invoice_number}
                                    </span>
                                  }
                                </td>
                                <td>
                                  {(v.target!=='payment' && v.amount>0) &&
                                    <>
                                    <span>₹</span>
                                    {this.currency_format(v.amount)}
                                    <span>
                                      {(contact_type==='customer')?'Dr':'Cr'}
                                    </span>
                                    </>
                                  }
                                </td>
                                <td>
                                  {(v.target!=='payment' && v.adjusted>0) &&
                                    <>
                                    <span>₹</span>
                                    {this.currency_format(v.adjusted)}
                                    <span>
                                      {(contact_type==='customer')?'Cr':'Dr'}
                                    </span>
                                    </>
                                  }
                                  {(v.target==='payment') &&
                                    <>
                                      <span>₹</span>
                                      {this.currency_format(v.amount)}
                                      <span>
                                        {(contact_type==='customer')?'Cr':'Dr'}
                                      </span>
                                    </>
                                  }
                                </td>
                                <td>
                                  <span>₹</span>
                                  {this.currency_format(Math.abs(v.balance_amount))}
                                  {(v.target!=='payment' && v.amount>0) &&
                                    <span>
                                      {(contact_type==='customer')?'Dr':'Cr'}
                                    </span>
                                  }
                                  {(v.target!=='payment' && v.adjusted>0) &&
                                    <span>
                                      {(contact_type==='customer')?'Cr':'Dr'}
                                    </span>
                                  }
                                  {(v.target==='payment') &&
                                      <span>
                                        {(contact_type==='customer')?'Cr':'Dr'}
                                      </span>
                                  }
                                </td>
                              </tr>
                            )}
                            <tr className={"child-row hide dr-cr-child-report dr-cr-child-report-"+contact_id}>
                              <th></th>
                              <th></th>
                              <th>
                                <span>₹</span>{this.currency_format(amount)}
                                {(contact_type==='customer' && amount>=0) &&
                                  <span>
                                    Dr
                                  </span>
                                  }
                                  {(contact_type==='customer' && amount<0) &&
                                  <span>
                                    Cr
                                  </span>
                                  }
                                  {(contact_type==='supplier' && amount>=0) &&
                                  <span>
                                    Cr
                                  </span>
                                  }
                                  {(contact_type==='supplier' && amount<0) &&
                                  <span>
                                    Dr
                                  </span>
                                  }
                              </th>
                              <th>
                                <span>₹</span>{this.currency_format(customers_info.adjusted)}
                                {(contact_type==='customer') &&
                                  <span>
                                    Cr
                                  </span>
                                  }
                                  {(contact_type==='supplier') &&
                                  <span>
                                    Dr
                                  </span>
                                  }
                              </th>
                              <th>
                                  <span>₹</span>
                                  {balance}
                              </th>
                            </tr>
                          </>
                          }
                          </>
                          )
                         }) : <tr><td colSpan={5} className="text-center">{this.state.loading_process}</td></tr> }
                        </tbody>
                        <tfoot>
                            <tr>
                           
                              <th></th>
                              <th></th>
                             
                              <th>
                                {(this.state.customers_ids.length > 0) &&
                                <>
                                <span style={{fontWeight:'bold',fontSize:'20px'}}>₹</span>
                                <label id="trans-total" style={{fontWeight:'bold',fontSize:'20px'}}>
                                {this.currency_format(paybl_rcvbl_total)}
                                {(this.state.pay_rcv_type==='creditors') &&
                                  <span>
                                    Dr
                                  </span>
                                  }
                                  {(this.state.pay_rcv_type==='debitors') &&
                                  <span>
                                    Cr
                                  </span>
                                  }
                                </label>
                                </>
                                }
                              </th>
                              <th>
                              {(this.state.customers_ids.length > 0) &&
                                <>
                                <span style={{fontWeight:'bold',fontSize:'20px'}}>₹</span>
                                <label id="trans-total" style={{fontWeight:'bold',fontSize:'20px'}}>
                                {this.currency_format(paybl_rcvbl_adj)}
                                {(this.state.pay_rcv_type==='creditors') &&
                                  <span>
                                    Cr
                                  </span>
                                  }
                                  {(this.state.pay_rcv_type==='debitors') &&
                                  <span>
                                    Dr
                                  </span>
                                  }
                                </label>
                                </>
                                }
                              </th>
                              <th>
                                {(this.state.customers_ids.length > 0) &&
                                <>
                                <span style={{fontWeight:'bold',fontSize:'20px'}}>₹</span>
                                <label id="trans-total" style={{fontWeight:'bold',fontSize:'20px'}}>
                                  {this.currency_format(Math.abs(paybl_rcvbl_total-paybl_rcvbl_adj))}
                                  {((paybl_rcvbl_total-paybl_rcvbl_adj)>=0)?'Cr':'Dr'}

                                  {(this.state.pay_rcv_type==='creditors' && (paybl_rcvbl_total-paybl_rcvbl_adj)>=0) &&
                                  <span>
                                    Dr
                                  </span>
                                  }
                                  {(this.state.pay_rcv_type==='creditors' && (paybl_rcvbl_total-paybl_rcvbl_adj)<0) &&
                                  <span>
                                    Cr
                                  </span>
                                  }
                                  

                                  {(this.state.pay_rcv_type==='debitors' && (paybl_rcvbl_total-paybl_rcvbl_adj)>=0) &&
                                  <span>
                                    Cr
                                  </span>
                                  }
                                  {(this.state.pay_rcv_type==='debitors' && (paybl_rcvbl_total-paybl_rcvbl_adj)<0) &&
                                  <span>
                                    Dr
                                  </span>
                                  }
                                  
                                </label>
                                </>
                                }
                              </th>
                            
                            </tr>
                        </tfoot>
                      </table>
                     </div>
                     </div>
                  </div>
                </div>
              }

                {(this.state.active_tab==='transactions' && this.state.transaction_report) &&
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content">
                     {(this.state.transaction_report && this.state.transaction_report.length > 0) &&
                      <>
                      <button type="button" className="btn btn-success mrright-5 pull-right mrtop-10 mrbtm-10" onClick={this.handleInventoryPrint}>Print</button>
                       <ReactHTMLTableToExcel
                        id="donwload-transaction-xls-button"
                        className="hide btn btn-success mrright-5  pull-right  mrtop-10 mrbtm-10"
                        table="transactions-report-xls"
                        filename="transactions-report"
                        sheet="transactions-report"
                        buttonText="Download as XLS"/>
                        <button type="button" className="btn btn-success mrright-5 pull-right mrtop-10 mrbtm-10" onClick={this.handleDownloadTransactionReport}>Download as XLS</button>
                        </>
                      }
                     <div className="table-responsive" id="report-print">
                        <table className="table" id="transactions-report-xls">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">
                          
                            </th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            {(this.state.filter_option==='today') &&

                            <th scope="col"></th>
                            }
                            {(this.state.hide_transaction_list_items.indexOf('customer')>=0) &&
                            <th scope="col" id='contact_name'></th>
                            }
                            {(this.state.hide_transaction_list_items.indexOf('payment_mode')>=0) &&
                            <>
                            {(this.state.payment_mode_option.length > 0) &&
                            <th scope="col" colSpan={(this.state.selected_payment_mode==='all')?this.state.payment_mode_option.length:1} style={{textAlign:'center'}}>
                                PAYMENT MODE
                                {(this.state.is_downloading_trans===false) &&
                                <select style={{marginLeft:'5px'}} onChange={this.handlePaymentModeChange} className="no-print">
                                  <option value="no">All</option>
                                  {(this.state.payment_mode_option.length > 0) ? this.state.payment_mode_option.map( (value, index) => {
                                  return (
                                      <option value={value.replace(/\s/g, '_')} key={index}>{value}</option>
                                    )
                                  }) : ''}
                              </select>
                              }
                              
                               
                              </th>
                            }
                              </>
                            }
                            {(this.state.hide_transaction_list_items.indexOf('assisted_by')>=0) &&
                            <>
                            {(this.state.assisted_by_option.length > 0) &&
                              <>
                              <th scope="col"></th>
                              {(this.state.is_item_commission===true) &&
                                <th scope="col"></th>
                              }
                              </>
                            }
                            </>
                            }
                            {(this.state.hide_transaction_list_items.indexOf('before_tax_amount')>=0) &&
                            
                              <th scope="col" ></th>

                            }
                            {(this.state.hide_transaction_list_items.indexOf('discount')>=0) &&
                            <th scope="col" ></th>
                          }
                          {(this.state.hide_transaction_list_items.indexOf('tax')>=0) &&
                          <>
                            <th scope="col" colSpan={8}></th>
                            
                          </>
                          }
                          {(this.state.hide_transaction_list_items.indexOf('tcs')>=0) &&
                            <th scope="col" ></th>
                          }
                          {(this.state.hide_transaction_list_items.indexOf('total')>=0) &&
                              <th scope="col" ></th>
                          }
                          </tr>
                          <tr>
                            <th scope="col">
                            {(this.state.filter_option==='sales') &&
                              <input type="checkbox" onChange={this.handleSelectAllTransaction}/>
                            }
                            </th>
                            <th scope="col">DATE</th>
                            <th scope="col">INVOICE NO #</th>
                            {(this.state.filter_option==='today') &&

                            <th scope="col">TYPE</th>
                            }
                            {(this.state.hide_transaction_list_items.indexOf('customer')>=0) &&
                            <th scope="col" id='contact_name' onClick={this.onSort} className="sortable-head">CUSTOMER 
                              <div className="sort-cont no-print">
                                <p className="sort-icon1"><FontAwesomeIcon icon={faCaretUp} /></p>
                                <p className="sort-icon2"><FontAwesomeIcon icon={faCaretDown} /></p>
                              </div>
                            </th>
                            }
                            {(this.state.hide_transaction_list_items.indexOf('payment_mode')>=0) &&
                            <>
                              
                              {(this.state.payment_mode_option.length > 0) ? this.state.payment_mode_option.map( (value, index) => {
                                let np = value.replace(/\s/g, '_');
                                return (
                                    <>
                                    {(this.state.selected_payment_mode==='all' || this.state.selected_payment_mode===np) &&
                                      <th>{value}</th>
                                    }
                                    </>
                                  )
                                }) : ''}
                              </>
                            }
                            {(this.state.hide_transaction_list_items.indexOf('assisted_by')>=0) &&
                            <>
                            {(this.state.assisted_by_option.length > 0) &&
                            <>
                              <th scope="col">
                                ASSISTED BY
                                {(this.state.is_downloading_trans===false) &&
                                <select style={{marginLeft:'5px'}} onChange={this.handleStaffChange} className="no-print">
                                  <option value="no">All</option>
                                  <option value="none">None</option>
                                  {(this.state.assisted_by_option.length > 0) ? this.state.assisted_by_option.map( (value, index) => {
                                  return (
                                      <option value={value.replace(/\s/g, '_')} key={index}>{value}</option>
                                    )
                                  }) : ''}
                              </select>
                              }
                              </th>
                              {(this.state.is_item_commission===true) &&
                                <th scope="col">COMMISSION</th>
                              }
                            </>
                              }
                            </>
                            }
                            {(this.state.hide_transaction_list_items.indexOf('before_tax_amount')>=0) &&
                            
                              <th scope="col" >TOTAL BEFORE TAX</th>

                            }
                            {(this.state.hide_transaction_list_items.indexOf('discount')>=0) &&
                            <th scope="col" >DISCOUNT</th>
                          }
                          {(this.state.hide_transaction_list_items.indexOf('tax')>=0) &&
                          <>
                            <th scope="col" >CGST 2.5%</th>
                            <th scope="col" >SGST 2.5%</th>
                            <th scope="col" >IGST 5%</th>
                            <th scope="col" >CGST 6%</th>
                            <th scope="col" >SGST 6%</th>
                            <th scope="col" >IGST 12%</th>
                            <th scope="col" >TOTAL 5%</th>
                            <th scope="col" >TOTAL 12%</th>
                            {/* <th scope="col" >TOTAL IGST</th> */}
                          </>
                          }
                          {(this.state.hide_transaction_list_items.indexOf('tcs')>=0) &&
                            <th scope="col" >TCS</th>
                        }
                        {(this.state.hide_transaction_list_items.indexOf('total')>=0) &&
                            <th scope="col" >AMOUNT</th>
                        }
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.transaction_report && this.state.transaction_report.length > 0) ?this.state.transaction_report.map( (value, index) => {
                            total_transaction_amount = total_transaction_amount + parseFloat(value.amount)
                            none_assisted_by = value.assisted_by;
                            none_assisted_by = (none_assisted_by==='-')?'none':value.assisted_by.replace(/\s/g, '_');
                           return (
                            <tr key={ index } className={"contact-row transactions-row "+none_assisted_by+" "+value.classes} data_target={value.amount}id={"trans-list-items-"+value.id} >
                              <td>
                                {(this.state.filter_option==='sales') &&
                                  <input type="checkbox" className="tr-cb-item" onChange={this.handleChangeTransItemAction} value={value.id}/>
                                }
                              </td>
                              <td>{value.created_on}</td>
                              <td>
                                <span className="no-print">
                                {(value.target==='payments' && value.screen==='billing') &&
                                  <DrawerLink target="payment" url={'/view-payment/'+value.id} name={value.invoice_number}  schema={value.schema}/>
                               
                                }
                                {(value.target==='payments' && value.screen==='order') &&
                                  <DrawerLink target="payment" url={'/order/view-payment/'+value.id} name={value.invoice_number}  schema={value.schema}/>
                               
                                }
                                {(value.target!=='payments') &&
                                <>
                                  <DrawerLink target="billing" url={'/view-billing/'+value.target+'/'+value.id} name={value.invoice_number}  schema={value.schema}/>
                                  {(value.dispatched_info && value.dispatched_info.supplier_ref_no!==undefined && value.dispatched_info.supplier_ref_no!=="") &&
                                    <span style={{color:'gray'}}> | {value.dispatched_info.supplier_ref_no}</span>
                                  }
                                </>
                                
                                }
                                </span>
                                {(this.state.is_print===true) &&
                                  <span className="print-block">
                                    {value.invoice_number}
                                    {(value.dispatched_info!==undefined && value.dispatched_info.supplier_ref_no!==undefined && value.dispatched_info.supplier_ref_no!=="") &&
                                      <span style={{color:'gray'}}> | {value.dispatched_info.supplier_ref_no}</span>
                                    }
                                  </span>
                                }
                              </td>
                              {(this.state.filter_option==='today') &&
                              <td>{value.type}</td>
                              }
                              {(this.state.hide_transaction_list_items.indexOf('customer')>=0) &&
                              <td>
                                <span className="no-print">
                                <Link link={'/view-contact/'+value.contact_id} name={value.contact_name} />
                            
                                </span>
                                {(this.state.is_print===true) &&
                                  <>
                                    <p className="print-block">
                                      {value.contact_name}
                                      {(value.contact_info && value.contact_info.phone_1 && value.contact_info.phone_1!=="") &&
                                        <label style={{marginLeft:'5px'}}> | {value.contact_info.phone_1}</label>
                                      }
                                    </p>
                                  </>
                                }
                              </td>
                              }
                              {(this.state.hide_transaction_list_items.indexOf('payment_mode')>=0) &&
                              <>
                                {(this.state.payment_mode_option.length > 0) ? this.state.payment_mode_option.map( (pmo, index) => {
                                  let np = pmo.replace(/\s/g, '_');
                                  
                                  let ppmoval = value.payment_mode_data_new[np];
                                  return (
                                    <>
                                    {(this.state.selected_payment_mode==='all' || this.state.selected_payment_mode===np) &&
                                      <td>
                                        {ppmoval}
                                      </td>
                                    }
                                    </>
                                    )
                                }) : ''}
                              </>
                              }
                              {(this.state.hide_transaction_list_items.indexOf('assisted_by')>=0) &&
                            <>
                              {(this.state.assisted_by_option.length > 0) &&
                              <>
                              <td>{value.assisted_by}</td>  
                              {(this.state.is_item_commission===true) &&
                                <td scope="col">{value.total_commission}</td>
                              }
                              </>
                              }
                              </>
                          }
                          {(this.state.hide_transaction_list_items.indexOf('before_tax_amount')>=0) &&
                              <td><span>₹</span>{this.currency_format(value.sub_total)}</td>
                        }
                        {(this.state.hide_transaction_list_items.indexOf('discount')>=0) &&
                              <td><span>₹</span>{this.currency_format(value.discount)}</td>
                          }
                          {(this.state.hide_transaction_list_items.indexOf('tax')>=0) &&
                          <>
                              <td>
                              {(value.tax_data && value.tax_data.cgst!==undefined && value.tax_data.cgst['2.5']!==undefined) &&
                              <>
                                {this.currency_format(value.tax_data.cgst['2.5'])}
                              </>
                              }
                              </td>
                              <td>
                              {(value.tax_data && value.tax_data.sgst!==undefined && value.tax_data.sgst['2.5']!==undefined) &&
                              <>{this.currency_format(value.tax_data.sgst['2.5'])}</>
                              }
                              </td>
                              <td>
                              {(value.tax_data && value.tax_data.igst!==undefined && value.tax_data.igst['5']!==undefined) &&
                              <>{this.currency_format(value.tax_data.igst['5'])}</>
                              }
                              </td>
                              <td>
                              {(value.tax_data && value.tax_data.cgst!==undefined && value.tax_data.cgst['6']!==undefined) && 
                              <>{this.currency_format(value.tax_data.cgst['6'])}</>
                              }
                              </td>
                              <td>
                              {(value.tax_data && value.tax_data.sgst!==undefined && value.tax_data.sgst['6']!==undefined) &&
                              <>{this.currency_format(value.tax_data.sgst['6'])}</>
                              }
                              </td>
                              <td>
                              {(value.tax_data && value.tax_data.igst!==undefined && value.tax_data.igst['12']!==undefined) && 
                              <>{this.currency_format(value.tax_data.igst['12'])}</>
                              }
                              </td>
                              <td>
                                
                              {(value.tax_data && value.tax_data.total!==undefined && value.tax_data.total['5']!==undefined) && 
                              <>{this.currency_format(value.tax_data.total['5'])}</>
                              }</td>
                              <td>
                              {(value.tax_data && value.tax_data.total!==undefined && value.tax_data.total['12']!==undefined) && 
                              <>{this.currency_format(value.tax_data.total['12'])}</>
                              }
                              </td>
                              
                              </>
                              }
                              {(this.state.hide_transaction_list_items.indexOf('tcs')>=0) &&
                              <td>
                                {(value.tcs_percent!=="" && value.tcs_percent>0) &&
                                <>
                                  <span>₹</span>
                                  {this.currency_format(value.tcs_total)}({value.tcs_percent})
                                </>
                                }
                              </td>
                          }
                          {(this.state.hide_transaction_list_items.indexOf('total')>=0) &&
                              <td><span>₹</span>{this.currency_format(value.amount)}</td>
                          }
                            </tr>
                          )
                         }) : <tr><td colSpan={(this.state.filter_option==='today')?18:15} className="text-center">{this.state.loading_process}</td></tr> }


                            <tr>
                              <th>
                                {(this.state.is_show_trans_action===true && this.state.filter_option==='sales') &&
                                  <select className="form-control" onChange={this.handleChangeTranAction}>
                                    <option value='delete'>Delete</option>
                                  </select>
                                }
                              </th>
                              <th>
                              {(this.state.is_show_trans_action===true && this.state.filter_option==='sales') &&
                                <button type="button" className="btn btn-success" onClick={this.handleProceedTransAction}>Proceed</button>
                              }
                              </th>
                              {(this.state.filter_option==='today') &&
                                <th></th>
                              }
                              <th></th>
                              {(this.state.hide_transaction_list_items.indexOf('customer')>=0) &&
                                <th></th>
                              }
                              {(this.state.hide_transaction_list_items.indexOf('payment_mode')>=0) &&
                              <>
                               {(this.state.payment_mode_option.length > 0) ? this.state.payment_mode_option.map( (pmo, index) => {
                                let np = pmo.replace(/\s/g, '_');
                                let ppmoval = this.state.payment_mode_total[np];
                                return (
                                  <>
                                    {(this.state.selected_payment_mode==='all' || this.state.selected_payment_mode===np) &&
                                    <th>{ppmoval}</th>
                                    }
                                  </>
                                  )
                                }) : ''}
                              </>
                            }
                            {(this.state.hide_transaction_list_items.indexOf('assisted_by')>=0) &&
                              <>
                                {(this.state.assisted_by_option.length > 0) &&
                                <>
                                  <th></th>
                                  {(this.state.is_item_commission===true) &&
                                    <th scope="col"></th>
                                  }
                                </>
                              }
                              </>
                            }
                              {(this.state.hide_transaction_list_items.indexOf('before_tax_amount')>=0) &&
                              <th></th>
                              }
                              {(this.state.hide_transaction_list_items.indexOf('discount')>=0) &&
                              <th></th>
                            }
                              {(this.state.hide_transaction_list_items.indexOf('tax')>=0) &&
                          <>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                          </>
                          }
                              {(this.state.hide_transaction_list_items.indexOf('tcs')>=0) &&
                              <th></th>
                              }
                              {(this.state.hide_transaction_list_items.indexOf('total')>=0) &&
                              <th><span id="transaction-total"><span>₹</span><label id="trans-total" style={{fontWeight:'bold'}}>{this.currency_format(total_transaction_amount)}</label></span></th>
                              }
                            </tr>
                      
                        </tbody>
                       
                      </table>
                     </div>
                     </div>
                  </div>
                  {(this.state.is_delete_trans) &&
                    <div id="delete_transaction_popup" className="modal" style={{display:'block'}}>
                      <div className="modal-dialog modal-confirm">
                        <div className="modal-content">
                          <div className="modal-header flex-column">
                            <div className="icon-box">
                              <span className="material-icons">&times;</span>
                            </div>            
                            <h4 className="modal-title w-100">Are you sure?</h4>
                          </div>
                          <div className="modal-body">
                            <p style={{whiteSpace: 'break-spaces'}}>Do you really want to delete these records? This process cannot be undone</p>
                            <div className="form-check form-check-inline" id="without-inv-blck">
                              <input className="form-check-input" type="checkbox" id="without-effecting-inv" defaultChecked={true}/>
                              <label className="form-check-label chklbl" htmlFor="without-effecting-inv">WITHOUT EFFECTING INVENTORY</label>
                            </div>
                          </div>
                          <div className="modal-footer justify-content-center">
                            <button type="button" className="btn btn-secondary" onClick={this.handleCloseDelete}>No</button>
                            {(this.state.is_deleting_trans===false) &&
                              <button type="button" className="btn btn-danger" onClick={this.handleDeleteTransaction}>Yes</button>
                            }
                            {(this.state.is_deleting_trans===true) &&
                              <button type="button" className="btn btn-danger"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                            }
                          </div>
                        </div>
                      </div>
                    </div> 
                  }
                </div>
              }
              {(this.state.active_tab==='transactions-book' && this.state.transaction_book_report) &&
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content">
                     {(this.state.transaction_book_report && this.state.transaction_book_report.length > 0) &&
                      <>
                      <button type="button" className="btn btn-success mrright-5 pull-right mrtop-10 mrbtm-10" onClick={this.handleInventoryPrint}>Print</button>
                       <ReactHTMLTableToExcel
                        id="donwload-transaction-book-xls-button"
                        className="btn btn-success mrright-5  pull-right  mrtop-10 mrbtm-10"
                        table="transactions-book-report-xls"
                        filename="transactions-book-report"
                        sheet="transactions-book-report"
                        buttonText="Download as XLS"/>
                        </>
                      }
                     <div className="table-responsive" id="report-print">
                        <table className="table" id="transactions-book-report-xls">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">DATE</th>
                            <th scope="col">INVOICE NO #</th>
                            <th scope="col">NAME</th>
                            <th scope="col">GSTIN</th>
                            <th scope="col">PAYMENT MODE</th>
                            <th scope="col">ASSISTED BY</th>
                            <th scope="col">PARTY</th>
                            <th scope="col">PRODUCT</th>
                            <th scope="col">CODE</th>
                            <th scope="col">QUANTITY</th>
                            <th scope="col">RATE</th>
                            <th scope="col">CGST%</th>
                            <th scope="col">CGST</th>
                            <th scope="col">SGST%</th>
                            <th scope="col">SGST</th>
                            <th scope="col">IGST%</th>
                            <th scope="col">IGST</th>
                            <th scope="col">GROSS</th>
                            <th scope="col">NET</th>

                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.transaction_book_report && this.state.transaction_book_report.length > 0) ?this.state.transaction_book_report.map( (value, index) => {
                          
                           return (
                            <tr key={ index } className={"contact-row transactions-row "}>
                              <td>{value.invoice_date}</td>
                              <td>
                               
                                <DrawerLink target="billing" url={'/view-billing/'+value.target+'/'+value.invoice_id} name={value.invoice_number}  schema={value.schema}/>
                               
                              </td>
                              <td>
                                {value.category}
                              </td>
                              <td>
                                {value.gstin}
                              </td>
                              <td>
                                {(value.payment_mode)}
                              </td>
                              <td>
                                {value.assisted_by}
                              </td>
                              <td>
                                
                                <Link link={'/view-contact/'+value.party_info.contact_id} name={value.party_info.name} target='_blank' />
                              {/* 
                                <p style={{margin:'0px',fontSize: '12px',color: 'gray'}}>{value.party_info.phone_1}</p> */}
                               
                              </td>
                              <td>
                                &nbsp;{value.item_name}
                              </td>
                              <td>
                                {value.hsn}
                              </td>
                              <td>
                                {value.quantity}
                              </td>
                              <td>
                              ₹{utils.currency_format(value.rate)}
                              </td>
                              <td>
                                {(value.cgst_tax_per>0)?value.cgst_tax_per:''}
                              </td>
                              <td>
                              {(value.cgst_tax_amt>0)?'₹'+utils.currency_format(value.cgst_tax_amt):''}
                              </td>
                              <td>
                              {(value.sgst_tax_per>0)?value.sgst_tax_per:''}
                              </td>
                              <td>
                              {(value.sgst_tax_amt>0)?'₹'+utils.currency_format(value.sgst_tax_amt):''}
                              </td>
                              <td>
                              {(value.igst_tax_per>0)?value.igst_tax_per:''}
                              </td>
                              <td>
                              {(value.igst_tax_amt>0)?'₹'+utils.currency_format(value.igst_tax_amt):''}
                              </td>
                              <td>
                              ₹{utils.currency_format(value.gross)}
                              </td>
                              <td>
                              ₹{utils.currency_format(value.net)}
                              </td>
                            </tr>
                          )
                         }) : <tr><td colSpan={19} className="text-center">{this.state.loading_process}</td></tr> }
                        </tbody>
                      </table>
                     </div>
                     </div>
                  </div>
                </div>
              }
              {(this.state.active_tab==='expenses' && this.state.expenses_report) &&
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content">
                     {(this.state.expenses_report && this.state.expenses_report.length > 0) &&
                      <>
                      <button type="button" className="btn btn-success mrright-5 pull-right mrtop-10 mrbtm-10" onClick={this.handleInventoryPrint}>Print</button>
                       <ReactHTMLTableToExcel
                        id="donwload-transaction-xls-button"
                        className="btn btn-success mrright-5  pull-right  mrtop-10 mrbtm-10"
                        table="expenses-report-xls"
                        filename="expenses-report"
                        sheet="expenses-report"
                        buttonText="Download as XLS"/>
                        </>
                      }
                     <div className="table-responsive" id="report-print">
                        <table className="table" id="expenses-report-xls">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">EXPENSE #</th>
                            <th scope="col">DATE</th>
                            <th scope="col">TYPE</th>
                            <th scope="col">NAME</th>
                            <th scope="col">MODE OF PAYMENT</th>
                            <th scope="col" className="text-right">AMOUNT</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.expenses_report && this.state.expenses_report.length > 0) ?this.state.expenses_report.map( (value, index) => {
                           return (
                            <tr key={ index } id={"billing-row-"+value.expense_id} className="contact-row">
                             
                              <td>
                                {value.expense_number}
                              </td>
                              <td>{value.created_date}</td>
                              <td>{value.expense_type_name}</td>            
                              <td>{value.contact_name}</td>
                              <td>{value.payment_mode}</td>            
                              <td className="text-right">₹{this.currency_format(value.grand_total.replace(/,/g ,''))}</td>
                           
                            </tr>
                          )
                         }) : <tr><td colSpan={6} className="text-center">{this.state.loading_process}</td></tr> }
                        </tbody>
                        <tfoot>
                            <tr>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                            
                              <th className="text-right">₹{this.currency_format(this.state.total_grand_amount)}</th>
                            </tr>
                        </tfoot>
                      </table>
                     </div>
                     </div>
                  </div>
                </div>
              }
              {(this.state.active_tab==='profit_loss' && this.state.profit_loss_report.cogs!==undefined) &&
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content">
                     {(this.state.profit_loss_report && this.state.profit_loss_report.cogs!==undefined) &&
                      <>
                      <button type="button" className="btn btn-success mrright-5 pull-right mrtop-10 mrbtm-10" onClick={this.handleInventoryPrint}>Print</button>
                       <ReactHTMLTableToExcel
                        id="donwload-transaction-xls-button"
                        className="btn btn-success mrright-5  pull-right  mrtop-10 mrbtm-10"
                        table="profit-loss-report-xls"
                        filename="profit-loss-report"
                        sheet="profit-loss-report"
                        buttonText="Download as XLS"/>
                        </>
                      }
                     <div className="table-responsive plr-div" id="report-print">
                        <table className="table" id="profit-loss-report-xls">
                          <thead>
                            <tr>
                              <th  style={{textAlign:'center'}} colSpan="3">{user_info.company_name}</th>
                            </tr>
                            <tr>
                              <th  style={{textAlign:'center'}} colSpan="3">Profit & Loss Statement</th>
                            </tr>
                            <tr>
                              <th  style={{textAlign:'center'}} colSpan="3">From {this.state.profit_loss_from} to  {this.state.profit_loss_to}</th>
                            </tr>
                            <tr>
                              <th>Particulars</th>
                              <th>Amount</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Sales</td>
                              <td>₹{this.currency_format(this.state.profit_loss_report.total_sale)}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Cost of Goods Sold</td>
                              <td>₹{this.currency_format(this.state.profit_loss_report.cogs)}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>Gross Profit</th>
                              <td></td>
                              <td>₹{this.currency_format(this.state.profit_loss_report.gross_profit)}</td>
                            </tr>
                            {(this.state.profit_loss_report.expense_types.length>0) &&
                              <>
                              <tr>
                                <th colSpan="3">Operating Expenses</th>
                              </tr>
                              {this.state.profit_loss_report.expense_types.map((value, index) => (
                                <tr>
                                  <td>{value.key}</td>
                                  <td>₹{this.currency_format(value.value)}</td>
                                  <td></td>
                                </tr>
                              ))}
                              <tr>
                                <th>Total Operating Expenses</th>
                                <td></td>
                                <td>₹{this.currency_format(this.state.profit_loss_report.operating_expenses)}</td>
                              </tr>
                              </>
                            }
                            <tr>
                              <th>Operating Income</th>
                              <td></td>
                              <td>₹{this.currency_format(this.state.profit_loss_report.operating_income)}</td>
                            </tr>
                            <tr>
                              <th>Net Income</th>
                              <td></td>
                              <td>₹{this.currency_format(this.state.profit_loss_report.net_income)}</td>
                            </tr>
                          </tbody>
                        </table>
                     </div>
                     </div>
                  </div>
                </div>
              }
              {/* {(this.state.active_tab==='tax' && this.state.tax_report) &&
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content">
                     {(this.state.tax_report && this.state.tax_report.length > 0) &&
                      <>
                      <button type="button" className="btn btn-success mrright-5 pull-right mrtop-10 mrbtm-10" onClick={this.handleInventoryPrint}>Print</button>
                      <ReactHTMLTableToExcel
                        id="donwload-tax-xls-button"
                        className="btn btn-success mrright-5  pull-right  mrtop-10 mrbtm-10"
                        table="tax-report-xls"
                        filename="tax-report"
                        sheet="tax-report"
                        buttonText="Download as XLS"/>
                        
                      <h2 className="pull-right mrtop-10" style={{marginRight:'15px'}}>Total Tax - ₹{this.state.total_collected_tax}</h2>
                        </>
                      }
                     <div className="table-responsive" id="report-print">
                        <table className="table" id="tax-report-xls">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">DATE</th>
                            <th scope="col">ITEM NAME</th>
                            <th scope="col">AMOUNT</th>
                            <th scope="col">TAX</th>
                            <th scope="col">INVOICE NUMBER</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.tax_report && this.state.tax_report.length > 0) ?this.state.tax_report.map( (value, index) => {
                           return (
                            <tr key={ index } className="contact-row">
                              <td>{value.date}</td>
                              <td>{value.item_name}</td>
                              <td>₹{this.currency_format(value.price.replace(/,/g ,''))}</td>
                              <td>₹{this.currency_format(value.tax)}</td>
                              <td>{value.invoice_number}</td>
                            </tr>
                          )
                         }) : <tr><td colSpan={5} className="text-center">{this.state.loading_process}</td></tr> }
                        </tbody>
                       </table>
                     </div>
                     </div>
                  </div>
                </div>
              } */}

              {(this.state.active_tab==='tax' && this.state.tax_report) &&
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content">
                     {(this.state.tax_report && (this.state.tax_report.length > 0 || this.state.tax_report.length===undefined)) &&
                      <div className="row">
                          <div className="col-md-7">
                            {(this.state.tax_section==='all') &&
                              <h5 style={{marginTop:'16px',marginLeft:'16px'}}>{this.state.total_b2b}<i style={{fontSize:'13px'}}>(B2B)</i> + {this.state.total_b2c}<i style={{fontSize:'13px'}}>(B2C)</i> - {this.state.total_cdnr}<i style={{fontSize:'13px'}}>(CDNR)</i> = {this.state.total_hsn}<i style={{fontSize:'13px'}}>(HSN)</i></h5>
                            }
                          </div>
                          <div className="col-md-5">
                          <button type="button" className="btn btn-success mrright-5 pull-right mrtop-10 mrbtm-10" onClick={this.handleInventoryPrint}>Print</button>
                          <a href={this.state.gst_json_file} download><button type="button" className="btn btn-success mrright-5 pull-right mrtop-10 mrbtm-10">Download GST JSON</button></a>
                          <ReactHTMLTableToExcel
                            id="donwload-tax-xls-button"
                            className="btn btn-success mrright-5  pull-right  mrtop-10 mrbtm-10"
                            table="tax-report-xls"
                            filename={this.state.tax_section+"-tax-report"}
                            sheet="tax-report"
                            buttonText="Download as XLS"/>
                            
                          {/* <h2 className="pull-right mrtop-10" style={{marginRight:'15px'}}>Total Tax - ₹{this.state.total_collected_tax}</h2> */}
                          </div>
                        </div>
                      }
                      {(this.state.gst_json_file!=='') &&
                        <div className="table-responsive" id="report-print">
                              {(this.state.tax_section==='hsn') &&
                                <>
                                {/* <TaxList data={this.state} target="hsn" id="tax-report-xls"/> */}
                                <table  className="table" id="tax-report-xls">
                                  <tbody>
                                    <tr><th>HSN</th></tr>
                                    <tr>
                                      <td>
                                        <TaxList data={this.state} target="hsn_combined"/>
                                      </td>
                                    </tr>
                                    {/* <tr><th>SALES HSN</th></tr>
                                    <tr>
                                      <td>
                                        <TaxList data={this.state} target="hsn"/>
                                      </td>
                                    </tr>
                                    <tr><th>CDNR HSN</th></tr>
                                    <tr>
                                      <td>
                                        <TaxList data={this.state} target="cdnr_hsn"/>
                                      </td>
                                    </tr> */}
                                  </tbody>
                                </table>
                                  </>
                              }
                              {(this.state.tax_section==='cdnr') &&
                                <TaxList data={this.state} target="cdnr"id="tax-report-xls"/>
                              } 
                              {(this.state.tax_section==='b2b' || this.state.tax_section==='b2cs' || this.state.tax_section==='b2b_b2cs') &&
                                <TaxList data={this.state} target={(this.state.tax_section==='b2cs')?'b2c':'b2b'} id="tax-report-xls"/>
                              }

                              {(this.state.tax_section==='all') &&
                                <table  className="table" id="tax-report-xls">
                                    <tbody>
                                      <tr><th>B2B</th></tr>
                                      <tr>
                                        <td>
                                          <TaxList data={this.state} target="b2b"/>
                                        </td>
                                      </tr>
                                      <tr><th>B2C</th></tr>
                                      <tr>
                                        <td>
                                          <TaxList data={this.state} target="b2c"/>
                                        </td>
                                      </tr>
                                      <tr><th>CDNR</th></tr>
                                      <tr>
                                        <td>
                                          <TaxList data={this.state} target="cdnr"/>
                                        </td>
                                      </tr>
                                      <tr><th>HSN</th></tr>
                                      <tr>
                                        <td>
                                          <TaxList data={this.state} target="hsn_combined"/>
                                        </td>
                                      </tr>
                                      {/* <tr><th>SALES HSN</th></tr>
                                      <tr>
                                        <td>
                                          <TaxList data={this.state} target="hsn"/>
                                        </td>
                                      </tr>
                                      <tr><th>CDNR HSN</th></tr>
                                      <tr>
                                        <td>
                                          <TaxList data={this.state} target="cdnr_hsn"/>
                                        </td>
                                      </tr> */}
                                      {/* <tr><th>ALL SALES HSN</th></tr>
                                      <tr>
                                        <td>
                                          <TaxList data={this.state} target="hsn_list"/>
                                        </td>
                                      </tr> */}
                                    </tbody>
                                </table>
                              }
                        </div>
                      }
                     </div>
                  </div>
                  <div id="process-tax-report" className="modal fade">
                    <div className="modal-dialog">
                      <div className="modal-content csv-load-blk">
                        <div className="parent-bar">
                          <div className="child-bar" style={{width:this.state.progress+'%'}}></div>
                        </div>
                        <p>{this.state.gst_tax_report_download_msg} ({this.state.progress}%)</p>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {(this.state.active_tab==='tcs_tax' && this.state.tcs_tax_report) &&
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content">
                     {(this.state.tcs_tax_report && (this.state.tcs_tax_report.length > 0 || this.state.tcs_tax_report.length===undefined)) &&
                      <div className="row">
                          <div className="col-md-4">
                            
                          </div>
                          <div className="col-md-8">
                          {/* <button type="button" className="btn btn-success mrright-5 pull-right mrtop-10 mrbtm-10" onClick={this.handleInventoryPrint}>Print</button>
                          */}
                          <ReactHTMLTableToExcel
                            id="donwload-tax-xls-button"
                            className="btn btn-success mrright-5  pull-right  mrtop-10 mrbtm-10"
                            table="tax-report-xls"
                            filename={"tcs-tax-report"}
                            sheet="tax-report"
                            buttonText="Download as XLS"/>
                            
                          {/* <h2 className="pull-right mrtop-10" style={{marginRight:'15px'}}>Total Tax - ₹{this.state.total_collected_tax}</h2> */}
                          </div>
                        </div>
                      }
                      
                      <div className="table-responsive" id="report-print">
                      <table className="table" id="tax-report-xls">
              
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">PARTY NAME</th>
                      <th scope="col">GST NUMBER</th>
                      <th scope="col">INVOICE#</th>
                      <th scope="col">DATE</th>
                      <th scope="col">GROSS AMOUNT</th>
                      <th scope="col">DISCOUNT</th>
                      <th scope="col">NET AMOUNT</th>
                      <th scope="col">TAX</th>
                      <th scope="col">TCS TAXABLE VALUE</th>
                      <th scope="col">TCS</th>
                      <th scope="col">ROUND OFF</th>
                      <th scope="col">INVOICE VALUE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(this.state.tcs_tax_report && this.state.tcs_tax_report.length > 0) ?this.state.tcs_tax_report.map( (value, index) => {
                      tcs_gross_total = tcs_gross_total + parseFloat(value.sub_total);
                      tcs_total_disc = tcs_total_disc + parseFloat(value.global_discount);
                      tcs_total_net_amt = tcs_total_net_amt + parseFloat(value.total_before_tax);
                      tcs_total_tax = tcs_total_tax + parseFloat(value.total_tax);
                      tcs_total_taxable_amt = tcs_total_taxable_amt + parseFloat(value.taxable_value);
                      tcs_total = tcs_total + parseFloat(value.tcs_total);
                      tcs_total_inv_value = tcs_total_inv_value + parseFloat(value.invoice_value);
                    return (
                      <tr key={ index } className="contact-row">
                        <td>{value.party_name}</td>
                        <td>{value.party_gst}</td>
                        <td><DrawerLink target="billing" url={value.url} name={value.invoice_number}  schema={value.schema}/></td>
                        <td>{value.date}</td>
                        <td>₹{this.currency_format(value.sub_total)}</td>
                        <td>₹{this.currency_format(value.global_discount)}</td>
                        <td>₹{this.currency_format(value.total_before_tax)}</td>
                        <td>₹{this.currency_format(value.total_tax)}</td>
                        <td>₹{this.currency_format(value.taxable_value)}</td>
                        <td>₹{this.currency_format(value.tcs_total)}({value.tcs_percent}%)</td>
                        <td>{value.round_off_sym}{value.round_off}</td>
                        <td>₹{this.currency_format(value.invoice_value)}</td>
                      </tr>
                    )
                  }) : <tr>
                      <td colSpan={12} className="text-center">
                      {(this.state.tcs_tax_report && this.state.tcs_tax_report.length === 0) &&
                        <>{this.state.loading_process}</>
                      }
                    </td></tr> }
                  </tbody>
                      {(this.state.tcs_tax_report && this.state.tcs_tax_report.length > 0) &&
                      <tfoot>
                          <tr>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th>₹{this.currency_format(tcs_gross_total)}</th>
                              <th>₹{this.currency_format(tcs_total_disc)}</th>
                              <th>₹{this.currency_format(tcs_total_net_amt)}</th>
                              <th>₹{this.currency_format(tcs_total_tax)}</th>
                              <th>₹{this.currency_format(tcs_total_taxable_amt)}</th>
                              <th>₹{this.currency_format(tcs_total)}</th>
                              <th></th>
                              <th>₹{this.currency_format(tcs_total_inv_value)}</th>
                          </tr>
                      </tfoot>
                      }
                        </table>
                      </div>
                    
                     </div>
                  </div>
                </div>
              }
              
              {(this.state.active_tab==='audit-trial' && this.state.audit_trial_report) &&
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content">
                     {/* {(this.state.audit_trial_report && (this.state.audit_trial_report.length > 0 || this.state.audit_trial_report.length===undefined)) &&
                      <div className="row">
                          <div className="col-md-4">
                            
                          </div>
                          <div className="col-md-8">
                          
                          <ReactHTMLTableToExcel
                            id="donwload-tax-xls-button"
                            className="btn btn-success mrright-5  pull-right  mrtop-10 mrbtm-10"
                            table="tax-report-xls"
                            filename={"tcs-tax-report"}
                            sheet="tax-report"
                            buttonText="Download as XLS"/>
                            
                          </div>
                        </div>
                      } */}
                      
                      <div className="table-responsive" id="report-print">
                      <table className="table" id="tax-report-xls">
              
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">DATE</th>
                      <th scope="col">USERNAME</th>
                      <th scope="col">MESSAGE</th>
                      <th scope="col">INVOICE #</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(this.state.audit_trial_report && this.state.audit_trial_report.length > 0) ?this.state.audit_trial_report.map( (value, index) => {
                    return (
                        <tr key={ index } className="contact-row">
                          <td>{value.action_date}</td>
                          <td>{value.user_info.username}</td>
                          <td>{value.action_message}</td>
                          <td>
                            {(value.invoice_info && value.invoice_info.invoice_id && value.invoice_info.invoice_url) &&
                              <DrawerLink target={value.view_type} url={value.invoice_info.invoice_url} name={value.invoice_info.invoice_number} schema={value.invoice_info.schema}/>
                            }
                            {(value.invoice_info && value.invoice_info.invoice_id===undefined) &&
                              <span>-</span>
                            }
                          </td>
                        </tr>
                      )
                    }) : <tr>
                      <td colSpan={4} className="text-center">
                      {(this.state.audit_trial_report && this.state.audit_trial_report.length === 0) &&
                        <>{this.state.loading_process}</>
                      }
                    </td></tr> }
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={4} className="text-center">
                      {(this.state.is_loaded_audit_trial===false) &&
                        <>
                        {(this.state.is_load_more_clicked===false) &&
                          <button type="button" className="btn btn-outline-info" onClick={this.handleLoadMoreAuditTrial}>Load More</button>
                        }
                        {(this.state.is_load_more_clicked===true) &&
                          <button type="button" className="btn btn-outline-info">Loading...</button>
                        }
                        </>
                      }
                      </td>
                    </tr>
                  </tfoot>
                        </table>
                      </div>
                    
                     </div>
                  </div>
                </div>
              }

              {(this.state.active_tab==='customer-pending' && this.state.is_showing_pending_invoice===true && this.state.is_all_contact===false) &&
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content">
                     {(this.state.contact_ids && this.state.contact_ids.length > 0) &&
                      <>

                      <button type="button" className="btn btn-success mrright-5 pull-right mrtop-10 mrbtm-10" onClick={this.handleInventoryPrint}>Print</button>
                      <ReactHTMLTableToExcel
                      id="pending-invoice-report-table-xls-button"
                      className="btn btn-success pull-right  mrtop-10 mrright-5"
                      table="pending-invoice-report-table"
                      filename="pending_invoice"
                      sheet="pending_invoice"
                      buttonText="Download XLS"/>
                      <a href={this.state.pending_share_data.sharable_link} target="_blank" className="pull-right mrtop-10 mrright-5">
                        <button type="button" className="btn btn-success">Download PDF</button>
                      </a>
                      {(this.state.pending_share_data[this.state.contact_ids[0]] && this.state.pending_share_data[this.state.contact_ids[0]].data && this.state.pending_share_data[this.state.contact_ids[0]].data.length!==0 && this.state.contact_ids.length===1) &&
                        <div className="share-blk">
                        <ReactWhatsapp number={this.state.pending_share_data[this.state.contact_ids[0]].data.mobile} message={this.state.pending_share_data[this.state.contact_ids[0]].data.message} element="div"><button type="button" style={{marginTop:'10px',marginRight:'5px'}} className="btn btn-success"><div className="icons8-whatsapp"></div> WhatsApp</button></ReactWhatsapp>
                        </div>
                      }
                      </>
                      }
                      <div id="report-print">

                      <p className="text-right print-block" style={{fontWeight:'bold'}}>Print On : {this.state.FromDate.getDate()+'/'+(this.state.FromDate.getMonth()+1)+'/'+this.state.FromDate.getFullYear()}</p>
                      <table id="pending-invoice-report-table" className="table pending-invoice">
                      <tr>
                        <td>
                      {(this.state.contact_ids && this.state.contact_ids.length > 0) ?this.state.contact_ids.map( (val, ind) => {
                           customer_pending_invoice_total = 0;
                           return (
                                <table className="table pending-invoice">
                                    <thead>
                                    {(this.state.is_print===true) &&
                                      <tr>
                                            <th  colSpan={(this.state.contact_types[val]==='Supplier')?9:7} style={{textTransform:'uppercase',textAlign:'center'}}>
                                                
                                                <h2 style={{margin:'0px',fontWeight:'bold',fontSize:'16px'}}>
                                                    {(this.state.report_company_info && this.state.report_company_info)?this.state.report_company_info[0].company_name:''
                                                    }
                                                </h2>
                                                    {(this.state.report_company_info && this.state.report_company_info && this.state.report_company_info[0]!==undefined && this.state.report_company_info[0].company_sub_title!=="" && this.state.report_company_info[0].company_sub_title!==null) &&
                                                    <>
                                                    <p style={{color:'gray',fontSize:'14px',margin:'0px'}}>{this.state.report_company_info[0].company_sub_title}</p>
                                                    </>
                                                    }
                                                
                                              
                                                    <p style={{margin:'0px'}}>
                                                    {(this.state.report_company_info && this.state.report_company_info && this.state.report_company_info[0].address!=='' && this.state.report_company_info[0].address!==null)?this.state.report_company_info[0].address:''
                                                    }
                                                    </p>
                                                    <p style={{margin:'0px'}}>
                                                    {(this.state.report_company_info && this.state.report_company_info && this.state.report_company_info[0].city!=='' && this.state.report_company_info[0].city!==null)?this.state.report_company_info[0].city:''
                                                    }
                                                    {(this.state.report_company_info && this.state.report_company_info && this.state.report_company_info[0].pin_code!=='' && this.state.report_company_info[0].pin_code!==null )?'-'+this.state.report_company_info[0].pin_code:''
                                                    }
                                                    {(this.state.report_company_info && this.state.report_company_info && this.state.report_company_info[0].state!=='' && this.state.report_company_info[0].state!==null )?' '+this.state.report_company_info[0].state:''
                                                    }
                                                    </p>
                                                    {(this.state.report_company_info && this.state.report_company_info && this.state.report_company_info[0].phone!=='') &&
                                                    <p style={{margin:'0px'}}>
                                                  
                                                        {(this.state.report_company_info && this.state.report_company_info && this.state.report_company_info[0].phone!=="" && this.state.report_company_info[0].phone!==null)?this.state.report_company_info[0].phone:''
                                                        }
                                                        
                                                    </p>
                                                    }
                                            </th>
                                      </tr>
                                    }
                                        <tr>
                                          <th colSpan={(this.state.contact_types[val]==='Supplier')?8:6} className="text-center" style={{fontSize:'20px'}}>
                                            
                                            {this.state.contact_types[val]+'\'s Name: '+ this.state.contact_names[val]}
                                            {(this.state.pending_share_data[val] && this.state.pending_share_data[val].data && this.state.pending_share_data[val].data.mobile && this.state.pending_share_data[val].data.mobile!=="") &&
                                              <> | {this.state.pending_share_data[val].data.mobile}</>
                                            }
                                          </th>
                                          <th className="text-right">
                                          {(this.state.pending_share_data[val] && this.state.pending_share_data[val].data.mobile  && this.state.pending_share_data[val].data.mobile!==""  && this.state.pending_share_data[val].data.message!=="") &&
                                            <div className="share-blk">
                                            <ReactWhatsapp number={this.state.pending_share_data[val].data.mobile} message={this.state.pending_share_data[val].data.message} element="div"><button type="button" className="btn btn-success" style={{padding:'2px 3px 2px 6px'}}><div className="icons8-whatsapp"></div></button></ReactWhatsapp>
                                            </div>
                                          }
                                            </th>
                                        </tr>
                                        
                                    </thead>
                                    <tbody>
                                   
                                    <tr>
                                      <th colSpan={(this.state.contact_types[val]==='Supplier')?9:7}>
                                        PENDING INVOICE(S)
                                      </th>
                                    </tr>
                                    <tr>
                                      <th>S.No.</th>
                                      <th>Date</th>
                                      <th>Invoice</th>
                                      {(this.state.contact_types[val]==='Supplier') &&
                                        <>
                                          <th>Sup Bill Date</th>
                                          <th>Sup Ref No</th>
                                        </>
                                      }
                                      <th className="text-right">Invoice Value</th>
                                      <th className="text-right">Payment Made/Adjusted</th>
                                      <th className="text-right">Pending</th>
                                      <th className="text-right">Days</th>
                                    </tr>
                                    {(this.state.customer_pending_invoices[val] && this.state.customer_pending_invoices[val].length > 0) ?this.state.customer_pending_invoices[val].map( (value, index) => {
                                      if(value.type!=='sales_return' && value.type!=='purchase_return' && value.type!=='note' && value.type!=='opening_balance'){
                                        customer_pending_invoice_total = customer_pending_invoice_total + value.pending_amount;
                                      }
                                      if(value.type==='sales_return' || value.type==='purchase_return'){
                                        is_show_pending_sr_pr = true;
                                      }
                                      if(value.type==='note'){
                                        is_show_pending_cr_dr = true;
                                      }
                                      if(value.type==='opening_balance' && this.state.show_pending_invoice_list_items.indexOf('opening_balance')>=0){
                                        customer_pending_invoice_total = customer_pending_invoice_total + value.pending_amount;
                                      }
                                     return (
                                          <>
                                          {(value.type==='opening_balance' && this.state.show_pending_invoice_list_items.indexOf('opening_balance')>=0) &&
                                          <tr key={ index } className="contact-row">
                                            <td>{index+1}</td>
                                            <td>{value.created_date}</td>
                                            <td>
                                              <span className="no-print">
                                               <DrawerLink target={value.target} url={value.url} name={value.invoice_number}  schema={value.schema}/>
                                               {(value.fin_year!==undefined) &&
                                                  <span className="fyinstrip">{value.fin_year}</span>
                                                }
                                               </span>
                                              {(this.state.is_print) &&
                                                <span className="print-block">{value.invoice_number}</span>
                                              }
                                            </td>
                                            {(this.state.contact_types[val]==='Supplier') &&
                                              <>
                                                <td>
                                                  {(value.invoice_date)?value.invoice_date:'-'}
                                                </td>
                                                <td>
                                                  {(value.dispached_details)?value.dispached_details.supplier_ref_no:'-'}
                                                </td>
                                                </>
                                            }
                                            <td className="text-right">₹{this.currency_format(value.grand_total)}</td>
                                            <td className="text-right">₹{this.currency_format(value.total_paid)}</td>
                                            <td className="text-right">₹{this.currency_format(value.pending_amount)}</td>
                                            <td className="text-right">{value.age}</td>
                                          </tr>
                                          }
                                          {(value.type!=='sales_return' && value.type!=='purchase_return' && value.type!=='note' && value.type!=='opening_balance') &&
                                          <tr key={ index } className="contact-row">
                                            <td>{index+1}</td>
                                            <td>{value.created_date}</td>
                                            <td>
                                              <span className="no-print">
                                               <DrawerLink target={value.target} url={value.url} name={value.invoice_number}  schema={value.schema}/>
                                               {(value.fin_year!==undefined) &&
                                                  <span className="fyinstrip">{value.fin_year}</span>
                                                }
                                               </span>
                                              {(this.state.is_print) &&
                                                <span className="print-block">{value.invoice_number}</span>
                                              }
                                            </td>
                                            {(this.state.contact_types[val]==='Supplier') &&
                                              <>
                                                <td>
                                                  {(value.invoice_date)?value.invoice_date:'-'}
                                                </td>
                                                <td>
                                                  {(value.dispached_details)?value.dispached_details.supplier_ref_no:'-'}
                                                </td>
                                                </>
                                            }
                                            <td className="text-right">₹{this.currency_format(value.grand_total)}</td>
                                            <td className="text-right">₹{this.currency_format(value.total_paid)}</td>
                                            <td className="text-right">₹{this.currency_format(value.pending_amount)}</td>
                                            <td className="text-right">{value.age}</td>
                                          </tr>
                                          }
                                          </>
                                      )
                                   }) : <tr><td colSpan={7} className="text-center">{this.state.loading_process}</td></tr> }
                                  <tr>
                                      <th colSpan="5" className="text-right">Invoice Pending Amount</th>
                                      {(this.state.contact_types[val]==='Supplier') &&
                                        <th colSpan={2}></th>
                                      }
                                      <th className="text-right">₹{this.currency_format(customer_pending_invoice_total)}</th>
                                      <th></th>
                                    </tr>
                                    {(this.state.show_pending_invoice_list_items.indexOf('return')>=0 && is_show_pending_sr_pr===true) &&
                                      <>
                                      <tr>
                                        <th colSpan={(this.state.contact_types[val]==='Supplier')?9:7}>
                                          PENDING SALES/PURCHASE RETURN
                                        </th>
                                      </tr>
                                      <tr>
                                        <th>S.No.</th>
                                        <th>Date</th>
                                        <th>Invoice</th>
                                        {(this.state.contact_types[val]==='Supplier') &&
                                          <>
                                            <th>Sup Bill Date</th>
                                            <th>Sup Ref No</th>
                                          </>
                                        }
                                        <th className="text-right">Invoice Value</th>
                                        <th className="text-right">Payment Made/Adjusted</th>
                                        <th className="text-right">Pending</th>
                                        <th className="text-right">Days</th>
                                      </tr>
                                      {(this.state.customer_pending_invoices[val] && this.state.customer_pending_invoices[val].length > 0) ?this.state.customer_pending_invoices[val].map( (value, index) => {
                                        if(value.type==='sales_return' || value.type==='purchase_return'){
                                          customer_pending_return_total = customer_pending_return_total + value.pending_amount;
                                        }
                                      return (
                                            <>
                                            {(value.type==='sales_return' || value.type==='purchase_return') &&
                                            <tr key={ index } className="contact-row">
                                              <td>{index+1}</td>
                                              <td>{value.created_date}</td>
                                              <td>
                                                <span className="no-print">
                                                <DrawerLink target={value.target} url={value.url} name={value.invoice_number}  schema={value.schema}/>
                                                {(value.fin_year!==undefined) &&
                                                    <span className="fyinstrip">{value.fin_year}</span>
                                                  }
                                                </span>
                                                {(this.state.is_print) &&
                                                  <span className="print-block">{value.invoice_number}</span>
                                                }
                                              </td>
                                              {(this.state.contact_types[val]==='Supplier') &&
                                                <>
                                                  <td>
                                                    {(value.invoice_date)?value.invoice_date:'-'}
                                                  </td>
                                                  <td>
                                                    {(value.dispached_details)?value.dispached_details.supplier_ref_no:'-'}
                                                  </td>
                                                  </>
                                              }
                                              <td className="text-right">₹{this.currency_format(value.grand_total)}</td>
                                              <td className="text-right">₹{this.currency_format(value.total_paid)}</td>
                                              <td className="text-right">₹{this.currency_format(value.pending_amount)}</td>
                                              <td className="text-right">{value.age}</td>
                                            </tr>
                                            }
                                            </>
                                        )
                                    }) : <tr><td colSpan={7} className="text-center">{this.state.loading_process}</td></tr> }
                                    {(customer_pending_return_total===0) &&
                                      <tr>
                                        <td colSpan={(this.state.contact_types[val]==='Supplier')?9:7} style={{textAlign:'center'}}>
                                          No Record(s) Found
                                        </td>
                                      </tr>
                                    }
                                    {(customer_pending_return_total===0) &&
                                      <tr>
                                        <th colSpan="5" className="text-right">Return Pending Amount</th>
                                        {(this.state.contact_types[val]==='Supplier') &&
                                          <th colSpan={2}></th>
                                        }
                                        <th className="text-right">₹{this.currency_format(customer_pending_return_total)}</th>
                                        <th></th>
                                      </tr>
                                    }
                                      </>
                                    }
                                    {(this.state.show_pending_invoice_list_items.indexOf('note')>=0 && is_show_pending_cr_dr===true) &&
                                      <>
                                      <tr>
                                        <th colSpan={(this.state.contact_types[val]==='Supplier')?9:7}>
                                          CREDIT/DEBIT NOTE
                                        </th>
                                      </tr>
                                      <tr>
                                        <th>S.No.</th>
                                        <th>Date</th>
                                        <th>Invoice</th>
                                        {(this.state.contact_types[val]==='Supplier') &&
                                          <>
                                            <th>Sup Bill Date</th>
                                            <th>Sup Ref No</th>
                                          </>
                                        }
                                        <th className="text-right">Invoice Value</th>
                                        <th className="text-right">Payment Made/Adjusted</th>
                                        <th className="text-right">Pending</th>
                                        <th className="text-right">Days</th>
                                      </tr>
                                      {(this.state.customer_pending_invoices[val] && this.state.customer_pending_invoices[val].length > 0) ?this.state.customer_pending_invoices[val].map( (value, index) => {
                                        if(value.type==='note'){
                                          customer_pending_note_total = customer_pending_note_total + value.pending_amount;
                                        }
                                      return (
                                            <>
                                            {(value.type==='note') &&
                                            <tr key={ index } className="contact-row">
                                              <td>{index+1}</td>
                                              <td>{value.created_date}</td>
                                              <td>
                                                <span className="no-print">
                                                {/* <DrawerLink target={value.target} url={value.url} name={value.invoice_number}  schema={value.schema}/> */}
                                                <Link link={value.url} name={value.invoice_number} target="_blank" />
                                                {(value.fin_year!==undefined) &&
                                                    <span className="fyinstrip">{value.fin_year}</span>
                                                  }
                                                </span>
                                                {(this.state.is_print) &&
                                                  <span className="print-block">{value.invoice_number}</span>
                                                }
                                              </td>
                                              {(this.state.contact_types[val]==='Supplier') &&
                                                <>
                                                  <td>
                                                    {(value.invoice_date)?value.invoice_date:'-'}
                                                  </td>
                                                  <td>
                                                    {(value.dispached_details)?value.dispached_details.supplier_ref_no:'-'}
                                                  </td>
                                                  </>
                                              }
                                              <td className="text-right">₹{this.currency_format(value.grand_total)}</td>
                                              <td className="text-right">₹{this.currency_format(value.total_paid)}</td>
                                              <td className="text-right">₹{this.currency_format(value.pending_amount)}</td>
                                              <td className="text-right">{value.age}</td>
                                            </tr>
                                            }
                                            </>
                                        )
                                    }) : <tr><td colSpan={7} className="text-center">{this.state.loading_process}</td></tr> }
                                     {(customer_pending_note_total===0) &&
                                      <tr>
                                        <td colSpan={(this.state.contact_types[val]==='Supplier')?9:7} style={{textAlign:'center'}}>
                                          No Record(s) Found
                                        </td>
                                      </tr>
                                    }
                                    {(customer_pending_note_total>0) &&
                                      <tr>
                                        <th colSpan="5" className="text-right">Note Pending Amount</th>
                                        {(this.state.contact_types[val]==='Supplier') &&
                                          <th colSpan={2}></th>
                                        }
                                        <th className="text-right">₹{this.currency_format(customer_pending_note_total)}</th>
                                        <th></th>
                                      </tr>
                                    }
                                      </>
                                    }
                                    {(this.state.show_pending_invoice_list_items.indexOf('payments')>=0) &&
                                      <>
                                        {(this.state.customer_pending_payments[val] && this.state.customer_pending_payments[val].length > 0) &&
                                        <>
                                        <tr>
                                          <th colSpan={(this.state.contact_types[val]==='Supplier')?9:7}>
                                            PENDING PAYMENTS
                                          </th>
                                        </tr>
                                        <tr>
                                          <th>S.No.</th>
                                          <th>Date</th>
                                          <th>Invoice</th>
                                          {(this.state.contact_types[val]==='Supplier') &&
                                            <>
                                              <th>Sup Bill Date</th>
                                              <th>Sup Ref No</th>
                                            </>
                                          }
                                          <th className="text-right">Invoice Value</th>
                                          <th className="text-right">Payment Made/Adjusted</th>
                                          <th className="text-right">Pending</th>
                                          <th className="text-right">Days</th>
                                        </tr>
                                        
                                        {(this.state.customer_pending_payments[val] && this.state.customer_pending_payments[val].length > 0) ?this.state.customer_pending_payments[val].map( (value, index) => {
                                          customer_pending_payment_total = customer_pending_payment_total + value.pending_amount;
                                        return (
                                              <tr key={ index } className="contact-row">
                                                <td>{index+1}</td>
                                                <td>{value.created_date}</td>
                                                <td>
                                                  <span className="no-print">
                                                  <DrawerLink target={value.target} url={value.url} name={value.invoice_number}  schema={value.schema}/>
                                                  {(value.fin_year!==undefined) &&
                                                      <span className="fyinstrip">{value.fin_year}</span>
                                                    }
                                                  </span>
                                                  {(this.state.is_print) &&
                                                    <span className="print-block">{value.invoice_number}</span>
                                                  }
                                                </td>
                                                {(this.state.contact_types[val]==='Supplier') &&
                                                  <>
                                                    <td>
                                                      {(value.invoice_date)?value.invoice_date:'-'}
                                                    </td>
                                                    <td>
                                                      {(value.dispached_details)?value.dispached_details.supplier_ref_no:'-'}
                                                    </td>
                                                    </>
                                                }
                                                <td className="text-right">₹{this.currency_format(value.grand_total)}</td>
                                                <td className="text-right">₹{this.currency_format(value.total_paid)}</td>
                                                <td className="text-right">₹{this.currency_format(value.pending_amount)}</td>
                                                <td className="text-right">{value.age}</td>
                                              </tr>
                                          )
                                      }) : <tr><td colSpan={7} className="text-center">{this.state.loading_process}</td></tr> }
                                        <tr>
                                          <th colSpan="5" className="text-right">Payment Pending Amount</th>
                                          {(this.state.contact_types[val]==='Supplier') &&
                                            <th colSpan={2}></th>
                                          }
                                          <th className="text-right">₹{this.currency_format(customer_pending_payment_total)}</th>
                                          <th></th>
                                        </tr>
                                        </>
                                        }
                                      </>
                                    }
                                  </tbody>
                                
                                </table>
                             )
                      }) : <div className="row" style={{padding:'20px'}}><div className="col-md-12 text-center">{this.state.loading_process}</div></div> }
                        </td>
                        </tr>
                      </table>
                     </div>
                     </div>
                  </div>
                </div>
              }
              {(this.state.active_tab==='customer-pending' && this.state.is_showing_pending_invoice===true && this.state.is_all_contact===true) &&
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content">
                    
                      <div>
                                <table className="table pending-invoice">
                                    <thead>
                                        <tr>
                                          <th>SNo.</th>
                                          <th>Contact Name</th>
                                          <th className="text-center">Unpaid Invoice</th>
                                          <th className="text-right">Pending Amount</th>
                                          
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {(this.state.contact_ids && this.state.contact_ids.length > 0) ?this.state.contact_ids.map( (val, ind) => {
                                    return (
                                      <>
                                              {(this.state.customer_pending_invoice_report[val] && this.state.customer_pending_invoice_report[val].length > 0) ?this.state.customer_pending_invoice_report[val].map( (value, index) => {
                                              return (
                                                    <tr key={ ind } className="contact-row">
                                                      <td>{ind+1}</td>
                                                      <td>
                                                      <Link link={'/view-contact/'+value.id} name={value.name} target="_blank" />
                                                      </td>
                                                      <td className="text-center">
                                                        {(value.pen_invoice===0) &&
                                                        <div className="spinner-border text-danger sm-loader" role="status">
                                                          <span className="sr-only">Loading...</span>
                                                        </div>  
                                                        }
                                                        {(value.pen_invoice>0) &&
                                                          <DrawerLink target="customer-pending" url="" schema="" name={value.pen_invoice} info={value} page_title={value.name+'\'s Pending Invoice(s)'}/>
                                                        }
                                                         {(value.pen_invoice>0) &&
                                                          <span className="fyinstrip">
                                                            <DrawerLink target="customer-pending" url="" schema="" name={"View Details"} info={value} page_title={value.name+'\'s Pending Invoice(s)'}/>
                                                          </span>
                                                        }
                                                      </td>
                                                      <td className="text-right">
                                                      {(value.pen_invoice===0) &&
                                                        <div className="spinner-border text-danger sm-loader" role="status">
                                                          <span className="sr-only">Loading...</span>
                                                        </div>  
                                                        }
                                                        {(value.pen_invoice>0) &&
                                                          <>
                                                            ₹{this.currency_format(value.pending_amount)}
                                                          </>
                                                        }
                                                      </td>
                                                    </tr>
                                                )
                                            }) : <tr><td colSpan={4} className="text-center">{this.state.loading_process}</td></tr> }
                                          
                                          
                                        </>
                                      )
                                    }) : <tr><td colSpan={5} className="text-center">{this.state.loading_process}</td></tr> }

                                    {/* {(this.state.contact_ids && this.state.contact_ids.length > 0 && this.state.is_show_more_pending_invoice===true) &&
                                      <tr><td colSpan={5} className="text-center">
                                        {(this.state.clicked_show_more_pi_btn===false) &&
                                        <button type="button" className="btn btn-outline-info" onClick={this.handleLoadMorePendingInvoice}>
                                        
                                          Load More
                                        
                                        </button>
                                        }
                                         {(this.state.clicked_show_more_pi_btn===true) &&
                                        <button type="button" className="btn btn-outline-info">
                                        
                                          Loading...
                                        
                                        </button>
                                        }
                                      </td></tr>
                                    } */}
                          </tbody>
                        </table>
                          
                     </div>
                     </div>
                  </div>
                </div>
              }
              {(this.state.active_tab==='sales-man') &&
                <div className="container-fluid">
                <div className="content-area">
                   <div className="content-filter">
                      <div className="row">
                        <div className="col-md-9">
                            <h4 style={{fontSize:'16px',marginBottom:'0px',fontWeight:'bold',color:'gray'}}>SALESMAN REPORT</h4>
                        </div>     
                        <div className="col-md-3">
                        {(this.state.sales_man_ids.length > 0) &&
                          <>
                           <button type="button" className="btn btn-success mrright-5 pull-right" onClick={this.handleInventoryPrint}>Print</button>
                           <ReactHTMLTableToExcel
                            id="donwload-transaction-xls-button"
                            className="btn btn-success mrright-5  pull-right"
                            table="salesman-report-xls"
                            filename="salesman-report"
                            sheet="salesman-report"
                            buttonText="Download as XLS"/>
                            </>
                        }
                          </div>               
                      </div>
                   </div>
                   <div className="main-content">
                   <div className="table-responsive"  id="report-print">
                      <table className="table"  id="salesman-report-xls">
                      <thead className="thead-light">
                        <tr>
                          {(this.state.is_staff_selected===false) &&
                            <th scope="col">STAFF</th>
                          }
                          <th scope="col">INVOICE NO #</th>
                          <th scope="col">DATE</th>
                          <th scope="col">AMOUNT</th>
                          <th scope="col">COMISSION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(this.state.sales_man_ids.length > 0) ?this.state.sales_man_ids.map( (value, index) => {
                          customers_info = this.state.sales_man_names[parseInt(value)];
                         return (
                          <>
                            {(this.state.is_staff_selected===false) &&
                              <tr key={ index }  className={"contact-row dr-cr-report dr-cr-report-"+value} data-id={value} onClick={this.handleToggleCrDrReport}>
                                <td colSpan={3}>
                                  <Link link={'/view-contact/'+value} name={customers_info.name} />
                                </td>
                                <td>
                                 <span>₹</span>{this.currency_format(customers_info.amount)}
                                </td>
                                <td>
                                  {'₹'+this.currency_format(customers_info.comission)}
                                  {/* <DrawerLink target="comission" url="" schema="" name={'₹'+this.currency_format(customers_info.comission)} info={customers_info.comission_data[parseInt(value)]} width="30%" page_title={customers_info.name+' Comission'}/>
 */}
                                  {(this.state.is_print===false) &&
                                  <span className="pull-right">
                                    <FontAwesomeIcon className={"expand-caret-"+value} icon={faCaretRight}/>
                                    <FontAwesomeIcon className={"hide expand-caret-"+value} icon={faCaretDown}/>
                                  </span>
                                  }
                                </td>
                              </tr>
                            }
                            
                            {this.state.sales_man_report[value].map((report,idx) => (
                              <tr  className={(this.state.is_staff_selected===false)?"contact-row child-row hide dr-cr-child-report dr-cr-child-report-"+value:"contact-row child-row dr-cr-child-report dr-cr-child-report-"+value} key={value}>
                                {(this.state.is_staff_selected===false) &&
                                  <td></td>
                                }
                                <td>
                                  <DrawerLink target="billing" url={'/view-billing/'+report.type+'/'+report.id} name={report.invoice_number} schema={report.schema}/>
                                </td>
                                <td>{report.created_on}</td>
                                <td><span>₹</span>{this.currency_format(report.grand_total)}</td>
                                <td>
                                  <DrawerLink target="comission" url="" schema="" name={'₹'+this.currency_format(report.comission)} info={report.comission_data} width="30%" page_title={report.invoice_number+' Comission'}/>
                                </td>
                              </tr>
                            ))}
                            </>
                        )
                       }) : <tr><td colSpan={(this.state.is_staff_selected===false)?5:4} className="text-center">{this.state.loading_process}</td></tr> }
                      </tbody>
                      {(this.state.contact_ids.length > 0) &&
                        <tfoot>
                          <tr>
                          {(this.state.is_staff_selected===false) &&
                            <th></th>
                          }
                            <th></th>
                            <th></th>
                            <th><span>₹</span>{this.state.sales_man_grand_total}</th>
                            <th><span>₹</span>{this.state.total_sm_comission}</th>
                          </tr>
                        </tfoot>
                      }
                    </table>
                    </div>
                   </div>
                </div>
              </div>
              }
              {(this.state.active_tab==='retained-customer') &&
                <div className="container-fluid">
                <div className="content-area">
                  {(this.state.retained_customer_graph!=='') &&
                   <div className="content-filter">
                      <div className="row">
                        <div className="col-md-6">
                          <h2 style={{marginBottom:'0px'}}>
                            <span style={{color:'#4f81bc'}}>{this.state.new_customer_percent}%</span>
                            <span style={{color:'gray'}}> | </span>
                            <span style={{color:'#c0504e'}}>{this.state.retained_customer_percent}%</span>
                          </h2>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3">
                            <select className="form-control" onChange={(e)=>this.handleChangeRCLoadType(e.target.value)}>
                              <option value={'all'}>All Time</option>
                              <option value={'today'}>Today</option>
                              <option value={'yesterday'}>Yesterday</option>
                              <option value={'thisweek'}>This Week</option>
                              <option value={'lastweek'}>Last Week</option>
                              <option value={'thismonth'}>This Month</option>
                              <option value={'lastmonth'}>Last Month</option>
                            </select>
                        </div>
                        <div className="col-md-12">
                          <hr />
                        </div>
                      </div>
                      <div className="row">
                       
                        <CanvasJSChart options = {this.state.retained_customer_graph}
				 onRef={ref => this.chart = ref}
			/> 
                        {(this.state.retained_customer_report.length > 0)  &&
                        <div className="col-md-3">
                           <ReactHTMLTableToExcel
                            id="donwload-transaction-xls-button"
                            className="btn btn-success mrright-5  pull-right"
                            table="product-report-report-xls"
                            filename="product-report-report"
                            sheet="product-report-report"
                            buttonText="Download as XLS"/>
                          </div>    
                        }           
                      </div>
                   </div>
                  }
                  {/*  <div className="main-content">
                   <div className="table-responsive"  id="report-print">
                      <table className="table"  id="product-report-report-xls">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">CUSTOMER</th>
                          <th scope="col" style={{textAlign:'center'}}>NO. OF VISITS</th>
                          <th scope="col" style={{textAlign:'right'}}>PURCHASED AMOUNT</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {(this.state.retained_customer_report.length > 0) ?this.state.retained_customer_report.map( (value, index) => {
                         return (
                         
                              <tr key={ index }  className={"contact-row"}>
                                <td>
                                  <Link link={'/view-contact/'+value.contact_id} name={value.name} target="_blank"/>
                                </td>
                                <td style={{textAlign:'center'}}>
                                  {value.next_visits}
                                </td>
                                <td style={{textAlign:'right'}}>
                                ₹{utils.currency_format(value.total_purchased)}
                                </td>
                              </tr>
                        )
                       }) : <tr><td colSpan={3} className="text-center">{this.state.loading_process}</td></tr> }
                      </tbody>
                      
                    </table>
                    </div>
                   </div> */}
                </div>
              </div>
              }
              {(this.state.active_tab==='product-report') &&
                <div className="container-fluid">
                <div className="content-area">
                   <div className="content-filter">
                      <div className="row">
                        <div className="col-md-9">
                            <h4 style={{fontSize:'16px',marginBottom:'0px',fontWeight:'bold',color:'gray'}}>PRODUCT REPORT</h4>
                        </div>
                        {(this.state.top_selling_product_report.length > 0)  &&
                        <div className="col-md-3">
                        
                          
                           
                           <ReactHTMLTableToExcel
                            id="donwload-transaction-xls-button"
                            className="btn btn-success mrright-5  pull-right"
                            table="product-report-report-xls"
                            filename="product-report-report"
                            sheet="product-report-report"
                            buttonText="Download as XLS"/>
                            
                        
                          </div>    
                        }           
                      </div>
                   </div>
                   <div className="main-content">
                   <div className="table-responsive"  id="report-print">
                      <table className="table"  id="product-report-report-xls">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">BARCODE</th>
                          <th scope="col">ITEM NAME</th>
                          <th scope="col" style={{textAlign:'right'}}>QUANTITY</th>
                          <th scope="col" style={{textAlign:'center'}}>REVENUE</th>
                          <th scope="col" style={{textAlign:'right'}}>NET PROFIT</th>
                          <th scope="col" style={{cursor:'pointer',textAlign:'right'}}  onClick={this.handleSortListOnMargin}>MARGIN <FontAwesomeIcon icon={faSort} className='sort-on-margin'/></th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {(this.state.top_selling_product_report.length > 0) ?this.state.top_selling_product_report.map( (value, index) => {
                         return (
                         
                              <tr key={ index }  className={"contact-row"}>
                                  <td>
                                  {value.barcode_name}
                                </td>
                                <td>
                                  <Link link={'/products/view/'+value.inventory_id} name={value.item_name} target="_blank"/>
                                </td>
                                <td style={{textAlign:'right'}}>
                                  {this.currency_format(value.totalquantity)} <span>{value.unit_name}</span>
                                </td>
                                <td style={{textAlign:'center'}}>
                                  {this.currency_format(value.revenue)}
                                </td>
                                <td style={{textAlign:'right'}}>
                            
                            {(value.net_profit!=='-')?this.currency_format(value.net_profit):'-'}
                          </td>
                                <td style={{textAlign:'right'}}>
                                  <span>{(value.margin!=='-')?value.margin+'%':'-'}</span>
                                </td>
                                
                              </tr>
                           
                        )
                       }) : <tr><td colSpan={6} className="text-center">{this.state.loading_process}</td></tr> }
                      </tbody>
                      <tfoot>
                        <th></th>
                        <th></th>
                        <th style={{textAlign:'right'}}>{(this.state.grand_quantity>0)?utils.currency_format(this.state.grand_quantity):''}</th>
                        <th style={{textAlign:'center'}}>
                          {(this.state.total_global_discount>0) &&
                          <>
                            {(this.state.grand_revenue>0)?utils.currency_format(this.state.grand_revenue):''}
                            {(this.state.total_global_discount>0)?' - '+utils.currency_format(this.state.total_global_discount):''}
                            {(this.state.total_selling_price>0)?' = '+utils.currency_format(this.state.total_selling_price):''}
                          </>
                          }
                          {(this.state.total_global_discount==0) &&
                            <>
                              {utils.currency_format(this.state.grand_revenue)}
                            </>
                          }
                        </th>
                        <th style={{textAlign:'right'}}>{(this.state.grand_profit>0)?utils.currency_format(this.state.grand_profit):''}</th>
                        <th style={{textAlign:'right'}}>{(this.state.average_margin>0)?utils.currency_format(this.state.average_margin)+'%':''}</th>
                      </tfoot>
                    </table>
                    </div>
                   </div>
                </div>
              </div>
              }

              {(this.state.active_tab==='reports' && (this.state.ledger_format==='format1' || this.state.ledger_format==='format2' || this.state.ledger_format==='format3') && this.state.reports_list)  &&
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="content-filter">
                        <div className="row">
                          <div className="col-md-12">
                              <h4 style={{fontSize:'16px',marginBottom:'0px',fontWeight:'bold',color:'gray'}}>DATEWISE LEDGER REPORT <span style={{fontSize:'16px',marginBottom:'0px',fontWeight:'bold',color:'gray',textTransform:'uppercase'}} id="ledger_contact_type"></span></h4>
                          </div>
                                                   
                        </div> 
                     </div>
                     <div className="main-content">
                     <div className="table-responsive" id="report-print">
                        <div className="text-center print-block">
                            <h4>{user_info.company_name}</h4>
                            <h5>Ledger Account</h5>
                            {(localStorage.getItem('is_bank_account')!=='true' && localStorage.getItem('is_bank_account')!=='payment_mode' && this.state.contact_info && this.state.contact_info.name!==undefined) &&
                            <div>
                              <p style={{fontSize:'14pt',lineHeight:'0.8'}}>{this.state.contact_info.name}</p>
                              {(this.state.contact_info.address_1!=="")&&
                                <p style={{fontSize:'12pt'}}>{this.state.contact_info.address_1}</p>
                              }
                              <p style={{fontSize:'12pt',lineHeight:'0.8'}}>
                                {this.state.contact_info.city} 
                                {(this.state.contact_info.state!=="" && this.state.contact_info.city!=="")?', ':''}
                                {(this.state.contact_info.state!=="")?this.state.contact_info.state.replace(/[-0-9]/g, ''):''}
                                {(this.state.contact_info.state!=="" && this.state.contact_info.pin!=="")?', ':''}
                                {(this.state.contact_info.pin!=="")?this.state.contact_info.pin:''}
                              </p>
                              <p style={{fontSize:'12pt',lineHeight:'0.8'}}>
                                {(this.state.contact_info.email!=="")?this.state.contact_info.email:''}

                                {(this.state.contact_info.email!=="" && this.state.contact_info.phone_1!=="")?' | ':''}

                                {(this.state.contact_info.phone_1!=="")?this.state.contact_info.phone_1:''}
                              </p>
                            </div>
                          }
                          <p style={{fontSize:'12pt',lineHeight:'0.8'}}>
                            {this.state.FromDate.getDate()+'/'+(this.state.FromDate.getMonth()+1)+'/'+this.state.FromDate.getFullYear()} - {this.state.ToDate.getDate()+'/'+(this.state.ToDate.getMonth()+1)+'/'+this.state.ToDate.getFullYear()}
                          </p>
                        </div>
                        <table className="table ledger-table" id="download-format1-report">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">DATE</th>
                            {(this.state.is_bank_account===true) &&
                              <th scope="col">PARTY</th>
                            }
                            <th scope="col">INVOICE #</th>
                            <th scope="col">CHEQUE NO</th>
                            {(this.state.is_bank_account===false) &&
                              <th scope="col">TYPE</th>
                            }
                            <th scope="col">REFERENCE</th>
                            <th scope="col">SUP'S REF NO.</th>
                            <th scope="col">DEBIT(-)</th>
                            <th scope="col">CREDIT(+)</th>
                            
                            <th scope="col">BALANCE</th>
                            <th scope="col">REMARKS</th>
                            {(this.state.is_bank_account===true) &&
                              <th scope="col">TYPE</th>
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.reports_list && this.state.reports_list.sp_report_data && this.state.reports_list.sp_report_data.report_data && this.state.reports_list.sp_report_data.report_data.length > 0) ?this.state.reports_list.sp_report_data.report_data.map( (value, index) => {
                          
                           return (
                            <tr key={ index } className="contact-row">
                              <td>{value.created_on}</td>
                              {(this.state.is_bank_account===true) &&
                                <td>
                                  {(value.contact_name!==undefined)?value.contact_name:'-'}
                                </td>
                              }
                              <td>
                                <span className="no-print">
                                  {(value.target==='payments') &&
                                   <DrawerLink target="payment" url={value.view_url} name={value.invoice_number}  schema={value.schema}/>
                                  }
                                  {(value.target==='expenses' || value.target==='cr_dr_note') &&
                                   <a href={value.view_url} target="_blank" style={{color:'#007bff'}}>{value.invoice_number}</a>
                                  }
                                  {(value.target!=='payments' && value.target!=='expenses' && value.target!=='cheque' && value.target!=='cr_dr_note' && value.target!=='cash_entry') &&
                                    <DrawerLink target="billing" url={'/'+value.view_type+'/'+value.target+'/'+value.id} name={value.invoice_number} schema={value.schema}/>
                                  }
                                  {(value.target==='cash_entry' || value.target==='cash_entry-' || value.target==='cash_entry+') &&
                                      <DrawerLink target="cash_entry" url={value.view_url} schema={value.schema} name={value.invoice_number} info={value.bill_info} page_title={value.invoice_number+' Info'}/>
                                    }
                                  {(value.target==='cheque') &&
                                  <>
                                     <DrawerLink target="cheque" url={''} name={value.invoice_number} info={value.info} width="85%"/>
                                    {(value.info && value.info.invoice_type) &&
                                      <span style={{paddingLeft: '6px',fontSize: '12px',color: 'gray'}}>
                                        (
                                        {(value.info.invoice_type==='sale' || value.info.invoice_type==='sales' || value.info.invoice_type==='IN')?'IN':'OUT'}
                                        )
                                      </span>
                                    }
                                  </>
                                  }
                                </span>
                                {(this.state.is_print) &&
                                <span className="print-block">
                                    {value.invoice_number}
                                </span>
                                }
                              </td>
                              <td style={{textTransform:'capitalize'}}>{(value.cheque_no)?value.cheque_no:''}</td>
                              {(this.state.is_bank_account===false) &&
                              <td style={{textTransform:'capitalize'}}>{value.target_label}</td>
                              }
                              <td style={{whiteSpace:'unset'}}>
                                <p style={{margin:'0px',inlineSize:'150px'}}>{(value.refrence_no!=='')?value.refrence_no:'-'}</p>
                              </td>
                              <td>
                                {(value.dispatched_info && value.dispatched_info.supplier_ref_no!=='')?value.dispatched_info.supplier_ref_no:'-'}
                              </td>
                              <td>
                              {(value.debit>0) &&
                                <>
                                  <span>₹</span>{this.currency_format(value.debit)}
                                </>
                              }
                              {(value.debit===0) &&
                                <>
                                -
                                </>
                              }
                              </td>
                               <td>
                              {(value.credit>0) &&
                              <>
                                <span>₹</span>{this.currency_format(value.credit)}
                              </>
                              }
                              {(value.credit===0) &&
                                <>
                                -
                                </>
                              }
                              
                              </td>
                              <td><span>₹</span>{this.currency_format(value.balance)}</td>
                              <td>
                                  {(value.notes!=='') &&
                                    <p className="led-remark">{value.notes}</p>
                                  }
                                  {(value.notes==='') &&
                                    <p className="led-remark">-</p>
                                  }
                              </td>
                              {(this.state.is_bank_account===true) &&
                              <td style={{textTransform:'capitalize'}}>{value.target_label}</td>
                              }
                            </tr>
                          )
                         }) : <tr><td colSpan={10} className="text-center">{this.state.loading_process}</td></tr> }
                        </tbody>
                        {(this.state.reports_list && this.state.reports_list.sp_report_data && this.state.reports_list.sp_report_data.report_data && this.state.reports_list.sp_report_data.report_data.length > 0) &&
                        <>
                        <tfoot>
                          <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th><span>₹</span>{this.currency_format(this.state.reports_list.sp_report_data.total_debit)}</th>
                            <th><span>₹</span>{this.currency_format(this.state.reports_list.sp_report_data.total_credit)}</th>
                            
                            <th><span>₹</span>{this.currency_format(this.state.reports_list.sp_report_data.total_balance)}{this.state.reports_list.sp_report_data.msg2}</th>
                            <th></th>
                            {(this.state.is_bank_account===true) &&
                              <th></th>
                              }
                          </tr>
                      {/*
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <th>By Closing balance</th>
                            <td>
                            {(this.state.reports_list.sp_report_data.closing_debit>0) &&
                              <>
                              <span>₹</span>{this.currency_format(this.state.reports_list.sp_report_data.closing_debit)}
                              </>
                            }
                            </td>
                            <td>
                            {(this.state.reports_list.sp_report_data.closing_credit>0) &&
                              <>
                              <span>₹</span>{this.currency_format(this.state.reports_list.sp_report_data.closing_credit)}
                               </>
                            }
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th><span>₹</span>{this.currency_format(this.state.reports_list.sp_report_data.total_closing_debit)}</th>
                            <th><span>₹</span>{this.currency_format(this.state.reports_list.sp_report_data.total_closing_credit)}</th>
                            <th></th>
                            <th></th>
                          </tr>
                        */}
                        </tfoot>
                        </>
                      }
                      </table>
                      </div>
                      {(this.state.reports_list && this.state.reports_list.sp_report_data && this.state.reports_list.sp_report_data.report_data && this.state.reports_list.sp_report_data.report_data.length > 0) &&
                      <div className="content-head border-top" style={{paddingLeft:'5px'}}>
                        
                        <div className="row">
                        <div className="col-md-6">
                          <p style={{color:'#8e8e8e'}}>Report Generated : {this.state.report_time} | {this.state.report_date}</p>
                          </div>
                          <div className={(this.isMobile()===false)?"col-md-6 text-right":"col-md-6 text-center"} style={{marginTop:'-5px'}}>
                             <button type="button" className="btn btn-success mrright-5" onClick={this.handleInventoryPrint}>Print</button>
                             <ReactHTMLTableToExcel
                              id="donwload-inventory-xls-button"
                              className="btn btn-success mrright-5"
                              table="download-format1-report"
                              filename="ledger_format1_report"
                              sheet="ledger_format1_report"
                              buttonText="Download as XLS"/>
                             <PDFDownloadLink document={<ReportPDFFormat1 report_data={this.state.reports_list.sp_report_data.report_data} pdf_report={this.state.reports_list.sp_report_data.pdf_report} total_pdf_balance={this.state.reports_list.sp_report_data.total_pdf_balance} total_pdf_debit={this.state.reports_list.sp_report_data.total_pdf_debit} total_pdf_credit={this.state.reports_list.sp_report_data.total_pdf_credit} msg1={this.state.reports_list.sp_report_data.msg1} contact_info={this.state.contact_info} ledger_from_date={this.state.ledger_from_date} ledger_to_date={this.state.ledger_to_date} total_balance={this.state.reports_list.sp_report_data.total_balance} closing_debit={this.state.reports_list.sp_report_data.closing_debit} closing_credit={this.state.reports_list.sp_report_data.closing_credit} total_closing_debit={this.state.reports_list.sp_report_data.total_closing_debit} total_closing_credit={this.state.reports_list.sp_report_data.total_closing_credit}/>} fileName="report.pdf">
                                {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <button type="button" className="btn btn-success">Download PDF</button>)}
                              </PDFDownloadLink>
                          </div>

                        </div>
                      </div>
                      }
                     </div>
                  </div>
                </div>
              }

                {(this.state.active_tab==='reports'  && this.state.ledger_format!=='format1' && this.state.ledger_format!=='format2' && this.state.ledger_format!=='format3' && this.state.reports_list && this.state.reports_list.sp_report_data && this.state.reports_list.sp_report_data.report_data && this.state.reports_list.sp_report_data.report_data.length > 0)  &&
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="content-filter">
                        <div className="row">
                          <div className="col-md-12">
                              <h4 style={{fontSize:'16px',marginBottom:'0px',fontWeight:'bold',color:'gray'}}>SALES & PURCHASE REPORT</h4>
                          </div>
                                                   
                        </div>
                     </div>
                     <div className="main-content">
                     <div className="table-responsive">
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">INVOICE NO #</th>
                            <th scope="col">DATE</th>
                            <th scope="col">DEBIT(-)</th>
                            <th scope="col">CREDIT(+)</th>
                            <th scope="col">BALANCE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.reports_list && this.state.reports_list.sp_report_data && this.state.reports_list.sp_report_data.report_data && this.state.reports_list.sp_report_data.report_data.length > 0) ?this.state.reports_list.sp_report_data.report_data.map( (value, index) => {
                          
                           return (
                            <tr key={ index } className="contact-row">
                              <td>
                                {(value.target==='payments') &&
                                  <DrawerLink target="payment" url={'/view-payment/'+value.id} name={value.invoice_number} schema={value.schema}/>
                                
                                }
                                {(value.target!=='payments') &&
                                <DrawerLink target="billing" url={'/view-billing/'+value.target+'/'+value.id} name={value.invoice_number} schema={value.schema}/>

                                
                                }
                                
                              </td>
                              <td>{value.created_on}</td>
                              <td>
                              {(value.debit>0) &&
                                <>
                                  <span>₹</span>{this.currency_format(value.debit)}
                                </>
                              }
                              {(value.debit===0) &&
                                <>
                                -
                                </>
                              }
                              </td>
                              <td>
                              {(value.credit>0) &&
                              <>
                                <span>₹</span>{this.currency_format(value.credit)}
                              </>
                              }
                              {(value.credit===0) &&
                                <>
                                -
                                </>
                              }
                              
                              </td>  
                              
                              <td><span>₹</span>{this.currency_format(value.balance)}</td>
                            </tr>
                          )
                         }) : <tr><td colSpan={5} className="text-center">{this.state.loading_process}</td></tr> }
                        </tbody>
                        {(this.state.reports_list && this.state.reports_list.sp_report_data && this.state.reports_list.sp_report_data.report_data && this.state.reports_list.sp_report_data.report_data.length > 0) &&
                        <tfoot>
                          <tr>
                            <th></th>
                            <th></th>
                            <th><span>₹</span>{this.currency_format(this.state.reports_list.sp_report_data.total_debit)}(Dr)</th>
                            <th><span>₹</span>{this.currency_format(this.state.reports_list.sp_report_data.total_credit)}(Cr)</th>
                            <th><span>₹</span>{this.currency_format(this.state.reports_list.sp_report_data.total_balance)}{this.state.reports_list.sp_report_data.msg2}</th>
                          </tr>
                        </tfoot>
                      }
                      </table>
                      </div>
                      {(this.state.reports_list && this.state.reports_list.sp_report_data && this.state.reports_list.sp_report_data.report_data && this.state.reports_list.sp_report_data.report_data.length > 0) &&
                      <div className="content-head border-top" style={{paddingLeft:'5px'}}>
                        <div className="row">
                          <div className="col-md-6">
                          <p style={{color:'#8e8e8e'}}>Report Generated : {this.state.report_time} | {this.state.report_date}</p>
                          </div>
                          <div className="col-md-6 text-right">
                            <p>{this.state.reports_list.sp_report_data.msg1} <span>₹</span>{this.currency_format(this.state.reports_list.sp_report_data.total_balance)}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 text-right" style={{marginBottom:'10px',marginLeft:'3px'}}>
                             <PDFDownloadLink document={<ReportPDF pdf_report={this.state.reports_list.sp_report_data.pdf_report} total_pdf_balance={this.state.reports_list.sp_report_data.total_pdf_balance} total_pdf_debit={this.state.reports_list.sp_report_data.total_pdf_debit} total_pdf_credit={this.state.reports_list.sp_report_data.total_pdf_credit} msg3={this.state.reports_list.sp_report_data.msg3} msg4={this.state.reports_list.sp_report_data.msg4} contact_info={this.state.contact_info}/>} fileName="report.pdf">
                                {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <button type="button" className="btn btn-success">Download PDF</button>)}
                              </PDFDownloadLink>
                          </div>

                        </div>
                      </div>
                      }
                     </div>
                  </div>
                </div>
              }
              {(this.state.active_tab==='reports'  && this.state.ledger_format!=='format1' && this.state.ledger_format!=='format2' && this.state.ledger_format!=='format3' && this.state.reports_list && this.state.reports_list.sr_pr_report_data && this.state.reports_list.sr_pr_report_data.report_data && this.state.reports_list.sr_pr_report_data.report_data.length > 0) &&
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="content-filter">
                        <div className="row">
                          <div className="col-md-12">
                              <h4 style={{fontSize:'16px',marginBottom:'0px',fontWeight:'bold',color:'gray'}}>SALES RETURN & PURCHASE RETURN REPORT</h4>
                          </div>
                                                   
                        </div>
                     </div>
                     <div className="main-content">
                     <div className="table-responsive">
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">INVOICE NO #</th>
                            <th scope="col">DATE</th>
                            <th scope="col">DEBIT(-)</th>
                            <th scope="col">CREDIT(+)</th>
                            <th scope="col">BALANCE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.reports_list && this.state.reports_list.sr_pr_report_data && this.state.reports_list.sr_pr_report_data.report_data && this.state.reports_list.sr_pr_report_data.report_data.length > 0) ?this.state.reports_list.sr_pr_report_data.report_data.map( (value, index) => {
                          
                           return (
                            <tr key={ index } className="contact-row">
                              <td>
                                {(value.target==='payments') &&
                                  <DrawerLink target="payment" url={'/view-payment/'+value.id} name={value.invoice_number} schema={value.schema}/>

                                
                                }
                                {(value.target!=='payments') &&
                                <DrawerLink target="billing" url={'/view-billing/'+value.target+'/'+value.id} name={value.invoice_number} schema={value.schema}/>

                                
                                }
                                
                              </td>
                              <td>{value.created_on}</td>
                              <td>
                              {(value.debit>0) &&
                                <>
                                  <span>₹</span>{this.currency_format(value.debit)}
                                </>
                              }
                              {(value.debit===0) &&
                                <>
                                -
                                </>
                              }
                              </td>
                              <td>
                              {(value.credit>0) &&
                              <>
                                <span>₹</span>{this.currency_format(value.credit)}
                              </>
                              }
                              {(value.credit===0) &&
                                <>
                                -
                                </>
                              }
                              
                              </td>
                              <td><span>₹</span>{this.currency_format(value.balance)}</td>
                            </tr>
                          )
                         }) : <tr><td colSpan={5} className="text-center">{this.state.loading_process}</td></tr> }
                        </tbody>
                        {(this.state.reports_list && this.state.reports_list.sr_pr_report_data && this.state.reports_list.sr_pr_report_data.report_data && this.state.reports_list.sr_pr_report_data.report_data.length > 0) &&
                        <tfoot>
                          <tr>
                            <th></th>
                            <th></th>
                            <th><span>₹</span>{this.currency_format(this.state.reports_list.sr_pr_report_data.total_debit)}(Dr)</th>
                            <th><span>₹</span>{this.currency_format(this.state.reports_list.sr_pr_report_data.total_credit)}(Cr)</th>
                            <th><span>₹</span>{this.currency_format(this.state.reports_list.sr_pr_report_data.total_balance)}{this.state.reports_list.sr_pr_report_data.msg2}</th>
                          </tr>
                        </tfoot>
                      }
                      </table>
                      </div>
                      {(this.state.reports_list && this.state.reports_list.sr_pr_report_data && this.state.reports_list.sr_pr_report_data.report_data && this.state.reports_list.sr_pr_report_data.report_data.length > 0) &&
                      <div className="content-head border-top" style={{paddingLeft:'5px'}}>
                        <div className="row">
                          <div className="col-md-6">
                          <p style={{color:'#8e8e8e'}}>Report Generated : {this.state.report_time} | {this.state.report_date}</p>
                          </div>
                          <div className="col-md-6 text-right">
                            <p>{this.state.reports_list.sr_pr_report_data.msg1} <span>₹</span>{this.currency_format(this.state.reports_list.sr_pr_report_data.total_balance)}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 text-right"  style={{marginBottom:'10px',marginLeft:'3px'}}>
                             <PDFDownloadLink document={<ReportPDF pdf_report={this.state.reports_list.sr_pr_report_data.pdf_report} total_pdf_balance={this.state.reports_list.sr_pr_report_data.total_pdf_balance} total_pdf_debit={this.state.reports_list.sr_pr_report_data.total_pdf_debit} total_pdf_credit={this.state.reports_list.sr_pr_report_data.total_pdf_credit} msg3={this.state.reports_list.sr_pr_report_data.msg3} msg4={this.state.reports_list.sr_pr_report_data.msg4} contact_info={this.state.contact_info}/>} fileName="report.pdf">
                                {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <button type="button" className="btn btn-success">Download PDF</button>)}
                              </PDFDownloadLink>
                          </div>

                        </div>
                      </div>
                      }
                     </div>
                  </div>
                </div>
              }
              {(this.state.active_tab==='reports'  && this.state.ledger_format!=='format1' && this.state.ledger_format!=='format2' && this.state.ledger_format!=='format3'  && this.state.reports_list && this.state.reports_list.pay_report_data && this.state.reports_list.pay_report_data.report_data && this.state.reports_list.pay_report_data.report_data.length > 0) &&
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="content-filter">
                        <div className="row">
                          <div className="col-md-12">
                              <h4 style={{fontSize:'16px',marginBottom:'0px',fontWeight:'bold',color:'gray'}}>PAYMENTS REPORT</h4>
                          </div>
                                                   
                        </div>
                     </div>
                     <div className="main-content">
                     <div className="table-responsive">
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">INVOICE NO #</th>
                            <th scope="col">DATE</th>
                            <th scope="col">DEBIT(-)</th>
                            <th scope="col">CREDIT(+)</th>
                            <th scope="col">BALANCE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.reports_list && this.state.reports_list.pay_report_data && this.state.reports_list.pay_report_data.report_data && this.state.reports_list.pay_report_data.report_data.length > 0) ?this.state.reports_list.pay_report_data.report_data.map( (value, index) => {
                          
                           return (
                            <tr key={ index } className="contact-row">
                              <td>
                                {(value.target==='payments') &&
                                <DrawerLink target="payment" url={'/view-payment/'+value.id} name={value.invoice_number} schema={value.schema}/>
                                
                                }
                                {(value.target!=='payments') &&
                                <DrawerLink target="billing" url={'/view-billing/'+value.target+'/'+value.id} name={value.invoice_number} schema={value.schema}/>
                                }
                                
                              </td>
                              <td>{value.created_on}</td>
                              <td>
                              {(value.debit>0) &&
                                <>
                                  <span>₹</span>{this.currency_format(value.debit)}
                                </>
                              }
                              {(value.debit===0) &&
                                <>
                                -
                                </>
                              }
                              </td>
                              <td>
                              {(value.credit>0) &&
                              <>
                                <span>₹</span>{this.currency_format(value.credit)}
                              </>
                              }
                              {(value.credit===0) &&
                                <>
                                -
                                </>
                              }
                              
                              </td> 
                              <td><span>₹</span>{this.currency_format(value.balance)}</td>
                            </tr>
                          )
                         }) : <tr><td colSpan={5} className="text-center">{this.state.loading_process}</td></tr> }
                        </tbody>
                        {(this.state.reports_list && this.state.reports_list.pay_report_data && this.state.reports_list.pay_report_data.report_data && this.state.reports_list.pay_report_data.report_data.length > 0) &&
                        <tfoot>
                          <tr>
                            <th></th>
                            <th></th>
                            <th><span>₹</span>{this.currency_format(this.state.reports_list.pay_report_data.total_debit)}(Dr)</th>
                            <th><span>₹</span>{this.currency_format(this.state.reports_list.pay_report_data.total_credit)}(Cr)</th>
                            <th><span>₹</span>{this.currency_format(this.state.reports_list.pay_report_data.total_balance)}{this.state.reports_list.pay_report_data.msg2}</th>
                          </tr>
                        </tfoot>
                      }
                      </table>
                      </div>
                      {(this.state.reports_list && this.state.reports_list.pay_report_data && this.state.reports_list.pay_report_data.report_data && this.state.reports_list.pay_report_data.report_data.length > 0) &&
                      <div className="content-head border-top" style={{paddingLeft:'5px'}}>
                        <div className="row">
                          <div className="col-md-6">
                          <p style={{color:'#8e8e8e'}}>Report Generated : {this.state.report_time} | {this.state.report_date}</p>
                          </div>
                          <div className="col-md-6 text-right">
                            <p>{this.state.reports_list.pay_report_data.msg1} <span>₹</span>{this.currency_format(this.state.reports_list.pay_report_data.total_balance)}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 text-right"  style={{marginBottom:'10px',marginLeft:'3px'}}>
                             <PDFDownloadLink document={<ReportPDF pdf_report={this.state.reports_list.pay_report_data.pdf_report} total_pdf_balance={this.state.reports_list.pay_report_data.total_pdf_balance} total_pdf_debit={this.state.reports_list.pay_report_data.total_pdf_debit} total_pdf_credit={this.state.reports_list.pay_report_data.total_pdf_credit} msg3={this.state.reports_list.pay_report_data.msg3} msg4={this.state.reports_list.pay_report_data.msg4} contact_info={this.state.contact_info}/>} fileName="report.pdf">
                                {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <button type="button" className="btn btn-success">Download PDF</button>)}
                              </PDFDownloadLink>
                          </div>

                        </div>
                      </div>
                      }
                     </div>
                  </div>
                </div>
              }
              </div>

            </div>
        );
    }
}

class TaxList extends Component {
  constructor(props) {
      super(props)
      this.state = {
          
      }
     
  }

  render() {
    let b2b_inv_total = 0;
        let b2b_cgst_total = 0;
        let b2b_sgst_total = 0;
        let b2b_igst_total = 0;
        let b2b_taxable_total = 0;

        let b2c_inv_total = 0;
        let b2c_cgst_total = 0;
        let b2c_sgst_total = 0;
        let b2c_igst_total = 0;
        let b2c_taxable_total = 0;

        let cdnr_inv_total = 0;
        let cdnr_cgst_total = 0;
        let cdnr_sgst_total = 0;
        let cdnr_igst_total = 0;
        let cdnr_taxable_total = 0;

        let hsn_quan_total = 0;
        let hsn_cgst_total = 0;
        let hsn_sgst_total = 0;
        let hsn_igst_total = 0;
        let hsn_taxable_total = 0;
        let hsn_item_total = 0;
        let hsn_total = 0;
        let total_global_disc = 0;
        let total_round_of = 0;
    return  <table className="table" id={this.props.id}>
              {(this.props.target==='b2b') &&
                <>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">GSTIN/UIN OF RECIPIENT</th>
                      <th scope="col">RECEIVER NAME</th>
                      <th scope="col">INVOICE NUMBER</th>
                      <th scope="col">IRN</th>
                      <th scope="col">INVOICE DATE</th>
                      <th scope="col">PLACE OF SUPPLY</th>
                      <th scope="col">REVERSE CHARGE</th>
                      {/* <th scope="col">APPLICABLE % OF TAX RATE</th> */}
                      <th scope="col">INVOICE TYPE</th>
                      {/* <th scope="col">E-COMMERCE GSTIN</th> */}
                      <th scope="col">TAX RATE %</th>
                      <th scope="col">TAXABLE AMOUNT</th>
                      <th scope="col">CGST</th>
                      <th scope="col">SGST</th>
                      <th scope="col">IGST</th>
                      <th scope="col">ROUND OFF</th>
                      <th scope="col">INVOICE VALUE</th>
                      {/* <th scope="col">GLOBAL DISCOUNT</th> */}
                      {/* <th scope="col">CESS AMOUNT</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {(this.props.data.tax_report && this.props.data.tax_report.b2b && this.props.data.tax_report.b2b.length > 0) ?this.props.data.tax_report.b2b.map( (value, index) => {
                      b2b_inv_total = b2b_inv_total+parseFloat(value.invoice_value);
                      b2b_cgst_total = b2b_cgst_total+parseFloat(value.cgst);
                      b2b_sgst_total = b2b_sgst_total+parseFloat(value.sgst);
                      b2b_igst_total = b2b_igst_total+parseFloat(value.igst);
                      b2b_taxable_total = b2b_taxable_total+parseFloat(value.taxable_amount);
                      total_round_of = total_round_of+parseFloat(value.round_off);
                    return (
                      <tr key={ index } className="contact-row">
                        <td>{value.gstin}</td>
                        <td>{value.name}</td>
                        <td>
                          <>
                            <DrawerLink target="billing" url={value.url} name={value.invoice_number}  schema={value.schema}/>
                            
                          </>
                        </td>
                        <td>
                        {(value.e_invoice_info && value.e_invoice_info.data && value.e_invoice_info.data.qr && value.e_invoice_info.data.qr.Irn) &&
                            <p style={{margin:'0px',color:'gray'}}>{value.e_invoice_info.data.qr.Irn}</p>
                          }  
                        </td>
                        <td>{value.date}</td>
                        <td>{value.place_of_supply}</td>
                        <td>{value.reverse_charge}</td>
                      {/*  <td>{value.applicable_per_of_tax_rate}</td> */}
                        <td>{value.invoice_type}</td>
                        <td>{value.rate}</td>
                        <td>₹{utils.currency_format(value.taxable_amount)}</td>
                        <td>{value.cgst}</td>
                        <td>{value.sgst}</td>
                        <td>{value.igst}</td>
                        <td>{value.round_off}</td>
                        <td>₹{utils.currency_format(value.invoice_value)}</td>
                        {/* <td>{value.global_discount}</td> */}
                      </tr>
                    )
                  }) : <tr><td colSpan={15} className="text-center">
                      {(this.props.data.tax_report && this.props.data.tax_report.b2b && this.props.data.tax_report.b2b.length === 0) ? 'Opps! No Record(s) Found.':'Loading...'}
                    </td></tr> }
                  {(this.props.data.tax_report && this.props.data.tax_report.b2b && this.props.data.tax_report.b2b.length > 0) &&
                      <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>₹{utils.currency_format(b2b_taxable_total)}</td>
                          <td>{utils.currency_format(b2b_cgst_total)}</td>
                          <td>{utils.currency_format(b2b_sgst_total)}</td>
                          <td>{utils.currency_format(b2b_igst_total)}</td>
                          <td>{utils.currency_format(total_round_of)}</td>
                          <td>₹{utils.currency_format(b2b_inv_total)}</td>
                          {/* <td></td> */}
                      </tr>
                    }
                  </tbody>
                </>
              }
              {(this.props.target==='b2c') &&
                <>
                  <thead className="thead-light">
                    <tr>
                      
                      <th scope="col">RECEIVER NAME</th>
                      <th scope="col">INVOICE NUMBER</th>
                      <th scope="col">INVOICE DATE</th>
                      <th scope="col">PLACE OF SUPPLY</th>
                      <th scope="col">REVERSE CHARGE</th>
                      {/* <th scope="col">APPLICABLE % OF TAX RATE</th> */}
                      <th scope="col">INVOICE TYPE</th>
                      {/* <th scope="col">E-COMMERCE GSTIN</th> */}
                      <th scope="col">TAX RATE %</th>
                      <th scope="col">TAXABLE AMOUNT</th>
                      <th scope="col">CGST</th>
                      <th scope="col">SGST</th>
                      <th scope="col">IGST</th>
                      <th scope="col">ROUND OFF</th>
                      <th scope="col">INVOICE VALUE</th>
                      {/* <th scope="col">GLOBAL DISCOUNT</th> */}
                      {/* <th scope="col">CESS AMOUNT</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {(this.props.data.tax_report && this.props.data.tax_report.b2c && this.props.data.tax_report.b2c.length > 0) ?this.props.data.tax_report.b2c.map( (value, index) => {
                      b2c_inv_total = b2c_inv_total+parseFloat(value.invoice_value);
                      b2c_cgst_total = b2c_cgst_total+parseFloat(value.cgst);
                      b2c_sgst_total = b2c_sgst_total+parseFloat(value.sgst);
                      b2c_igst_total = b2c_igst_total+parseFloat(value.igst);
                      b2c_taxable_total = b2c_taxable_total+parseFloat(value.taxable_amount);
                      total_round_of = total_round_of+parseFloat(value.round_off);
                    return (
                      <tr key={ index } className="contact-row">
                        <td>{value.name}</td>
                        <td>
                          <>
                            <DrawerLink target="billing" url={value.url} name={value.invoice_number}  schema={value.schema}/>
                          </>
                          </td>
                        <td>{value.date}</td>
                        <td>{value.place_of_supply}</td>
                        <td>{value.reverse_charge}</td>
                      {/*  <td>{value.applicable_per_of_tax_rate}</td> */}
                        <td>{value.invoice_type}</td>
                        <td>{value.rate}</td>
                        <td>₹{utils.currency_format(value.taxable_amount)}</td>
                        <td>{value.cgst}</td>
                        <td>{value.sgst}</td>
                        <td>{value.igst}</td>
                        <td>{value.round_off}</td>
                        <td>₹{utils.currency_format(value.invoice_value)}</td>
                        {/* <td>{value.global_discount}</td> */}
                      </tr>
                    )
                  }) : <tr><td colSpan={14} className="text-center">
                      {(this.props.data.tax_report && this.props.data.tax_report.b2c && this.props.data.tax_report.b2c.length === 0) ? 'Opps! No Record(s) Found.':'Loading...'}
                    </td></tr> }
                  {(this.props.data.tax_report && this.props.data.tax_report.b2c && this.props.data.tax_report.b2c.length > 0) &&
                      <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>₹{utils.currency_format(b2c_taxable_total)}</td>
                          <td>{utils.currency_format(b2c_cgst_total)}</td>
                          <td>{utils.currency_format(b2c_sgst_total)}</td>
                          <td>{utils.currency_format(b2c_igst_total)}</td>
                          <td>{utils.currency_format(total_round_of)}</td>
                          {/* <td></td> */}
                          <td>₹{utils.currency_format(b2c_inv_total)}</td>
                      </tr>
                    }
                  </tbody>
                </>
              }
              {(this.props.target==='cdnr') &&
                <>
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">GSTIN/UIN OF RECIPIENT</th>
                        <th scope="col">RECEIVER NAME</th>
                        <th scope="col">INVOICE NUMBER</th>
                        <th scope="col">IRN</th>
                        <th scope="col">INVOICE DATE</th>
                        <th scope="col">PLACE OF SUPPLY</th>
                        <th scope="col">REVERSE CHARGE</th>
                        {/* <th scope="col">APPLICABLE % OF TAX RATE</th> */}
                        <th scope="col">INVOICE TYPE</th>
                        {/* <th scope="col">E-COMMERCE GSTIN</th> */}
                        <th scope="col">TAX RATE %</th>
                        <th scope="col">TAXABLE AMOUNT</th>
                        <th scope="col">CGST</th>
                        <th scope="col">SGST</th>
                        <th scope="col">IGST</th>
                        <th scope="col">ROUND OFF</th>
                      {/* <th scope="col">GLOBAL DISCOUNT</th> */}
                        <th scope="col">INVOICE VALUE</th>
                        {/* <th scope="col">CESS AMOUNT</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {(this.props.data.tax_report && this.props.data.tax_report.cdnr && this.props.data.tax_report.cdnr.length > 0) ?this.props.data.tax_report.cdnr.map( (value, index) => {
                        cdnr_inv_total = cdnr_inv_total+parseFloat(value.invoice_value);
                        cdnr_cgst_total = cdnr_cgst_total+parseFloat(value.cgst);
                        cdnr_sgst_total = cdnr_sgst_total+parseFloat(value.sgst);
                        cdnr_igst_total = cdnr_igst_total+parseFloat(value.igst);
                        cdnr_taxable_total = cdnr_taxable_total+parseFloat(value.taxable_amount);
                        total_round_of = total_round_of+parseFloat(value.round_off);
                      return (
                        <tr key={ index } className="contact-row">
                          <td>{value.gstin}</td>
                          <td>{value.name}</td>
                          <td>
                            <>
                              <DrawerLink target="billing" url={value.url} name={value.invoice_number}  schema={value.schema}/>
                             
                            </>
                            </td>
                          <td> {(value.e_invoice_info && value.e_invoice_info.data && value.e_invoice_info.data.qr && value.e_invoice_info.data.qr.Irn) &&
                                <p style={{margin:'0px',color:'gray'}}>{value.e_invoice_info.data.qr.Irn}</p>
                              }</td>
                          <td>{value.date}</td>
                          <td>{value.place_of_supply}</td>
                          <td>{value.reverse_charge}</td>
                        {/*  <td>{value.applicable_per_of_tax_rate}</td> */}
                          <td>{value.invoice_type}</td>
                          <td>{value.rate}</td>
                          <td>₹{utils.currency_format(value.taxable_amount)}</td>
                          <td>{value.cgst}</td>
                          <td>{value.sgst}</td>
                          <td>{value.igst}</td>
                          <td>{value.round_off}</td>
                          {/* <td>{value.global_discount}</td> */}
                          <td>₹{utils.currency_format(value.invoice_value)}</td>
                        </tr>
                      )
                    }) : <tr><td colSpan={14} className="text-center">
                        {(this.props.data.tax_report && this.props.data.tax_report.cdnr && this.props.data.tax_report.cdnr.length === 0) ? 'Opps! No Record(s) Found.':'Loading...'}
                      </td></tr> }

                      {(this.props.data.tax_report && this.props.data.tax_report.cdnr && this.props.data.tax_report.cdnr.length > 0) &&
                        <tr>
                          <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>₹{utils.currency_format(cdnr_taxable_total)}</td>
                            <td>{utils.currency_format(cdnr_cgst_total)}</td>
                            <td>{utils.currency_format(cdnr_sgst_total)}</td>
                            <td>{utils.currency_format(cdnr_igst_total)}</td>
                            <td>{utils.currency_format(total_round_of)}</td>
                            <td>₹{utils.currency_format(cdnr_inv_total)}</td>
                            {/* <td></td> */}
                        </tr>
                      }
                    </tbody>
                  </table>
                </>
              }
              {(this.props.target==='hsn') &&
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">HSN</th>
                    <th scope="col">QUANTITY</th>
                    <th scope="col">TAX RATE %</th>
                   
                    <th scope="col">TAXABLE AMOUNT</th>
                    <th scope="col">CGST</th>
                    <th scope="col">SGST</th>
                    <th scope="col">IGST</th>
                    
                    <th scope="col">TOTAL AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {(this.props.data.tax_report && this.props.data.tax_report.hsn && this.props.data.tax_report.hsn.length > 0) ?this.props.data.tax_report.hsn.map( (value, index) => {
                    hsn_quan_total = hsn_quan_total+parseFloat(value.qty);
                    hsn_cgst_total = hsn_cgst_total+parseFloat(value.camt);
                    hsn_sgst_total = hsn_sgst_total+parseFloat(value.samt);
                    hsn_igst_total = hsn_igst_total+parseFloat(value.iamt);
                    hsn_taxable_total = hsn_taxable_total+parseFloat(value.txval);
                    hsn_item_total = parseFloat(value.txval)+parseFloat(value.camt)+parseFloat(value.samt)+parseFloat(value.iamt);
                    hsn_total = hsn_total + hsn_item_total;
                  return (
                    <tr key={ index } className="contact-row">
                      <td>{value.hsn_sc}</td>
                      <td>{value.qty}</td>
                      <td>{value.rt}</td>
                    
                      <td>₹{utils.currency_format(value.txval)}</td>
                      <td>{utils.currency_format(value.camt)}</td>
                      <td>{utils.currency_format(value.samt)}</td>
                      <td>{utils.currency_format(value.iamt)}</td>
                      
                      <td>₹{utils.currency_format(hsn_item_total)}</td>
                    </tr>
                  )
                }) : <tr><td colSpan={8} className="text-center">{(this.props.data.tax_report && this.props.data.tax_report.hsn && this.props.data.tax_report.hsn.length === 0) ? 'Opps! No Record(s) Found.':'Loading...'}</td></tr> }

                {(this.props.data.tax_report && this.props.data.tax_report.hsn && this.props.data.tax_report.hsn.length > 0) &&
                  <tr>
                    <td></td>
                      
                      <td>{utils.currency_format(hsn_quan_total)}</td>
                    
                      <td></td>
                      <td>₹{utils.currency_format(hsn_taxable_total)}</td>
                      <td>{utils.currency_format(hsn_cgst_total)}</td>
                      <td>{utils.currency_format(hsn_sgst_total)}</td>
                      <td>{utils.currency_format(hsn_igst_total)}</td>
                      <td>₹{utils.currency_format(hsn_total)}</td>
                  </tr>
                }
                </tbody>
              </table>
              }
              {(this.props.target==='hsn_list') &&
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">HSN</th>
                    <th scope="col">QUANTITY</th>
                    <th scope="col">TAX RATE %</th>
                   
                    <th scope="col">TAXABLE AMOUNT</th>
                    <th scope="col">CGST</th>
                    <th scope="col">SGST</th>
                    <th scope="col">IGST</th>
                    
                    <th scope="col">TOTAL AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {(this.props.data.tax_report && this.props.data.tax_report.hsn_list && this.props.data.tax_report.hsn_list.length > 0) ?this.props.data.tax_report.hsn_list.map( (value, index) => {
                    hsn_quan_total = hsn_quan_total+parseFloat(value.qty);
                    hsn_cgst_total = hsn_cgst_total+parseFloat(value.camt);
                    hsn_sgst_total = hsn_sgst_total+parseFloat(value.samt);
                    hsn_igst_total = hsn_igst_total+parseFloat(value.iamt);
                    hsn_taxable_total = hsn_taxable_total+parseFloat(value.txval);
                    hsn_item_total = parseFloat(value.txval)+parseFloat(value.camt)+parseFloat(value.samt)+parseFloat(value.iamt);
                    hsn_total = hsn_total + hsn_item_total;
                  return (
                    <tr key={ index } className="contact-row">
                      <td>{value.hsn_sc}</td>
                      <td>{value.qty}</td>
                      <td>{value.rt}</td>
                    
                      <td>₹{utils.currency_format(value.txval)}</td>
                      <td>{utils.currency_format(value.camt)}</td>
                      <td>{utils.currency_format(value.samt)}</td>
                      <td>{utils.currency_format(value.iamt)}</td>
                      
                      <td>₹{utils.currency_format(hsn_item_total)}</td>
                    </tr>
                  )
                }) : <tr><td colSpan={8} className="text-center">{(this.props.data.tax_report && this.props.data.tax_report.hsn && this.props.data.tax_report.hsn.length === 0) ? 'Opps! No Record(s) Found.':'Loading...'}</td></tr> }

                {(this.props.data.tax_report && this.props.data.tax_report.hsn && this.props.data.tax_report.hsn.length > 0) &&
                  <tr>
                    <td></td>
                      
                      <td>{utils.currency_format(hsn_quan_total)}</td>
                    
                      <td></td>
                      <td>₹{utils.currency_format(hsn_taxable_total)}</td>
                      <td>{utils.currency_format(hsn_cgst_total)}</td>
                      <td>{utils.currency_format(hsn_sgst_total)}</td>
                      <td>{utils.currency_format(hsn_igst_total)}</td>
                      <td>₹{utils.currency_format(hsn_total)}</td>
                  </tr>
                }
                </tbody>
              </table>
              }
              {(this.props.target==='cdnr_hsn' ) &&
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">HSN</th>
                    <th scope="col">QUANTITY</th>
                    <th scope="col">TAX RATE %</th>
                   
                    <th scope="col">TAXABLE AMOUNT</th>
                    <th scope="col">CGST</th>
                    <th scope="col">SGST</th>
                    <th scope="col">IGST</th>
                    
                    <th scope="col">TOTAL AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {(this.props.data.tax_report && this.props.data.tax_report.cdnr_hsn && this.props.data.tax_report.cdnr_hsn.length > 0) ?this.props.data.tax_report.cdnr_hsn.map( (value, index) => {
                    hsn_quan_total = hsn_quan_total+parseFloat(value.quantity);
                    hsn_cgst_total = hsn_cgst_total+parseFloat(value.camt);
                    hsn_sgst_total = hsn_sgst_total+parseFloat(value.samt);
                    hsn_igst_total = hsn_igst_total+parseFloat(value.iamt);
                    hsn_taxable_total = hsn_taxable_total+parseFloat(value.txval);
                    hsn_item_total = parseFloat(value.txval)+parseFloat(value.camt)+parseFloat(value.samt)+parseFloat(value.iamt);
                    hsn_total = hsn_total + hsn_item_total;
                  return (
                    <tr key={ index } className="contact-row">
                      <td>{value.hsn_sc}</td>
                      <td>{value.quantity}</td>
                      <td>{value.rt}</td>
                    
                      <td>₹{utils.currency_format(value.txval)}</td>
                      <td>{utils.currency_format(value.camt)}</td>
                      <td>{utils.currency_format(value.samt)}</td>
                      <td>{utils.currency_format(value.iamt)}</td>
                      
                      <td>₹{utils.currency_format(hsn_item_total)}</td>
                    </tr>
                  )
                }) : <tr><td colSpan={8} className="text-center">{(this.props.data.tax_report && this.props.data.tax_report.cdnr_hsn && this.props.data.tax_report.cdnr_hsn.length === 0) ? 'Opps! No Record(s) Found.':'Loading...'}</td></tr> }

                {(this.props.data.tax_report && this.props.data.tax_report.cdnr_hsn && this.props.data.tax_report.cdnr_hsn.length > 0) &&
                  <tr>
                    <td></td>
                      
                      <td>{utils.currency_format(hsn_quan_total)}</td>
                    
                      <td></td>
                      <td>₹{utils.currency_format(hsn_taxable_total)}</td>
                      <td>{utils.currency_format(hsn_cgst_total)}</td>
                      <td>{utils.currency_format(hsn_sgst_total)}</td>
                      <td>{utils.currency_format(hsn_igst_total)}</td>
                      <td>₹{utils.currency_format(hsn_total)}</td>
                  </tr>
                }
                </tbody>
              </table>
              }
              {(this.props.target==='hsn_combined' ) &&
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">HSN</th>
                    <th scope="col">QUANTITY</th>
                    <th scope="col">TAX RATE %</th>
                   
                    <th scope="col">TAXABLE AMOUNT</th>
                    <th scope="col">CGST</th>
                    <th scope="col">SGST</th>
                    <th scope="col">IGST</th>
                    
                    <th scope="col">TOTAL AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {(this.props.data.tax_report && this.props.data.tax_report.hsn_combined && this.props.data.tax_report.hsn_combined.length > 0) ?this.props.data.tax_report.hsn_combined.map( (value, index) => {
                    hsn_quan_total = hsn_quan_total+parseFloat(value.qty);
                    let camt = 0;
                    let samt = 0;
                    let iamt = 0;
                    if(value.camt!==undefined){
                      camt = value.camt;
                    }
                    if(value.samt!==undefined){
                      samt = value.samt;
                    }
                    if(value.iamt!==undefined){
                      iamt = value.iamt;
                    }
                    hsn_cgst_total = hsn_cgst_total+parseFloat(camt);
                    hsn_sgst_total = hsn_sgst_total+parseFloat(samt);
                    hsn_igst_total = hsn_igst_total+parseFloat(iamt);
                    hsn_taxable_total = hsn_taxable_total+parseFloat(value.txval);
                    hsn_item_total = parseFloat(value.txval)+parseFloat(camt)+parseFloat(samt)+parseFloat(iamt);
                    hsn_total = hsn_total + hsn_item_total;
                  return (
                    <tr key={ index } className="contact-row">
                      {(value.hsn_sc!=='No HSN Found') &&
                        <>
                          <td>{value.hsn_sc}</td>
                          <td>{value.qty}</td>
                        </>
                      }
                      {(value.hsn_sc==='No HSN Found') &&
                        <>
                          <td>
                            <DrawerLink target="no_hsn_found" width="25%" url={''} name={value.hsn_sc} info={this.props.data.no_hsn_found_data}/>
                          </td>
                          <td>
                            <DrawerLink target="no_hsn_found" width="25%" url={''} name={value.qty} info={this.props.data.no_hsn_found_data}/>
                          </td>
                        </>
                      }
                      <td>{value.rt}</td>
                    
                      <td>₹{utils.currency_format(value.txval)}</td>
                      <td>{utils.currency_format(value.camt)}</td>
                      <td>{utils.currency_format(value.samt)}</td>
                      <td>{utils.currency_format(value.iamt)}</td>
                      
                      <td>₹{utils.currency_format(hsn_item_total)}</td>
                    </tr>
                  )
                }) : <tr><td colSpan={8} className="text-center">{(this.props.data.tax_report && this.props.data.tax_report.hsn_combined && this.props.data.tax_report.hsn_combined.length === 0) ? 'Opps! No Record(s) Found.':'Loading...'}</td></tr> }

                {(this.props.data.tax_report && this.props.data.tax_report.hsn_combined && this.props.data.tax_report.hsn_combined.length > 0) &&
                  <tr>
                    <td></td>
                      
                      <td>{utils.currency_format(hsn_quan_total)}</td>
                    
                      <td></td>
                      <td>₹{utils.currency_format(hsn_taxable_total)}</td>
                      <td>{utils.currency_format(hsn_cgst_total)}</td>
                      <td>{utils.currency_format(hsn_sgst_total)}</td>
                      <td>{utils.currency_format(hsn_igst_total)}</td>
                      <td>₹{utils.currency_format(hsn_total)}</td>
                  </tr>
                }
                </tbody>
              </table>
              }
            </table>
  }
}